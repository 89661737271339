import { useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountBox as AccountIcon } from '@mui/icons-material';
import SettingsBox from '../SettingsBox';
import AllowedEmailManager from './AllowedEmailManager';
import useAllowedEmailsState from './useAllowedEmailsState';
import AuthProviderPicker from './AuthProviderPicker';
import SaveButtonWithConfirmationPrompt from './SaveButtonWithConfirmationPrompt';
import Space from '@/components/Space';
import SchoolContext from '../SchoolContext';
import useApi from '@/lib/api/useApi';
import IdentityConnectionProvider from '@/lib/constants/IdentityConnectionProvider.enum';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

const LoginSettings = () => {
  const api = useApi();

  const [loading, { instanceConfig }] = useContext(SchoolContext);

  // TODO in general texts regarding allowed email settings should be reviewed
  // TODO show error on empty email
  const {
    allowedEmails,
    allowedEmailsAsObject: newAllowedEmails,
    addAllowedEmail,
    editAllowedEmail,
    removeAllowedEmail,
  } = useAllowedEmailsState(instanceConfig.allowedEmails);

  // Note: we can ignore sync re-enabling, because the page refreshes when
  // saving
  const [authProvider, setAuthProvider] = useComplexSwrState(
    instanceConfig.authProvider,
  );

  const [saving, setSaving] = useState(false);

  const settingsHaveChanged =
    JSON.stringify(Object.values(newAllowedEmails)) !==
      JSON.stringify(instanceConfig.allowedEmails) ||
    authProvider !== instanceConfig.authProvider;

  const saveChanges = async (): Promise<void> => {
    if (!settingsHaveChanged) {
      return;
    }

    setSaving(true);

    await api.put('instance-config', {
      instanceConfigValues: [
        {
          reference: 'allowedEmails',
          value: JSON.stringify(Object.values(newAllowedEmails)),
        },
        {
          reference: 'authProvider',
          value: JSON.stringify(authProvider),
        },
      ],
    });

    setSaving(false);

    window.location.reload();
  };

  return (
    <SettingsBox
      title={<FormattedMessage id="platform_settings.login_settings.title" />}
      icon={<AccountIcon />}
    >
      <AllowedEmailManager
        allowedEmails={allowedEmails}
        addAllowedEmail={addAllowedEmail}
        editAllowedEmail={editAllowedEmail}
        removeAllowedEmail={removeAllowedEmail}
      />
      <AuthProviderPicker
        value={authProvider}
        onChange={e =>
          setAuthProvider(e.target.value as IdentityConnectionProvider)
        }
      />
      <Space height={4} />
      <SaveButtonWithConfirmationPrompt
        onSave={saveChanges}
        disabled={loading || !settingsHaveChanged || saving}
      />
    </SettingsBox>
  );
};

export default LoginSettings;
