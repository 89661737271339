import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import NotFoundTitle from '@/components/NotFoundTitle';
import FormattedHtmlMessage from '@/components/FormattedHtmlMessage';

const MotionNotFoundTitle = motion.create(NotFoundTitle);
const StyledMessage = styled(MotionNotFoundTitle)({
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 600,
  fontSize: 18,
  textAlign: 'center',
});

const NoPlannedChallengesInVisibleWeekRangeMessage = () => (
  <StyledMessage
    initial={{
      opacity: 0,
    }}
    animate={{
      opacity: 1,
    }}
    transition={{
      delay: 0.1,
    }}
  >
    <FormattedHtmlMessage id="portfolio.challenge_planning_view.no_planned_challenges_in_visible_week_range_message" />
  </StyledMessage>
);

export default NoPlannedChallengesInVisibleWeekRangeMessage;
