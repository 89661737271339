import StyledNewlySharedInfo from './StyledNewlySharedInfo';
import MobileRowPicker from './MobileRowPicker';
import device from 'current-device';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import { PersonAddOutlined as NewlySharedIcon } from '@mui/icons-material';

const NewlySharedInfo = () => (
  <StyledNewlySharedInfo>
    <section>
      <NewlySharedIcon />
      <FormattedMessageWithChallengeNaming
        id={
          device.desktop()
            ? 'portfolio.challenge.newly_shared_info'
            : 'portfolio.challenge.newly_shared_info.mobile'
        }
      />
    </section>
    <MobileRowPicker />
  </StyledNewlySharedInfo>
);

export default NewlySharedInfo;
