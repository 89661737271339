/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import readKeyedImageAsDataUrl from './readKeyedImageAsDataUrl';

const useImageAsDataUrl = (key: string, image: Blob | null): string | null => {
  const [imageAsDataUrl, setImageAsDataUrl] = useState<string | null>(null);

  useEffect(() => {
    if (image === null) {
      setImageAsDataUrl(null);

      return;
    }

    const processImage = async (): Promise<void> => {
      const dataUrl = await readKeyedImageAsDataUrl(key, image);

      setImageAsDataUrl(dataUrl);
    };

    processImage();
  }, [key, image !== null]);

  return imageAsDataUrl;
};

export default useImageAsDataUrl;
