import { ChallengePortfolio, SetComplexImmerStateAction } from '@/lib/types';
import dummyFunction from '@/lib/utils/dummyFunction';
import { createContext } from 'react';

// TODO values are apparently continuously recomputed, inefficient, possible
// performance gain
const ChallengePortfolioContext = createContext<
  [ChallengePortfolio, SetComplexImmerStateAction<ChallengePortfolio>]
>([
  {
    rows: {},
    challenges: {},
  },
  dummyFunction,
]);

export default ChallengePortfolioContext;
