import { useContext } from 'react';
import KanbanPhasesHeader from '../KanbanPhasesHeader';
import PhasesWrapper from './PhasesWrapper';
import ChallengeContext from '../Challenge/ChallengeView/ChallengeContext';
import sortById from '@/lib/utils/sorting/sortById';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import KanbanPhaseColumn from './KanbanPhaseColumn';
import { TaskSummary } from '@/lib/types';

const TaskBoard = () => {
  const [challenge] = useContext(ChallengeContext);

  const tasksPerPhase = Object.values(challenge.tasks)
    .sort(sortById)
    .reduce(
      (tasksPerPhase, task) => {
        tasksPerPhase[task.phase].push(task);

        return tasksPerPhase;
      },
      {
        [KanbanPhase.TODO]: [] as TaskSummary[],
        [KanbanPhase.IN_PROGRESS]: [] as TaskSummary[],
        [KanbanPhase.DONE]: [] as TaskSummary[],
      },
    );

  return (
    <>
      <KanbanPhasesHeader inTaskBoard />
      <PhasesWrapper>
        <KanbanPhaseColumn
          phase={KanbanPhase.TODO}
          tasks={tasksPerPhase[KanbanPhase.TODO]}
        />
        <KanbanPhaseColumn
          phase={KanbanPhase.IN_PROGRESS}
          tasks={tasksPerPhase[KanbanPhase.IN_PROGRESS]}
        />
        <KanbanPhaseColumn
          phase={KanbanPhase.DONE}
          tasks={tasksPerPhase[KanbanPhase.DONE]}
        />
      </PhasesWrapper>
    </>
  );
};

export default TaskBoard;
