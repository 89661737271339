// We're working with the maximum 32-bit signed integer because that's the
// largest numeric value the database accepts.
export const MAX_SIGNED_INT32 = 2147483647;

const isBackendSafeNumber = (number: number): boolean => {
  if (!Number.isFinite(number)) {
    return false;
  }

  return Math.abs(number) <= MAX_SIGNED_INT32;
};

export default isBackendSafeNumber;
