/* eslint-disable no-useless-escape */
const isValidUrl = (url: string): boolean => {
  // Moving this variable out of this function will break the regex
  const VALID_URL_REGEX =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

  return VALID_URL_REGEX.test(url);
};

export default isValidUrl;
