import { TextareaAutosizeProps } from '@mui/material';
import StyledTextareaWithAutosizing from './StyledTextareaWithAutosizing';
import { useEffect, useState } from 'react';

type FormattedTextProps = Omit<
  TextareaAutosizeProps,
  'value' | 'tabIndex' | 'readOnly' | 'children'
> & {
  children: string;
};

const FormattedText = ({ children: text, ...props }: FormattedTextProps) => {
  // On content update we need to re-mount, otherwise the underlying textarea
  // will simply continue displaying the initial value.
  const [unmounted, setUnmounted] = useState(false);

  useEffect(() => {
    setUnmounted(true);

    setTimeout(() => setUnmounted(false), 0);
  }, [text]);

  if (unmounted) {
    return null;
  }

  return (
    <StyledTextareaWithAutosizing
      defaultValue={text}
      tabIndex={-1}
      readOnly
      {...props}
    />
  );
};

export default FormattedText;
