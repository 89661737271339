import React, { createContext } from 'react';
import { ReactContextWithAutomatedProvider, School } from '@/lib/types';

const SelectedSchoolContext = createContext<School>(
  null as unknown as School,
) as ReactContextWithAutomatedProvider<School>;

type AutomatedSelectedSchoolContextProviderProps = {
  selectedSchool: School;
  children: React.ReactNode;
};

const AutomatedSelectedSchoolContextProvider = ({
  selectedSchool,
  children,
}: AutomatedSelectedSchoolContextProviderProps) => {
  return (
    <SelectedSchoolContext.Provider value={selectedSchool}>
      {children}
    </SelectedSchoolContext.Provider>
  );
};

SelectedSchoolContext.AutomatedProvider =
  AutomatedSelectedSchoolContextProvider;

export default SelectedSchoolContext;
