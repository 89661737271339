import React, { useState, useEffect } from 'react';

const useElementIsInViewport = (
  elementRef: React.RefObject<HTMLElement | null>,
): boolean => {
  const [inViewport, setInViewport] = useState(true);

  useEffect(() => {
    const updateElementViewportRelationState = () => {
      if (elementRef.current !== null) {
        const elementBoundingClientRect =
          elementRef.current.getBoundingClientRect();
        const elementOffsetFromViewportTop = elementBoundingClientRect.top;
        setInViewport(elementOffsetFromViewportTop > 0);
      }
    };

    window.addEventListener('scroll', updateElementViewportRelationState);
    window.addEventListener('resize', updateElementViewportRelationState);

    return () => {
      window.removeEventListener('scroll', updateElementViewportRelationState);
      window.removeEventListener('resize', updateElementViewportRelationState);
    };
  }, [elementRef]);

  return inViewport;
};

export default useElementIsInViewport;
