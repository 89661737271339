import PerspectiveCard from '@/components/perspectives/PerspectiveCard';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import DragType from './DragType.enum';
import PerspectiveCategory from '@/lib/constants/PerspectiveCategory.enum';
import useApi from '@/lib/api/useApi';
import useIsPortfolioReadOnly from '@/components/ChallengePortfolio/NormalView/useIsPortfolioReadOnly';
import { PerspectiveWithSublevelsInfo } from '@/lib/types';
import { PerspectiveDragItem, PerspectiveDropResult } from './DragDropTypes';
import React from 'react';

type DraggablePerspectiveProps = {
  perspective: PerspectiveWithSublevelsInfo;
  category: PerspectiveCategory;
  onUpdateCategory: (category: PerspectiveCategory) => void;
  onOpen?: () => Promise<void>;
};

const DraggablePerspective = ({
  perspective,
  category,
  onUpdateCategory,
  onOpen,
}: DraggablePerspectiveProps) => {
  const api = useApi();
  const portfolioIsReadOnly = useIsPortfolioReadOnly();

  const [, dragRef] = useDrag({
    type: DragType.PERSPECTIVE,
    item: {
      perspective,
      category,
    } satisfies PerspectiveDragItem,
    end: (
      _,
      monitor: DragSourceMonitor<PerspectiveDragItem, PerspectiveDropResult>,
    ) => {
      const dropResult = monitor.getDropResult();

      if (dropResult === null) {
        return;
      }

      const newCategory = dropResult.category;

      if (category === newCategory) {
        return;
      }

      api.post(`/perspectives/${perspective.id}/category`, {
        category: newCategory,
      });

      onUpdateCategory(newCategory);
    },
  });

  return (
    <PerspectiveCard
      perspective={perspective}
      onOpen={onOpen}
      draggable={!portfolioIsReadOnly}
      ref={
        portfolioIsReadOnly
          ? null
          : (dragRef as unknown as React.Ref<HTMLLIElement>)
      }
    />
  );
};

export default DraggablePerspective;
