import UserRole from '@/lib/constants/UserRole.enum';
import { User } from '@/lib/types';

const rolesAsNumber = {
  [UserRole.STUDENT]: 4,
  [UserRole.COACH]: 3,
  [UserRole.EDITOR]: 2,
  [UserRole.ADMIN]: 1,
  [UserRole.SUPER_ADMIN]: 0,
} as const;

const sortByRoleThenName = (a: User, b: User): number => {
  const aRoleNumber = rolesAsNumber[a.role];
  const bRoleNumber = rolesAsNumber[b.role];

  if (aRoleNumber < bRoleNumber) {
    return -1;
  }

  if (aRoleNumber > bRoleNumber) {
    return 1;
  }

  return a.name.localeCompare(b.name);
};

export default sortByRoleThenName;
