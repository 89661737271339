import React, { Ref, useContext } from 'react';
import StyledPhaseColumn from './StyledPhaseColumn';
import PhaseContext from '../../../PhaseContext';
import VisiblePhaseContext from '@/components/ChallengePortfolio/VisiblePhaseContext';

type PhaseColumnProps = {
  hasItems: boolean;
  inTaskBoard?: boolean;
  collapsed?: boolean;
  children: React.ReactNode;
  ref: Ref<HTMLDivElement>;
};

// TODO work out degree of re-using between these challenge/task ish
// components; maybe also move to some `shared` folder
const PhaseColumn = ({
  hasItems,
  inTaskBoard,
  collapsed,
  children,
  ref,
}: PhaseColumnProps) => {
  const phase = useContext(PhaseContext);
  const { visibleChallengePhase, visibleTaskPhase } =
    useContext(VisiblePhaseContext);
  const visiblePhase = inTaskBoard ? visibleTaskPhase : visibleChallengePhase;

  return (
    <StyledPhaseColumn
      hasItems={hasItems}
      inTaskBoard={inTaskBoard ?? false}
      collapsed={collapsed ?? false}
      invisible={phase !== visiblePhase}
      ref={ref}
    >
      {children}
    </StyledPhaseColumn>
  );
};

export default PhaseColumn;
