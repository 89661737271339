import EditorWrapper from './EditorWrapper';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import EditorPart from '@/pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/EditorPart';
import AddPart from '@/pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/AddPart';
import UserRole from '@/lib/constants/UserRole.enum';
import useMimicPortfolioTemplates from './useMimicPortfolioTemplates';
import EditorPortfolioTemplatesContext from '@/pages/EditorPage/RubricsPortfolioTemplatesEditor/EditorPortfolioTemplatesContext';

const RubricsEditor = () => {
  const [portfolioTemplates, setPortfolioTemplates] =
    useMimicPortfolioTemplates();

  const parts = Object.values(portfolioTemplates.parts).sort(sortByPosition);
  const highestPartPosition = parts[parts.length - 1]?.position ?? 0;

  return (
    <EditorWrapper hasParts={parts.length > 0}>
      <EditorPortfolioTemplatesContext.Provider
        value={[portfolioTemplates, setPortfolioTemplates]}
      >
        {parts.map((part, i) => (
          <EditorPart
            part={part}
            previousPart={parts[i - 1] ?? null}
            nextPart={parts[i + 1] ?? null}
            highestPosition={highestPartPosition}
            subtopicPosition={-1}
            noGroups
            noCopy
            key={part.id}
          />
        ))}
        <AddPart
          subtopicId={-1}
          subtopicMinimumRole={UserRole.STUDENT}
          highestPartPosition={highestPartPosition}
          noParentAdd
          noGroups
        />
      </EditorPortfolioTemplatesContext.Provider>
    </EditorWrapper>
  );
};

export default RubricsEditor;
