import styled from '@emotion/styled';
import Dialog from '@/components/Dialog';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledDialogActions = styled(Dialog.Actions)({
  justifyContent: 'flex-start',
  p: {
    paddingRight: 12,
    marginRight: 'auto',
  },
  [mediaQuery(640)]: {
    p: {
      marginBottom: 12,
      marginRight: 0,
      paddingRight: 0,
      textAlign: 'center',
    },
  },
});

export default StyledDialogActions;
