import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Tooltip, IconButton, IconButtonProps } from '@mui/material';
import device from 'current-device';
import { Add as AddIcon } from '@mui/icons-material';
import { alpha } from '@mui/material';
import { common } from '@mui/material/colors';
import React from 'react';
import { OmitFramerProps } from '@/lib/types';

type CreateButtonProps = Omit<OmitFramerProps<IconButtonProps>, 'children'> & {
  tooltip: React.ReactNode;
  isCreateTaskButton?: boolean;
};

const ButtonContainer = styled('footer')({
  position: 'absolute',
  bottom: 8,
  right: 8,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'flex-end',
  zIndex: 1,
});

const MotionIconButton = motion.create(
  styled(IconButton)(({ theme }) => ({
    color: theme.isDark ? alpha(common.black, 0.87) : alpha(common.white, 0.87),
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[4],
    ':hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    ':focus': {
      transform: 'none !important',
      opacity: '1 !important',
    },
  })),
);

const CreateButton = ({
  tooltip,
  isCreateTaskButton,
  onClick,
}: CreateButtonProps) => {
  const shouldAlwaysShowButton = !device.desktop();
  return (
    <ButtonContainer>
      <Tooltip title={tooltip}>
        <MotionIconButton
          variants={{
            initial: {
              scale: isCreateTaskButton || shouldAlwaysShowButton ? 1 : 0,
              opacity: isCreateTaskButton || shouldAlwaysShowButton ? 1 : 0,
            },
            hover: {
              scale: 1,
              opacity: 1,
            },
          }}
          onClick={onClick}
        >
          <AddIcon />
        </MotionIconButton>
      </Tooltip>
    </ButtonContainer>
  );
};

export default CreateButton;
