import { useContext } from 'react';
import api, { Api } from './index';
import ApiHandlingContext from './ApiHandlingContext';
import HttpMethod from '../constants/HttpMethod.enum';

type ApiWithErrorHandling = Api & {
  handleError: (error: unknown, httpMethod: HttpMethod | null) => void;
};

const useApi = () => {
  const handleError = useContext(ApiHandlingContext);

  function wrapApiFunctionWithErrorHandling<
    F extends (...params: any[]) => Promise<unknown>,
  >(fn: F, httpMethod: HttpMethod | null): F {
    const wrappedApiFunction = (...params: unknown[]) =>
      new Promise(resolve => {
        fn(...params)
          .then(resolve)
          .catch((error: unknown): void => {
            handleError(error, httpMethod);
          });
      });

    return wrappedApiFunction as unknown as F;
  }

  return {
    url: api.url,
    isAuthenticated: api.isAuthenticated,
    authenticate: api.authenticate,
    reauthenticate: api.reauthenticate,
    unauthenticate: api.unauthenticate,
    addAuthenticationStateListener: api.addAuthenticationStateListener,
    removeAuthenticationStateListener: api.removeAuthenticationStateListener,
    get: wrapApiFunctionWithErrorHandling(api.get, HttpMethod.GET),
    getGlobalStatic: wrapApiFunctionWithErrorHandling(
      api.getGlobalStatic,
      HttpMethod.GET,
    ),
    getStoredGlobalStaticResponse: api.getStoredGlobalStaticResponse,
    post: wrapApiFunctionWithErrorHandling(api.post, HttpMethod.POST),
    put: wrapApiFunctionWithErrorHandling(api.put, HttpMethod.PUT),
    delete: wrapApiFunctionWithErrorHandling(api.delete, HttpMethod.DELETE),
    call: wrapApiFunctionWithErrorHandling(api.call, null),
    callWithoutAuthorizationHandling: wrapApiFunctionWithErrorHandling(
      api.callWithoutAuthorizationHandling,
      null,
    ),
    handleError,
  } as ApiWithErrorHandling;
};

export default useApi;
