import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { SecurityAuditEventType } from '../enums';
import Space from '@/components/Space';
import React, { useState } from 'react';
import { SecurityAuditEventsSearchInput } from '../types';
import { Moment } from 'moment';
import SearchForm, {
  SearchFormGroup,
  SearchFormGroupTitle,
  SearchFormGroupContent,
  SearchButton,
} from '../components/SearchForm';
import {
  isDateRangeValid,
  toEndOfDay,
} from '../components/SearchForm/SearchFormDateRange/dateRangeUtils';
import { FormattedMessage } from 'react-intl';
import SearchFormDateRange from '../components/SearchForm/SearchFormDateRange';
import NoneMenuItem from '../components/SearchForm/NoneMenuItem';

type SecurityAuditEventsSearchFormProps = {
  lastSearchInput: SecurityAuditEventsSearchInput;
  onSearch: (searchInput: SecurityAuditEventsSearchInput) => void;
};

const SecurityAuditEventsSearchForm = ({
  lastSearchInput,
  onSearch,
}: SecurityAuditEventsSearchFormProps) => {
  const [eventType, setEventType] = useState<SecurityAuditEventType | ''>('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [originIp, setOriginIp] = useState('');
  const [userNameOrEmail, setUserNameOrEmail] = useState('');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const handleSearch = (event: React.FormEvent): void => {
    event.preventDefault();

    const searchInput = {
      eventType: eventType || undefined,
      additionalInfo: additionalInfo.trim() || undefined,
      originIp: originIp.trim() || undefined,
      userNameOrEmail: userNameOrEmail.trim() || undefined,
      startDate: startDate?.toDate(),
      endDate: toEndOfDay(endDate)?.toDate(),
    };

    if (JSON.stringify(lastSearchInput) === JSON.stringify(searchInput)) {
      return;
    }

    onSearch(searchInput);
  };

  return (
    <SearchForm onSubmit={handleSearch}>
      <FormControl variant="filled" size="small" fullWidth>
        <InputLabel id="audit-event-type-select-label">
          <FormattedMessage id="audit.security_events.search_form.event_type.select.label" />
        </InputLabel>
        <Select<SecurityAuditEventType>
          labelId="audit-event-type-select-label"
          id="audit-event-type-select"
          label={
            <FormattedMessage id="audit.security_events.search_form.event_type.select.label" />
          }
          value={eventType}
          onChange={e =>
            setEventType(e.target.value as SecurityAuditEventType | '')
          }
        >
          <NoneMenuItem value="">
            <FormattedMessage id="audit.security_events.search_form.event_type.select.options.none" />
          </NoneMenuItem>
          {Object.values(SecurityAuditEventType).map(securityAuditEventType => (
            <MenuItem
              value={securityAuditEventType}
              key={securityAuditEventType}
            >
              {securityAuditEventType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Space height={12} />
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="additional-specification">
          <FormattedMessage id="audit.security_events.search_form.additional_spec.group_title" />
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <TextField
            variant="filled"
            size="small"
            label={
              <FormattedMessage id="audit.security_events.search_form.additional_spec.additional_info.field.label" />
            }
            value={additionalInfo}
            onChange={e => setAdditionalInfo(e.target.value)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="user">
          <FormattedMessage id="audit.security_events.search_form.user.group_title" />
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <TextField
            variant="filled"
            size="small"
            label={
              <FormattedMessage id="audit.security_events.search_form.user.name_or_email.field.label" />
            }
            value={userNameOrEmail}
            onChange={e => setUserNameOrEmail(e.target.value)}
          />
          <TextField
            variant="filled"
            size="small"
            label={
              <FormattedMessage id="audit.security_events.search_form.user.ip_address.field.label" />
            }
            value={originIp}
            onChange={e => setOriginIp(e.target.value)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="date-range">
          <FormattedMessage id="audit.security_events.search_form.date_range.group_title" />
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <SearchFormDateRange
            startDate={startDate}
            onChangeStartDate={date => setStartDate(date)}
            endDate={endDate}
            onChangeEndDate={date => setEndDate(date)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchButton disabled={!isDateRangeValid(startDate, endDate)} />
    </SearchForm>
  );
};

export default SecurityAuditEventsSearchForm;
