import getEventXOffset from './getEventXOffset';
import ensurePercentage from './ensurePercentage';
import { MouseMoveEvent } from '../../SliderRail';
import React from 'react';

const getProgressFromMousePosition = (
  event: MouseMoveEvent,
  reversed: boolean,
  sliderRef: React.RefObject<HTMLElement | null>,
): number | null => {
  const sliderElement = sliderRef.current;

  if (sliderElement === null) {
    return null;
  }

  const boundingRect = sliderElement.getBoundingClientRect();
  const xOffset = getEventXOffset(event);

  if (xOffset === null) {
    return null;
  }

  const eventOffsetFromLeftWrapperBoundary = xOffset - boundingRect.left;
  const progressWithinWrapperBoundaries =
    eventOffsetFromLeftWrapperBoundary / boundingRect.width;
  const progressPercentage = Math.round(progressWithinWrapperBoundaries * 100);

  if (reversed) {
    return ensurePercentage(100 - progressPercentage);
  }

  return ensurePercentage(progressPercentage);
};
export default getProgressFromMousePosition;
