import { useContext } from 'react';
import FieldContext from '../../../FieldContext';
import useRenderSelectValueOrPlaceholder from './useRenderSelectValueOrPlaceholder';
import StyledSelect from './StyledSelect';
import NoneMenuItem from './NoneMenuItem';
import { MenuItem } from '@mui/material';
import useCurrentFieldValue from '../../utils/useCurrentFieldValue';
import useSetCurrentFieldValue from '../../utils/useSetCurrentFieldValue';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type InnerSelectProps = {
  disabled: boolean;
};

const getInitialSelectValue = (
  currentFieldValue: string | string[],
  multipleOptionsSelectable: boolean,
): string | string[] => {
  if (multipleOptionsSelectable && !Array.isArray(currentFieldValue)) {
    return [currentFieldValue];
  }

  if (!multipleOptionsSelectable && Array.isArray(currentFieldValue)) {
    return currentFieldValue[0];
  }

  return currentFieldValue;
};

const InnerSelect = ({ disabled }: InnerSelectProps) => {
  const field = useContext(FieldContext);
  const multipleOptionsSelectable =
    field.additionalConfig.multipleOptionsSelectable ?? false;

  const currentFieldValue = useCurrentFieldValue<string | string[]>(
    multipleOptionsSelectable ? [] : '',
    value => typeof value === 'string' || Array.isArray(value),
  );
  const [setCurrentFieldValue, saving] = useSetCurrentFieldValue<
    string | string[]
  >();

  const initialSelectValue = getInitialSelectValue(
    currentFieldValue,
    multipleOptionsSelectable,
  );
  const [selectValue, setSelectValue, , reenableSelectValueSync] =
    useComplexSwrState(initialSelectValue, {
      stringifyChangeCheck: true,
    });

  const saveSelectValue = async (): Promise<void> => {
    await setCurrentFieldValue(selectValue);

    reenableSelectValueSync();
  };

  const renderSelectValueOrPlaceholder = useRenderSelectValueOrPlaceholder();

  return (
    <StyledSelect<string | string[]>
      variant="standard"
      value={selectValue}
      onChange={e => setSelectValue(e.target.value)}
      multiple={multipleOptionsSelectable}
      renderValue={renderSelectValueOrPlaceholder}
      MenuProps={{
        TransitionProps: {
          onExit: saveSelectValue,
        },
      }}
      disabled={disabled || saving}
      displayEmpty
    >
      {!multipleOptionsSelectable && <NoneMenuItem value="" />}
      {Object.entries(field.additionalConfig.options ?? {}).map(
        ([key, option]) => (
          <MenuItem value={key} key={key}>
            {option.name}
          </MenuItem>
        ),
      )}
    </StyledSelect>
  );
};

export default InnerSelect;
