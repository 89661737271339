import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  AuditableModelType,
  AuditEventType,
  AuditEventSubtype,
} from '../enums';
import styled from '@emotion/styled';
import Space from '@/components/Space';
import React, { useState } from 'react';
import { AuditEventsSearchInput } from '../types';
import { Moment } from 'moment';
import { FormattedMessage } from 'react-intl';
import SearchForm, {
  SearchFormGroup,
  SearchFormGroupTitle,
  SearchFormGroupContent,
  SearchFormFieldWrapper,
  SearchButton,
} from '../components/SearchForm';
import SearchFormDateRange from '../components/SearchForm/SearchFormDateRange';
import {
  isDateRangeValid,
  toEndOfDay,
} from '../components/SearchForm/SearchFormDateRange/dateRangeUtils';
import NoneMenuItem from '../components/SearchForm/NoneMenuItem';
import isBackendSafeNumber, {
  MAX_SIGNED_INT32,
} from '@/lib/utils/isBackendSafeNumber';

type AuditEventsSearchFormProps = {
  lastSearchInput: AuditEventsSearchInput;
  onSearch: (searchInput: AuditEventsSearchInput) => void;
};

const ModelIdField = styled(TextField)({
  maxWidth: 120,
});

const AuditEventsSearchForm = ({
  lastSearchInput,
  onSearch,
}: AuditEventsSearchFormProps) => {
  const [eventType, setEventType] = useState<AuditEventType | ''>('');
  const [modelType, setModelType] = useState<AuditableModelType | ''>('');
  const [modelId, setModelId] = useState<number | null>(null);
  const [modelName, setModelName] = useState('');
  const [eventSubtype, setEventSubtype] = useState<AuditEventSubtype | ''>('');
  const [attribute, setAttribute] = useState('');
  const [oldValue, setOldValue] = useState('');
  const [newValue, setNewValue] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [userNameOrEmail, setUserNameOrEmail] = useState('');
  const [originIp, setOriginIp] = useState('');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const handleModelIdChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (e.target.value === '') {
      setModelId(null);

      return;
    }

    let modelId = Number(e.target.value);

    if (Number.isNaN(modelId)) {
      setModelId(null);

      return;
    }

    if (!Number.isInteger(modelId)) {
      modelId = Math.floor(modelId);
    }

    if (!isBackendSafeNumber(modelId)) {
      setModelId(MAX_SIGNED_INT32);

      return;
    }

    if (modelId < 1) {
      setModelId(1);

      return;
    }

    setModelId(modelId);
  };

  const handleSearch = (event: React.FormEvent): void => {
    event.preventDefault();

    const searchInput = {
      eventType: eventType || undefined,
      modelType: modelType || undefined,
      modelId: modelId || undefined,
      modelName: modelName.trim() || undefined,
      eventSubtype: eventSubtype || undefined,
      attribute: attribute.trim() || undefined,
      oldValue: oldValue.trim() || undefined,
      newValue: newValue.trim() || undefined,
      additionalInfo: additionalInfo.trim() || undefined,
      userNameOrEmail: userNameOrEmail.trim() || undefined,
      originIp: originIp.trim() || undefined,
      startDate: startDate?.toDate(),
      endDate: toEndOfDay(endDate)?.toDate(),
    };

    if (JSON.stringify(lastSearchInput) === JSON.stringify(searchInput)) {
      return;
    }

    onSearch(searchInput);
  };

  return (
    <SearchForm onSubmit={handleSearch}>
      <FormControl variant="filled" size="small" fullWidth>
        <InputLabel id="audit-event-type-select-label">
          <FormattedMessage id="audit.user_activity.search_form.event_type.select.label" />
        </InputLabel>
        <Select<AuditEventType>
          labelId="audit-event-type-select-label"
          id="audit-event-type-select"
          label={
            <FormattedMessage id="audit.user_activity.search_form.event_type.select.label" />
          }
          value={eventType}
          onChange={e => setEventType(e.target.value as AuditEventType | '')}
        >
          <NoneMenuItem value="">
            <FormattedMessage id="audit.user_activity.search_form.event_type.select.options.none" />
          </NoneMenuItem>
          {Object.values(AuditEventType).map(auditEventType => (
            <MenuItem value={auditEventType} key={auditEventType}>
              {auditEventType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Space height={12} />
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="model">
          <FormattedMessage id="audit.user_activity.search_form.model.group_title" />
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <FormControl variant="filled" size="small">
            <InputLabel id="auditable-model-type-select-label">
              <FormattedMessage id="audit.user_activity.search_form.model.model_type.select.label" />
            </InputLabel>
            <Select<AuditableModelType>
              labelId="auditable-model-type-select-label"
              id="auditable-model-type-select"
              label={
                <FormattedMessage id="audit.user_activity.search_form.model.model_type.select.label" />
              }
              value={modelType}
              onChange={e =>
                setModelType(e.target.value as AuditableModelType | '')
              }
            >
              <NoneMenuItem value="">
                <FormattedMessage id="audit.user_activity.search_form.model.model_type.select.options.none" />
              </NoneMenuItem>
              {Object.values(AuditableModelType).map(auditableModelType => (
                <MenuItem value={auditableModelType} key={auditableModelType}>
                  {auditableModelType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Space height={12} />
          <SearchFormFieldWrapper>
            <ModelIdField
              variant="filled"
              size="small"
              label={
                <FormattedMessage id="audit.user_activity.search_form.model.model_id.field.label" />
              }
              type="number"
              value={modelId === null ? '' : modelId.toString()}
              onChange={handleModelIdChange}
              inputProps={{
                min: 1,
              }}
            />
            <Space width={12} />
            <TextField
              variant="filled"
              size="small"
              label={
                <FormattedMessage id="audit.user_activity.search_form.model.model_name.field.label" />
              }
              value={modelName}
              onChange={e => setModelName(e.target.value)}
            />
          </SearchFormFieldWrapper>
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="additional-specification">
          <FormattedMessage id="audit.user_activity.search_form.additional_spec.group_title" />
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <FormControl variant="filled" size="small" fullWidth>
            <InputLabel id="audit-event-subtype-select-label">
              <FormattedMessage id="audit.user_activity.search_form.additional_spec.event_subtype.select.label" />
            </InputLabel>
            <Select<AuditEventSubtype>
              labelId="audit-event-subtype-select-label"
              id="audit-event-subtype-select"
              label={
                <FormattedMessage id="audit.user_activity.search_form.additional_spec.event_subtype.select.label" />
              }
              value={eventSubtype}
              onChange={e =>
                setEventSubtype(e.target.value as AuditEventSubtype | '')
              }
            >
              <NoneMenuItem value="">
                <FormattedMessage id="audit.user_activity.search_form.additional_spec.event_subtype.select.options.none" />
              </NoneMenuItem>
              {Object.values(AuditEventSubtype).map(auditEventSubtype => (
                <MenuItem value={auditEventSubtype} key={auditEventSubtype}>
                  {auditEventSubtype}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="filled"
            size="small"
            label={
              <FormattedMessage id="audit.user_activity.search_form.additional_spec.attribute.field.label" />
            }
            value={attribute}
            onChange={e => setAttribute(e.target.value)}
          />
          <TextField
            variant="filled"
            size="small"
            label={
              <FormattedMessage id="audit.user_activity.search_form.additional_spec.old_value.field.label" />
            }
            value={oldValue}
            onChange={e => setOldValue(e.target.value)}
          />
          <TextField
            variant="filled"
            size="small"
            label={
              <FormattedMessage id="audit.user_activity.search_form.additional_spec.new_value.field.label" />
            }
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
          />
          <TextField
            variant="filled"
            size="small"
            label={
              <FormattedMessage id="audit.user_activity.search_form.additional_spec.additional_info.field.label" />
            }
            value={additionalInfo}
            onChange={e => setAdditionalInfo(e.target.value)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="user">
          <FormattedMessage id="audit.user_activity.search_form.user.group_title" />
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <TextField
            variant="filled"
            size="small"
            label={
              <FormattedMessage id="audit.user_activity.search_form.user.name_or_email.field.label" />
            }
            value={userNameOrEmail}
            onChange={e => setUserNameOrEmail(e.target.value)}
          />
          <TextField
            variant="filled"
            size="small"
            label={
              <FormattedMessage id="audit.user_activity.search_form.user.ip_address.field.label" />
            }
            value={originIp}
            onChange={e => setOriginIp(e.target.value)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchFormGroup>
        <SearchFormGroupTitle groupId="date-range">
          <FormattedMessage id="audit.user_activity.search_form.date_range.group_title" />
        </SearchFormGroupTitle>
        <SearchFormGroupContent>
          <SearchFormDateRange
            startDate={startDate}
            onChangeStartDate={date => setStartDate(date)}
            endDate={endDate}
            onChangeEndDate={date => setEndDate(date)}
          />
        </SearchFormGroupContent>
      </SearchFormGroup>
      <SearchButton disabled={!isDateRangeValid(startDate, endDate)} />
    </SearchForm>
  );
};

export default AuditEventsSearchForm;
