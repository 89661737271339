import { School } from '@/lib/types';
import dummyFunction from '@/lib/utils/dummyFunction';
import { createContext } from 'react';

const SelectedSchoolContext = createContext<[School | null, () => void]>([
  null,
  dummyFunction,
]);

export default SelectedSchoolContext;
