import styled from '@emotion/styled';
import GraphWrapper from './GraphWrapper';
import LoadingSpinner from '@/components/LoadingSpinner';
import { FormattedMessage } from 'react-intl';

const LoadingMessageWrapper = styled(GraphWrapper)({
  paddingLeft: 20,
  paddingRight: 20,
  height: 234,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  p: {
    marginTop: 12,
    marginBottom: 0,
    fontFamily: 'CircularStd',
    textAlign: 'center',
  },
});

const GraphLoadingPlaceholder = () => (
  <LoadingMessageWrapper>
    <LoadingSpinner />
    <p>
      <FormattedMessage id="audit.event_volume_graphs.loading_message" />
    </p>
  </LoadingMessageWrapper>
);

export default GraphLoadingPlaceholder;
