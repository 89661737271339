import { useState, useContext } from 'react';
import VisiblePortfolioTemplatesGroupContext from '../../VisiblePortfolioTemplatesGroupContext';
import UseAggressiveRubricCollapsingContext from '../UseAggressiveRubricCollapsingContext';
import EditorSubtopic from '../EditorSubtopic';
import AddSubtopic from '../AddSubtopic';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import itemMatchesVisibleGroup from '@/lib/rubricsPortfolioUtils/itemMatchesVisibleGroup';
import getHighestPositionFromSortedItems from '@/pages/EditorPage/getHighestPositionFromSortedItems';
import TopicWithDraggingTransparency from './TopicWithDraggingTransparency';
import TopicNameWrapper from '@/components/RubricsPortfolioComponents/StyledTopic/TopicNameWrapper';
import CollapseButton from '@/components/RubricsPortfolioComponents/CollapseButton';
import TopicContent from '@/components/RubricsPortfolioComponents/StyledTopic/TopicContent';
import EditTopic from './EditTopic';
import DeleteTopic from './DeleteTopic';
import useMakeDraggable from '../useMakeDraggable';
import useApi from '@/lib/api/useApi';
import { EditorTopic as EditorTopicType } from '@/lib/types';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';
import PortfolioDragType from '../PortfolioDragType.enum';

type EditorTopicProps = {
  topic: EditorTopicType;
  previousTopic: EditorTopicType | null;
  nextTopic: EditorTopicType | null;
  highestPosition: number;
};

const EditorTopic = ({
  topic,
  previousTopic,
  nextTopic,
  highestPosition,
}: EditorTopicProps) => {
  const api = useApi();

  const [portfolioTemplates, setPortfolioTemplates] = useContext(
    EditorPortfolioTemplatesContext,
  );

  const [isDragging, dragRef] = useMakeDraggable<HTMLDivElement>(
    PortfolioDragType.TOPIC,
    topic,
    previousTopic,
    nextTopic,
    highestPosition,
    (topicId: number, position: number): void => {
      setPortfolioTemplates(portfolioTemplates => {
        portfolioTemplates.topics[topicId].position = position;
      });

      api.put(`editor/portfolio-templates/topics/${topicId}/position`, {
        position,
      });
    },
  );

  const aggressiveRubricCollapsingEnabled = useContext(
    UseAggressiveRubricCollapsingContext,
  );
  const collapsedByDefault = aggressiveRubricCollapsingEnabled;
  const [collapsed, setCollapsed] = useState(collapsedByDefault);

  const allSubtopics = portfolioTemplates.subtopics;
  // TODO simply return ids as set of numbers in API?
  const desiredSubtopicsIds = topic.subtopics.map(({ id }) => id);
  const subtopics = desiredSubtopicsIds
    .map(subtopicId => allSubtopics[subtopicId])
    .filter(subtopic => !!subtopic)
    .sort(sortByPosition);

  const visibleGroup = useContext(VisiblePortfolioTemplatesGroupContext);
  const visibleSubtopics = subtopics.filter(subtopic =>
    itemMatchesVisibleGroup(subtopic, visibleGroup),
  );

  const highestSubtopicPosition = getHighestPositionFromSortedItems(subtopics);

  return (
    <TopicWithDraggingTransparency
      collapsed={collapsed}
      dragging={isDragging}
      ref={dragRef}
    >
      <TopicNameWrapper editor>
        <h2>{topic.name}</h2>
        <EditTopic topic={topic} />
        <DeleteTopic topic={topic} />
        <CollapseButton
          collapsed={collapsed}
          onClick={() => setCollapsed(!collapsed)}
        />
      </TopicNameWrapper>
      <TopicContent collapsed={collapsed}>
        {visibleSubtopics.map((subtopic, i) => (
          <EditorSubtopic
            subtopic={subtopic}
            previousSubtopic={visibleSubtopics[i - 1] ?? null}
            nextSubtopic={visibleSubtopics[i + 1] ?? null}
            highestPosition={highestSubtopicPosition}
            topicPosition={topic.position}
            key={subtopic.id}
          />
        ))}
        <AddSubtopic
          topicId={topic.id}
          topicMinimumRole={topic.minimumRole}
          highestSubtopicPosition={highestSubtopicPosition}
        />
      </TopicContent>
    </TopicWithDraggingTransparency>
  );
};

export default EditorTopic;
