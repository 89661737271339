import App from '@/lib/App';
import { useContext } from 'react';
import ShowPerspectiveCategorizationViewContext from '@/lib/contexts/ShowPerspectiveCategorizationViewContext';

const useContentIsWide = (): boolean => {
  const isDisplayingChallengePortfolio =
    App.navigation.useIsDisplayingChallengePortfolio();
  const [showPerspectiveCategorizationView] = useContext(
    ShowPerspectiveCategorizationViewContext,
  );

  return isDisplayingChallengePortfolio && !showPerspectiveCategorizationView;
};

export default useContentIsWide;
