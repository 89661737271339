import { Task } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';
import { useMemo } from 'react';

// TODO what does this do exactly?
function buildAttachmentState<V extends { id: number }>(
  object: Record<number, V> | null,
): Record<number, boolean> {
  if (object === null) {
    return {};
  }

  return Object.values(object).reduce(
    (object, element) => {
      object[element.id] = true;

      return object;
    },
    {} as Record<number, boolean>,
  );
}

const useAttachmentState = (task?: Task) => {
  const originalAttachedPerspectivesState = useMemo(
    () => buildAttachmentState(task?.directlyAttachedPerspectives ?? null),
    [task?.directlyAttachedPerspectives],
  );

  const originalAttachedSublevelsState = useMemo(
    () => buildAttachmentState(task?.attachedPerspectiveSublevels ?? null),
    [task?.attachedPerspectiveSublevels],
  );

  const [
    attachedPerspectives,
    setAttachedPerspectives,
    ,
    reenableAttachedPerspectivesSync,
  ] = useComplexSwrState(originalAttachedPerspectivesState);
  const [
    attachedSublevels,
    setAttachedSublevels,
    ,
    reenableAttachedSublevelsSync,
  ] = useComplexSwrState(originalAttachedSublevelsState);

  const attachPerspective = (id: number): void => {
    setAttachedPerspectives(attachedPerspectives => {
      attachedPerspectives[id] = true;
    });
  };

  const detachPerspective = (id: number): void => {
    setAttachedPerspectives(attachedPerspectives => {
      attachedPerspectives[id] = false;
    });
  };

  const attachSublevel = (id: number): void => {
    setAttachedSublevels(attachedSublevels => {
      attachedSublevels[id] = true;
    });
  };

  const detachSublevel = (id: number): void => {
    setAttachedSublevels(attachedSublevels => {
      attachedSublevels[id] = false;
    });
  };

  const reenableAttachmentStateSync = (): void => {
    reenableAttachedPerspectivesSync();
    reenableAttachedSublevelsSync();
  };

  const resetAttachmentState = (): void => {
    setAttachedPerspectives(originalAttachedPerspectivesState);
    setAttachedSublevels(originalAttachedSublevelsState);

    reenableAttachmentStateSync();
  };

  const anySublevelAttached = (sublevelIds: number[]): boolean => {
    const attachedSublevelIds = Object.entries(attachedSublevels)
      .filter(([_, attached]) => attached)
      .map(([id]) => Number(id));

    for (const sublevelId of sublevelIds) {
      if (attachedSublevelIds.includes(sublevelId)) {
        return true;
      }
    }

    return false;
  };

  return {
    attachedPerspectives,
    attachedSublevels,
    attachPerspective,
    detachPerspective,
    attachSublevel,
    detachSublevel,
    resetAttachmentState,
    reenableAttachmentStateSync,
    anySublevelAttached,
  };
};

export default useAttachmentState;
