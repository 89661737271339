import PerspectiveCategory from '@/lib/constants/PerspectiveCategory.enum';
import styled from '@emotion/styled';
import { useDrop } from 'react-dnd';
import DragType from './DragType.enum';
import React from 'react';
import { PerspectiveDropResult } from './DragDropTypes';

type CategoryWrapperProps = {
  category: PerspectiveCategory;
  children: React.ReactNode;
};

const StyledWrapper = styled('section')({
  marginBottom: 32,
  ':last-child': {
    marginBottom: 0,
  },
});

const CategoryWrapper = ({ category, children }: CategoryWrapperProps) => {
  const [, attachDropHandlingRef] = useDrop({
    accept: DragType.PERSPECTIVE,
    drop: (): PerspectiveDropResult => ({
      category,
    }),
  });

  return (
    <StyledWrapper
      ref={attachDropHandlingRef as unknown as React.Ref<HTMLDivElement>}
    >
      {children}
    </StyledWrapper>
  );
};

export default CategoryWrapper;
