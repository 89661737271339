import { useContext } from 'react';
import InnerDateField from '../DateField/InnerDateField';
import { isEarlier } from '@/lib/utils/dateUtils';
import convertToTimestamp from './utils/convertToTimestamp';
import useSavePortfolioItemDate from './utils/useSavePortfolioItemDate';
import FieldRendererContext from '../../../FieldRendererContext';
import moment from 'moment';

const PlannedEndDate = () => {
  const { portfolioItem, portfolioItemApiEndpoint } =
    useContext(FieldRendererContext);

  const savePortfolioItemDate = useSavePortfolioItemDate(
    `${portfolioItemApiEndpoint}/dates/planned-end-date`,
  );

  return (
    <InnerDateField
      initialValue={convertToTimestamp(portfolioItem.plannedEndDate)}
      onSave={savePortfolioItemDate}
      shouldDisableDate={
        portfolioItem.plannedStartDate === null
          ? undefined
          : date => isEarlier(date, moment(portfolioItem.plannedStartDate))
      }
      clearable
    />
  );
};

export default PlannedEndDate;
