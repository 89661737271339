/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import useApi from './useApi';

const useGlobalStaticApiResponse = <T>(
  url: string,
): [true, null] | [false, T] => {
  const api = useApi();

  const storedResponse = api.getStoredGlobalStaticResponse<T>(url);

  const [loading, setLoading] = useState(storedResponse === null);
  const [response, setResponse] = useState(storedResponse);

  const fetchApiResponse = async (): Promise<void> => {
    setLoading(true);

    const apiResponse = await api.getGlobalStatic<T>(url);

    setResponse(apiResponse);
    setLoading(false);
  };

  useEffect(() => {
    fetchApiResponse();
  }, [url]);

  if (loading || response === null) {
    return [true, null];
  }

  return [false, response];
};

export default useGlobalStaticApiResponse;
