import { useState } from 'react';
import PageTitle from '@/components/PageTitle';
import { Security as SecurityIcon } from '@mui/icons-material';
import AuditEventsWithSearchForm from './AuditEventsWithSearchForm';
import SecurityAuditEventsWithSearchForm from './SecurityAuditEventsWithSearchForm';
import { ButtonGroup } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import AuditPageHeader from './AuditPageHeader';
import EventTypeButton from './EventTypeButton';

const AuditPage = () => {
  const [isDisplayingSecurityAuditEvents, setIsDisplayingSecurityAuditEvents] =
    useState(false);

  return (
    <>
      <PageTitle id="audit.page_title" />
      <AuditPageHeader>
        <h1>
          <SecurityIcon />
          <FormattedMessage
            id={`audit.page_header.titles.${
              isDisplayingSecurityAuditEvents
                ? 'security_events'
                : 'user_activity'
            }`}
          />
        </h1>
        <ButtonGroup variant="outlined" size="small" color="secondary">
          <EventTypeButton
            onClick={() => setIsDisplayingSecurityAuditEvents(false)}
            selected={!isDisplayingSecurityAuditEvents}
            disabled={!isDisplayingSecurityAuditEvents}
          >
            <FormattedMessage id="audit.page_header.switch_buttons.user_activity" />
          </EventTypeButton>
          <EventTypeButton
            onClick={() => setIsDisplayingSecurityAuditEvents(true)}
            selected={isDisplayingSecurityAuditEvents}
            disabled={isDisplayingSecurityAuditEvents}
          >
            <FormattedMessage id="audit.page_header.switch_buttons.security_events" />
          </EventTypeButton>
        </ButtonGroup>
      </AuditPageHeader>
      {isDisplayingSecurityAuditEvents ? (
        <SecurityAuditEventsWithSearchForm />
      ) : (
        <AuditEventsWithSearchForm />
      )}
    </>
  );
};

export default AuditPage;
