import { useState, useContext } from 'react';
import LevelDialog from '@/components/RubricsPortfolioComponents/LevelDialog';
import EditorLevel from '../EditorLevel';
import AddLevel from '../AddLevel';
import StyledPart from '@/components/RubricsPortfolioComponents/StyledPart';
import PartName from '@/components/RubricsPortfolioComponents/StyledPart/PartName';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import getHighestPositionFromSortedItems from '@/pages/EditorPage/getHighestPositionFromSortedItems';
import EditPart from './EditPart';
import CopyPart from './CopyPart';
import DeletePart from './DeletePart';
import useMakeDraggable from '../useMakeDraggable';
import useApi from '@/lib/api/useApi';
import UserRole from '@/lib/constants/UserRole.enum';
import useEditorPartsApiEndpoint from './useEditorPartsApiEndpoint';
import usePartDragKey from './usePartDragKey';
import { EditorPart as EditorPartType } from '@/lib/types';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';

type EditorPartProps = {
  part: EditorPartType;
  previousPart: EditorPartType | null;
  nextPart: EditorPartType | null;
  highestPosition: number;
  subtopicPosition: number;
  noGroups?: boolean;
  noCopy?: boolean;
};

const EditorPart = ({
  part,
  previousPart,
  nextPart,
  highestPosition,
  subtopicPosition,
  noGroups,
  noCopy,
}: EditorPartProps) => {
  const api = useApi();
  const editorPartsApiEndpoint = useEditorPartsApiEndpoint();

  const [portfolioTemplates, setPortfolioTemplates] = useContext(
    EditorPortfolioTemplatesContext,
  );

  const partDragKey = usePartDragKey();
  const [isDragging, dragRef] = useMakeDraggable<HTMLDivElement>(
    partDragKey,
    part,
    previousPart,
    nextPart,
    highestPosition,
    (partId: number, position: number): void => {
      setPortfolioTemplates(portfolioTemplates => {
        portfolioTemplates.parts[partId].position = position;
      });

      api.put(`${editorPartsApiEndpoint}/${partId}/position`, {
        position,
      });
    },
    part.subtopicId,
    subtopicPosition,
    (oldSubtopicId: number, newSubtopicId: number): void => {
      // TODO refactor this (maybe move these functions to separate util file
      // for subtopics/parts)
      setPortfolioTemplates(portfolioTemplates => {
        portfolioTemplates.parts[part.id].subtopicId = newSubtopicId;

        const oldSubtopic = portfolioTemplates.subtopics[oldSubtopicId];

        oldSubtopic.parts.splice(
          oldSubtopic.parts.findIndex(({ id }) => id === part.id),
          1,
        );

        portfolioTemplates.subtopics[newSubtopicId].parts.push({
          id: part.id,
          // TODO this should be removed
          position: part.position,
        });
      });

      api.post(
        `editor/portfolio-templates/parts/${part.id}/position/set-subtopic`,
        {
          subtopicId: newSubtopicId,
        },
      );
    },
  );

  const [levelDialogOpen, setLevelDialogOpen] = useState(false);

  const allLevels = portfolioTemplates.levels;
  const desiredLevelsIds = part.levels.map(({ id }) => id);
  const levels = desiredLevelsIds
    .map(levelId => allLevels[levelId])
    .filter(level => !!level)
    .sort(sortByPosition);

  const highestLevelPosition = getHighestPositionFromSortedItems(levels);

  const editorOnly = part.minimumRole === UserRole.EDITOR;

  return (
    <>
      <StyledPart editor dragging={isDragging} ref={dragRef}>
        <PartName
          editorOnly={editorOnly}
          onClick={() => setLevelDialogOpen(true)}
        >
          {part.name}
        </PartName>
        <EditPart part={part} noGroups={noGroups ?? false} />
        {!noCopy && <CopyPart part={part} highestPosition={highestPosition} />}
        <DeletePart part={part} />
      </StyledPart>
      <LevelDialog
        open={levelDialogOpen}
        onClose={() => setLevelDialogOpen(false)}
        editor
      >
        <LevelDialog.Title onClose={() => setLevelDialogOpen(false)}>
          {part.name}
        </LevelDialog.Title>
        <LevelDialog.Content>
          <LevelDialog.InnerContentWrapper>
            <LevelDialog.LevelWrapper>
              {levels.map((level, i) => (
                <EditorLevel
                  level={level}
                  previousLevel={levels[i - 1] ?? null}
                  nextLevel={levels[i + 1] ?? null}
                  highestPosition={highestLevelPosition}
                  key={level.id}
                />
              ))}
              <AddLevel
                partId={part.id}
                highestLevelPosition={highestLevelPosition}
              />
            </LevelDialog.LevelWrapper>
          </LevelDialog.InnerContentWrapper>
        </LevelDialog.Content>
      </LevelDialog>
    </>
  );
};

export default EditorPart;
