import { useContext, useState } from 'react';
import Dialog from '@/components/Dialog';
import SettingsCheckbox from './SettingsCheckbox';
import SettingsDialogActions from './SettingsDialogActions';
import { FormattedMessage } from 'react-intl';
import SchoolContext from '../../../SchoolContext';
import useApi from '@/lib/api/useApi';
import { DialogProps } from '@mui/material';

type SettingsDialogProps = DialogProps & {
  onClose: () => void;
};

const SettingsDialog = ({ onClose, ...props }: SettingsDialogProps) => {
  const api = useApi();

  const [, school] = useContext(SchoolContext);

  const [useReversedSliders, setUseReversedSliders] = useState(
    school.instanceConfig.useReversedSliders,
  );
  const [enableUserSwitcherArrows, setEnableUserSwitcherArrows] = useState(
    school.instanceConfig.enableUserSwitcherArrows,
  );
  const [useProfilePictures, setUseProfilePictures] = useState(
    school.instanceConfig.useProfilePictures,
  );
  const [
    enableAggressiveRubricCollapsing,
    setEnableAggressiveRubricCollapsing,
  ] = useState(school.instanceConfig.enableAggressiveRubricCollapsing);

  const [saving, setSaving] = useState(false);

  const settingsHaveChanged =
    useReversedSliders !== school.instanceConfig.useReversedSliders ||
    enableUserSwitcherArrows !==
      school.instanceConfig.enableUserSwitcherArrows ||
    useProfilePictures !== school.instanceConfig.useProfilePictures ||
    enableAggressiveRubricCollapsing !==
      school.instanceConfig.enableAggressiveRubricCollapsing;

  const saveChanges = async (): Promise<void> => {
    setSaving(true);

    await api.put('instance-config', {
      instanceConfigValues: [
        {
          reference: 'useReversedSliders',
          value: JSON.stringify(useReversedSliders),
        },
        {
          reference: 'enableUserSwitcherArrows',
          value: JSON.stringify(enableUserSwitcherArrows),
        },
        {
          reference: 'useProfilePictures',
          value: JSON.stringify(useProfilePictures),
        },
        {
          reference: 'enableAggressiveRubricCollapsing',
          value: JSON.stringify(enableAggressiveRubricCollapsing),
        },
      ],
    });

    setSaving(false);
    onClose();

    window.location.reload();
  };

  return (
    <Dialog
      onClose={onClose}
      onEnterClose={saveChanges}
      dangerous={settingsHaveChanged}
      {...props}
    >
      <Dialog.Title>
        <FormattedMessage id="platform_settings.content_settings.title" />
      </Dialog.Title>
      <Dialog.Content>
        <Dialog.ContentText>
          <FormattedMessage id="platform_settings.content_settings.text" />
        </Dialog.ContentText>
        <SettingsCheckbox
          label="platform_settings.content_settings.checkboxes.use_reversed_sliders.label"
          checked={useReversedSliders}
          onChange={e => setUseReversedSliders(e.target.checked)}
        />
        <SettingsCheckbox
          label="platform_settings.content_settings.checkboxes.enable_user_switcher_arrows.label"
          checked={enableUserSwitcherArrows}
          onChange={e => setEnableUserSwitcherArrows(e.target.checked)}
        />
        <SettingsCheckbox
          label="platform_settings.content_settings.checkboxes.use_profile_pictures.label"
          checked={useProfilePictures}
          onChange={e => setUseProfilePictures(e.target.checked)}
        />
        <SettingsCheckbox
          label="platform_settings.content_settings.checkboxes.enable_aggressive_rubric_collapsing"
          checked={enableAggressiveRubricCollapsing}
          onChange={e => setEnableAggressiveRubricCollapsing(e.target.checked)}
        />
      </Dialog.Content>
      <SettingsDialogActions
        onClose={onClose}
        onSave={saveChanges}
        settingsHaveChanged={settingsHaveChanged}
        actionsDisabled={saving}
      />
    </Dialog>
  );
};

export default SettingsDialog;
