import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import {
  EditorLevelWithoutRelations,
  EditorPart,
  EditorPortfolioTemplates,
  SetComplexImmerStateAction,
} from '@/lib/types';
import useComplexState from '@/lib/utils/useComplexState';

// Mimicking (rubric) portfolio templates is necessary to make this
// challenge/task-scoped rubric editor work
const useMimicPortfolioTemplates = (): [
  EditorPortfolioTemplates,
  SetComplexImmerStateAction<EditorPortfolioTemplates>,
] => {
  const { field } = useContext(FieldContext);

  const partsKeyedById = field!.parts.reduce(
    (partsKeyedById, part) => {
      partsKeyedById[part.id] = {
        ...(part as unknown as EditorPart),
        groups: [],
      };

      return partsKeyedById;
    },
    {} as Record<number, EditorPart>,
  );

  const levels = field!.parts.reduce(
    (levels, part) => {
      for (const level of part.levels) {
        levels[level.id] = level;
      }

      return levels;
    },
    {} as Record<number, EditorLevelWithoutRelations>,
  );

  const [portfolioTemplates, setPortfolioTemplates] = useComplexState({
    parts: partsKeyedById,
    levels,
    topics: {},
    subtopics: {},
  });

  return [portfolioTemplates, setPortfolioTemplates];
};

export default useMimicPortfolioTemplates;
