import { useEffect } from 'react';

function useKeydownListener(
  targetElement: HTMLElement | Document | null,
  keydownHandler: (event: KeyboardEvent) => void,
): void {
  useEffect(() => {
    if (targetElement === null) {
      return;
    }

    targetElement.addEventListener(
      'keydown',
      keydownHandler as EventListenerOrEventListenerObject,
    );

    return () => {
      targetElement.removeEventListener(
        'keydown',
        keydownHandler as EventListenerOrEventListenerObject,
      );
    };
  });
}

export default useKeydownListener;
