import React from 'react';
import StyledSliderRail from './StyledSliderRail';

// TODO better name & move?
export type MouseMoveEvent =
  | React.MouseEvent<HTMLDivElement>
  | React.TouchEvent<HTMLDivElement>;

type SliderRailProps = {
  levelCount: number;
  onMouseMoveActivate: (event: MouseMoveEvent) => void;
  onMouseMoveDeactivate: (event: MouseMoveEvent) => void;
  onMouseMove: (event: MouseMoveEvent) => void;
  // TODO should be readonly (lowercase)
  readOnly: boolean;
  disabled: boolean;
  children: React.ReactNode;
  ref: React.Ref<HTMLDivElement>;
};

const SliderRail = ({
  onMouseMoveActivate,
  onMouseMoveDeactivate,
  onMouseMove,
  ref,
  ...props
}: SliderRailProps) => (
  <StyledSliderRail
    onMouseDown={onMouseMoveActivate}
    onMouseUp={onMouseMoveDeactivate}
    onMouseLeave={onMouseMoveDeactivate}
    onMouseMove={onMouseMove}
    onTouchStart={onMouseMoveActivate}
    onTouchEnd={onMouseMoveDeactivate}
    onTouchCancel={onMouseMoveDeactivate}
    onTouchMove={onMouseMove}
    ref={ref}
    {...props}
  />
);

export default SliderRail;
