const immerCompatibleArraySort = <T>(
  array: T[],
  sortFunction: ((a: T, b: T) => number) | undefined,
) => {
  // This copies the array but not its elements
  const arrayCopy = array.slice();

  return arrayCopy.sort(sortFunction);
};

export default immerCompatibleArraySort;
