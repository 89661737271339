import FieldName from '../utils/FieldName';
import Space from '@/components/Space';
import TextEditor from '../utils/TextEditor';
import useSetCurrentFieldValue from '../utils/useSetCurrentFieldValue';
import useCurrentFieldValue from '../utils/useCurrentFieldValue';

type EditTextProps = {
  onEditClose: () => void;
};

const EditText = ({ onEditClose }: EditTextProps) => {
  const initialValue = useCurrentFieldValue<string>(
    '',
    value => typeof value === 'string',
  );
  const [setCurrentFieldValue, saving] = useSetCurrentFieldValue<
    string | null
  >();

  const handleSave = async (value: string | null): Promise<void> => {
    await setCurrentFieldValue(value);

    onEditClose();
  };

  return (
    <section>
      <FieldName />
      <Space height={12} />
      <TextEditor
        initialValue={initialValue.toString()}
        onSave={handleSave}
        disabled={saving}
      />
    </section>
  );
};

export default EditText;
