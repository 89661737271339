import styled from '@emotion/styled';

type TopicWithDraggingTransparencyProps = {
  collapsed: boolean;
  dragging: boolean;
};

const TopicWithDraggingTransparency = styled(
  'section',
)<TopicWithDraggingTransparencyProps>(({ theme, collapsed, dragging }) => ({
  opacity: dragging ? 0.5 : 1,
  transition: theme.transitions.create('all'),
  ':last-of-type': {
    marginBottom: collapsed ? -24 : undefined,
  },
}));

export default TopicWithDraggingTransparency;
