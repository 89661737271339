import { useTheme } from '@emotion/react';
import { useIntl } from 'react-intl';
import egodactLogoDefault from './egodact-logo-default.svg';
import egodactLogoWhite from './egodact-logo-white.svg';
import egodactLogoWhiteBlueText from './egodact-logo-white-blue-text.svg';
import { HTMLImgProps } from '@/lib/types';

type EgodactLogoProps = HTMLImgProps & {
  blueTextInDarkMode?: boolean;
};

const useLogo = (blueTextInDarkMode: boolean): string => {
  const theme = useTheme();

  if (theme.isDark && blueTextInDarkMode) {
    return egodactLogoWhiteBlueText;
  }

  if (theme.isDark) {
    return egodactLogoWhite;
  }

  return egodactLogoDefault;
};

const EgodactLogo = ({ blueTextInDarkMode, ...props }: EgodactLogoProps) => {
  const logo = useLogo(blueTextInDarkMode ?? false);
  const intl = useIntl();

  return (
    <img
      src={logo}
      alt={intl.formatMessage({
        id: 'egodact_logo.alt',
      })}
      {...props}
    />
  );
};

export default EgodactLogo;
