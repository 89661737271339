import { useContext } from 'react';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import PortfolioItemFields from '../../PortfolioItemFields';
import ChallengeContext from './ChallengeContext';
import ModelType from '@/lib/constants/challengeModel/ModelType.enum';
import { ChallengeSummary } from '@/lib/types';
import { DraftFunction } from 'use-immer';
import NewlySharedContext from '../../NewlySharedChallenges/NewlySharedContext';

const ChallengeFields = () => {
  const [isNewlyShared, , setNewlySharedChallenges] =
    useContext(NewlySharedContext);
  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const [challenge, setChallenge] = useContext(ChallengeContext);

  const setChallengeSummary = (
    stateUpdateFunction: DraftFunction<ChallengeSummary>,
  ): void => {
    if (isNewlyShared) {
      setNewlySharedChallenges(newlySharedChallenges => {
        stateUpdateFunction(newlySharedChallenges[challenge.id]);
      });

      return;
    }

    setChallengePortfolio(challengePortfolio => {
      stateUpdateFunction(challengePortfolio.challenges[challenge.id]);
    });
  };

  const challengeApiEndpoint = `challenge-portfolio/challenges/${challenge.id}`;

  return (
    <PortfolioItemFields
      modelType={ModelType.CHALLENGE}
      portfolioItem={challenge}
      setPortfolioItem={setChallenge}
      setPortfolioItemSummary={setChallengeSummary}
      portfolioItemApiEndpoint={challengeApiEndpoint}
    />
  );
};

export default ChallengeFields;
