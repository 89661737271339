import { useState } from 'react';
import UserAvatarMenuButton from './UserAvatarMenuButton';
import WideMenu from './WideMenu';
import MenuHeader from './MenuHeader';
import CurrentUserInfo from './CurrentUserInfo';
import SubmenuHeader from './SubmenuHeader';
import MenuContent from './MenuContent';
import LanguagePickerButton from './Options/LanguagePickerButton';
import DisplaySettingsButton from './Options/DisplaySettingsButton';
import ReleasesButton from './Options/ReleasesButton';
import DividerWithSpace from './DividerWithSpace';
import LogoutButton from './Options/LogoutButton';
import LanguagePicker from './Submenus/LanguagePicker';
import DisplaySettings from './Submenus/DisplaySettings';
import Releases from './Submenus/Releases';
import SubmenuType from './SubmenuType.enum';
import MenuContentPanel from './MenuContent/MenuContentPanel';

const OptionsMenu = () => {
  const [selectedSubmenu, setSelectedSubmenu] = useState<SubmenuType | null>(
    null,
  );
  const hasMovedToSubmenu = !!selectedSubmenu;

  const [menuAnchorElement, setMenuAnchorElement] =
    useState<HTMLButtonElement | null>(null);

  const menuOpen = !!menuAnchorElement;

  const openMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchorElement(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorElement(null);

    // Wait for the menu close animation to finish before leaving the submenu
    setTimeout(() => {
      setSelectedSubmenu(null);
    }, 400);
  };

  return (
    <>
      <UserAvatarMenuButton onClick={openMenu} />
      <WideMenu
        open={menuOpen}
        anchorEl={menuAnchorElement}
        onClose={closeMenu}
      >
        <MenuHeader hasMovedToSubmenu={hasMovedToSubmenu}>
          <MenuContentPanel invisible={hasMovedToSubmenu}>
            {!hasMovedToSubmenu && <CurrentUserInfo />}
          </MenuContentPanel>
          <MenuContentPanel>
            <SubmenuHeader
              selectedSubmenu={selectedSubmenu}
              onGoBack={() => setSelectedSubmenu(null)}
            />
          </MenuContentPanel>
        </MenuHeader>
        <MenuContent hasMovedToSubmenu={hasMovedToSubmenu}>
          <MenuContentPanel invisible={hasMovedToSubmenu}>
            <LanguagePickerButton
              onClick={() => setSelectedSubmenu(SubmenuType.LANGUAGE_PICKER)}
              disabled={hasMovedToSubmenu}
            />
            <DisplaySettingsButton
              onClick={() => setSelectedSubmenu(SubmenuType.DISPLAY_SETTINGS)}
              disabled={hasMovedToSubmenu}
            />
            <ReleasesButton
              onClick={() => setSelectedSubmenu(SubmenuType.RELEASES)}
              disabled={hasMovedToSubmenu}
            />
            <DividerWithSpace />
            <LogoutButton disabled={hasMovedToSubmenu} />
          </MenuContentPanel>
          <MenuContentPanel>
            {selectedSubmenu === SubmenuType.LANGUAGE_PICKER && (
              <LanguagePicker onCloseSubmenu={() => setSelectedSubmenu(null)} />
            )}
            {selectedSubmenu === SubmenuType.DISPLAY_SETTINGS && (
              <DisplaySettings />
            )}
            {selectedSubmenu === SubmenuType.RELEASES && <Releases />}
          </MenuContentPanel>
        </MenuContent>
      </WideMenu>
    </>
  );
};

export default OptionsMenu;
