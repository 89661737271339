import styled from '@emotion/styled';
import { CONNECTOR_WRAPPER_WIDTH, getConnectorStyles } from './styleConstants';
import {
  CARD_HEIGHT,
  CARD_MARGIN_BOTTOM,
} from '@/components/perspectives/PerspectiveCard/styleConstants';

type LowerCardConnectorProps = {
  origin: number;
  target: number;
  highlighted: boolean;
};

const LowerCardConnector = styled('div')<LowerCardConnectorProps>(({
  theme,
  origin,
  target,
  highlighted,
}) => {
  const connectorStyles = getConnectorStyles(theme);

  return {
    position: 'absolute',
    top:
      origin * (CARD_HEIGHT + CARD_MARGIN_BOTTOM) -
      connectorStyles.thickness / 2,
    left: 0,
    width: '100%',
    height:
      Math.abs((target - origin) * (CARD_HEIGHT + CARD_MARGIN_BOTTOM)) +
      connectorStyles.thickness,
    boxSizing: 'border-box',
    zIndex: highlighted ? 2 : 1,
    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: `calc(50% + ${connectorStyles.thickness / 2}px)`,
      height: '50%',
      boxSizing: 'border-box',
      borderRight: `${connectorStyles.thickness}px solid ${connectorStyles.color}`,
      borderTop: `${connectorStyles.thickness}px solid ${connectorStyles.color}`,
      borderTopRightRadius: CONNECTOR_WRAPPER_WIDTH / 2,
      borderColor: highlighted ? connectorStyles.highlightColor : undefined,
    },
    '::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      right: 0,
      width: `calc(50% + ${connectorStyles.thickness / 2}px)`,
      height: '50%',
      boxSizing: 'border-box',
      borderLeft: `${connectorStyles.thickness}px solid ${connectorStyles.color}`,
      borderBottom: `${connectorStyles.thickness}px solid ${connectorStyles.color}`,
      borderBottomLeftRadius: CONNECTOR_WRAPPER_WIDTH / 2,
      borderColor: highlighted ? connectorStyles.highlightColor : undefined,
    },
  };
});

export default LowerCardConnector;
