import { useState } from 'react';
import device from 'current-device';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import RowPicker from './RowPicker';

// This is necessary because dragging cards doesn't quite work on mobile
// devices
const MobileRowPicker = () => {
  const [pickerOpen, setPickerOpen] = useState(false);

  if (device.desktop()) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setPickerOpen(true)}
        disableElevation
      >
        <FormattedMessage id="portfolio.challenge.newly_shared.row_picker.button" />
      </Button>
      <RowPicker open={pickerOpen} onClose={() => setPickerOpen(false)} />
    </>
  );
};

export default MobileRowPicker;
