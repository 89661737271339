import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';
import { Fab, FabProps } from '@mui/material';

export type StyledAddFeedbackButtonProps = FabProps & {
  belowFeedbackList: boolean;
};

const StyledAddFeedbackButton = styled(
  stripProps(Fab, 'belowFeedbackList'),
)<StyledAddFeedbackButtonProps>(
  ({ theme }) => ({
    transition: theme.transitions.create('all'),
  }),
  ({ belowFeedbackList }) => {
    if (belowFeedbackList) {
      return {
        width: 232,
      };
    }

    return {
      position: 'absolute',
      bottom: 0,
      right: 0,
    };
  },
);

export default StyledAddFeedbackButton;
