import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

const CountTextWrapper = styled('p')(
  ({ theme }) => ({
    marginTop: 0,
    marginBottom: 12,
    color: theme.palette.text.secondary,
    [mediaQuery(1600)]: {
      marginBottom: 18,
      textAlign: 'center',
    },
    [mediaQuery(672)]: {
      fontSize: 14,
      textAlign: 'left',
    },
  }),
  addHighContrastStyles({
    fontStyle: 'italic',
  }),
);

export default CountTextWrapper;
