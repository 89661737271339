import { useState } from 'react';
import Space from '@/components/Space';
import { Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import ChallengeCreationDialog from '../../CreateChallengeWithRowSelection/ChallengeCreationDialog';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

const CreateFirstChallenge = () => {
  const [challengeCreationDialogOpen, setChallengeCreationDialogOpen] =
    useState(false);

  return (
    <>
      <br />
      <Space height={12} />
      <Button
        onClick={() => setChallengeCreationDialogOpen(true)}
        startIcon={<AddIcon />}
      >
        <FormattedMessageWithChallengeNaming id="portfolio.challenge_portfolio.no_challenges.create_first_challenge_button" />
      </Button>
      <ChallengeCreationDialog
        open={challengeCreationDialogOpen}
        onClose={() => setChallengeCreationDialogOpen(false)}
        rowIds={[]}
      />
    </>
  );
};

export default CreateFirstChallenge;
