import styled from '@emotion/styled';
import Dialog from '@/components/Dialog';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

export const UserTableCellContent = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

export const UserDialogTitle = styled(Dialog.Title)({
  display: 'flex',
  alignItems: 'center',
  '.MuiAvatar-root, .MuiCircularProgress-root, .MuiSvgIcon-root': {
    marginRight: 12,
  },
  '.MuiSvgIcon-root': {
    marginRight: 12,
    width: 21,
    height: 21,
  },
});

export const UserRoleIndicator = styled('span')(({ theme }) => {
  const indicatorBackgroundColor = theme.isDark
    ? theme.palette.primary.light
    : theme.palette.primary.dark;

  return {
    marginLeft: 12,
    padding: '1px 3px',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: theme.palette.getContrastText(indicatorBackgroundColor),
    backgroundColor: indicatorBackgroundColor,
    borderRadius: 3,
  };
});

export const UserDialogContent = styled(Dialog.Content)(({ theme }) => ({
  color: theme.palette.text.secondary,
  p: {
    marginTop: 4,
    marginBottom: 4,
  },
}));

export const UserDeletedMessage = styled('p')(
  ({ theme }) => ({
    color: theme.isDark
      ? theme.palette.warning.light
      : theme.palette.warning.dark,
    display: 'flex',
    alignItems: 'center',
    '&&&': {
      marginTop: 12,
    },
    '.MuiSvgIcon-root': {
      marginRight: 6,
    },
  }),
  addHighContrastStyles({
    fontStyle: 'italic',
  }),
);
