import App from '@/lib/App';
import { useTheme } from '@emotion/react';
import ProgressBar from './ProgressBar';
import SliderIcon from './SliderIcon';
import React from 'react';
import IconVariant from './IconVariant.enum';

type CoachProgressBarProps = {
  progress: number;
  studentProgress: number;
  isSliding: boolean;
};

const reversedBorderRadiusCorrectionStyles = (
  progress: number,
  studentProgress: number,
): React.CSSProperties => ({
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: progress > 99 ? 4 : undefined,
  borderTopLeftRadius:
    progress === studentProgress ? 9 : progress > 99 ? 4 : undefined,
  borderTopRightRadius: studentProgress === 0 ? 9 : 4,
});

const normalBorderRadiusCorrectionStyles = (
  progress: number,
  studentProgress: number,
): React.CSSProperties => ({
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: progress > 99 ? 4 : undefined,
  borderTopRightRadius:
    progress === studentProgress ? 9 : progress > 99 ? 4 : undefined,
  borderTopLeftRadius: studentProgress === 0 ? 9 : 4,
});

const CoachProgressBar = ({
  progress,
  studentProgress,
  isSliding,
}: CoachProgressBarProps) => {
  const useReversedSliders =
    App.instanceConfig.useSetting('useReversedSliders');
  const theme = useTheme();

  return (
    <ProgressBar
      progress={progress}
      otherProgress={studentProgress}
      color={theme.palette.sliders.coach}
      noTransition={isSliding}
      style={
        useReversedSliders
          ? reversedBorderRadiusCorrectionStyles(progress, studentProgress)
          : normalBorderRadiusCorrectionStyles(progress, studentProgress)
      }
    >
      <SliderIcon variant={IconVariant.COACH} isSliding={isSliding} />
    </ProgressBar>
  );
};

export default CoachProgressBar;
