import React, { createContext } from 'react';

type PromptTextFieldInjectedProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  helperText: React.ReactNode;
  error: boolean;
  inputRef: React.Ref<HTMLInputElement>;
  multiline: boolean;
};

const PromptTextFieldInjectedPropsContext =
  createContext<PromptTextFieldInjectedProps>({
    value: '',
    onChange: () => {},
    helperText: null,
    error: false,
    inputRef: null,
    multiline: false,
  });

export default PromptTextFieldInjectedPropsContext;
