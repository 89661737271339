import React, { useState, useRef } from 'react';
import isValidUrl from '@/lib/utils/isValidUrl';
import Dialog from '@/components/Dialog';
import Prompt from '@/components/Prompt';
import WarningText from './WarningText';
import DialogAddButton from './DialogAddButton';
import { FormattedMessage } from 'react-intl';

type ProofPromptProps = {
  open: boolean;
  onOk: (input: string) => void;
  onCancel: () => void;
  children: React.ReactNode;
};

const ProofPrompt = ({ open, onOk, onCancel, children }: ProofPromptProps) => {
  const [input, setInput] = useState('');
  const [showInvalidUrlWarning, setShowInvalidUrlWarning] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);

  const handleCancel = () => {
    setShowInvalidUrlWarning(false);
    setShowEmptyError(false);
    onCancel();
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOk = () => {
    if (inputRef.current === null) {
      return;
    }

    if (!input.trim()) {
      setShowEmptyError(true);
      inputRef.current.focus();

      return;
    }

    onOk(input.trim());

    setInput('');
    setShowInvalidUrlWarning(false);
    setShowEmptyError(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInput = e.target.value;

    setInput(newInput);
    setShowEmptyError(false);

    const hasInput = !!newInput.trim();
    const urlIsValid = isValidUrl(newInput);
    setShowInvalidUrlWarning(hasInput && !urlIsValid);
  };

  return (
    <Dialog open={open} onClose={handleCancel} onEnterClose={handleOk}>
      {children}
      <Prompt.TextField
        label={<FormattedMessage id="portfolio.level.proof.add.input_label" />}
        value={input}
        onChange={handleInputChange}
        error={showEmptyError}
        helperText={
          (showInvalidUrlWarning && (
            <WarningText>
              <FormattedMessage id="portfolio.level.proof.add.invalid_url_warning" />
            </WarningText>
          )) ||
          (showEmptyError && (
            <FormattedMessage id="portfolio.level.proof.empty_error" />
          ))
        }
        inputRef={inputRef}
      />
      <Dialog.Actions addTopMargin>
        <Dialog.Actions.CancelButton onClick={handleCancel} />
        <DialogAddButton onClick={handleOk} />
      </Dialog.Actions>
    </Dialog>
  );
};

export default ProofPrompt;
