import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';

const GraphWrapper = styled('footer')(
  ({ theme }) => ({
    marginTop: 12,
    marginRight: 24,
    maxWidth: 324,
    backgroundColor: theme.palette.action.hover,
    borderRadius: 4,
    h4: {
      padding: 10,
      paddingBottom: 0,
      color: theme.palette.text.secondary,
    },
    [mediaQuery(1600)]: {
      marginTop: 0,
      marginBottom: 12,
      marginRight: 0,
      maxWidth: '100%',
      order: 0,
    },
  }),
  addHighContrastStyles({
    '.MuiLineElement-series-security-events': {
      strokeDasharray: '10 5',
    },
  }),
  addBorderInHighContrastMode,
);

export default GraphWrapper;
