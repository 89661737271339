import React, { useContext, useState } from 'react';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import DeleteButton from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/DeleteChallenge/DeleteButton';
import TaskContext from '../TaskContext';
import useApi from '@/lib/api/useApi';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import { TaskSummary } from '@/lib/types';
import NewlySharedContext from '../../../NewlySharedChallenges/NewlySharedContext';

type DeleteTaskProps = {
  ButtonComponent?: React.ElementType;
};

const DeleteTask = ({
  ButtonComponent: ButtonComponentOrUndefined,
}: DeleteTaskProps) => {
  const api = useApi();

  const [isNewlyShared, , setNewlySharedChallenges] =
    useContext(NewlySharedContext);
  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const [challenge, setChallenge] = useContext(ChallengeContext);
  const [task] = useContext(TaskContext);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteChallenge = async (): Promise<void> => {
    setConfirmationDialogOpen(false);

    setDeleting(true);

    await api.delete(`challenge-portfolio/tasks/${task.id}`);

    setDeleting(false);
    setConfirmationDialogOpen(false);

    setChallenge(challenge => {
      delete challenge.tasks[task.id];
    });

    if (isNewlyShared) {
      setNewlySharedChallenges(newlySharedChallenges => {
        // TODO this logic/typing should be reviewed
        const updatedTasks = Array.isArray(
          newlySharedChallenges[challenge.id].tasks,
        )
          ? [...(newlySharedChallenges[challenge.id].tasks as TaskSummary[])]
          : Object.values(newlySharedChallenges[challenge.id].tasks);

        updatedTasks.splice(
          updatedTasks.findIndex(({ id }) => id === task.id),
          1,
        );

        newlySharedChallenges[challenge.id] = {
          ...newlySharedChallenges[challenge.id],
          tasks: updatedTasks,
        };
      });

      return;
    }

    setChallengePortfolio(challengePortfolio => {
      // TODO this logic/typing should be reviewed
      const updatedTasks = Array.isArray(
        challengePortfolio.challenges[challenge.id].tasks,
      )
        ? [
            ...(challengePortfolio.challenges[challenge.id]
              .tasks as TaskSummary[]),
          ]
        : Object.values(challengePortfolio.challenges[challenge.id].tasks);

      updatedTasks.splice(
        updatedTasks.findIndex(({ id }) => id === task.id),
        1,
      );

      challengePortfolio.challenges[challenge.id] = {
        ...challengePortfolio.challenges[challenge.id],
        tasks: updatedTasks,
      };
    });
  };

  const ButtonComponent = ButtonComponentOrUndefined ?? DeleteButton;

  return (
    <>
      <ButtonComponent onClick={() => setConfirmationDialogOpen(true)} />
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.challenge.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id={'portfolio.challenge.delete.text.as_task'} />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
            disabled={deleting}
          />
          <Dialog.Actions.DeleteButton
            onClick={deleteChallenge}
            disabled={deleting}
          />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteTask;
