import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import { ListItemIcon } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ActionMenuButtonProps } from '@/components/CollapsibleActionMenu/ActionMenuButton';

type EditFieldNameButtonProps = Omit<ActionMenuButtonProps, 'children'>;

const EditFieldNameButton = (props: EditFieldNameButtonProps) => (
  <CollapsibleActionMenu.Button {...props}>
    <ListItemIcon>
      <EditIcon />
    </ListItemIcon>
    <FormattedMessage id="editor.model_editor.actions.edit_name" />
  </CollapsibleActionMenu.Button>
);

export default EditFieldNameButton;
