import React from 'react';
import createLinkDetectionPlugin from './link-detection-plugin';
import createListPlugin from 'draft-js-list-plugin';
import Editor from '@draft-js-plugins/editor';
import { EditorProps, KeyBindingUtil } from 'draft-js';
import PluginEditor from '@draft-js-plugins/editor/lib/Editor';

type InnerEditorProps = EditorProps & {
  onEnterSave: () => void;
  ref: React.Ref<PluginEditor | null>;
};

const linkDetectionPlugin = createLinkDetectionPlugin();
const listPlugin = createListPlugin({
  olRegex: /1[.)]/,
});

const convertKeyEventToCommand = (
  event: React.KeyboardEvent,
  editorManager?: unknown,
): string => {
  if (event.key === 'Enter' && KeyBindingUtil.hasCommandModifier(event)) {
    return 'enter-save';
  }

  return listPlugin.keyBindingFn(event, editorManager);
};

const InnerEditor = ({ onEnterSave, ref, ...props }: InnerEditorProps) => {
  const handleKeyCommand = (command: string): 'handled' | 'not-handled' => {
    if (command === 'enter-save') {
      onEnterSave();

      return 'handled';
    }

    return 'not-handled';
  };

  return (
    <Editor
      ref={ref}
      plugins={[linkDetectionPlugin, listPlugin]}
      handleKeyCommand={handleKeyCommand}
      keyBindingFn={convertKeyEventToCommand}
      spellCheck
      {...props}
    />
  );
};

export default InnerEditor;
