import React, { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';

function extractPosition(item: DraggableItem): number;
function extractPosition(item: null): null;
function extractPosition(item: DraggableItem | null): number | null;
function extractPosition(item: DraggableItem | null): number | null {
  if (typeof item?.position !== 'number') {
    return null;
  }

  return item.position;
}

type DraggableItem = {
  id: number;
  position: number;
};

type DraggedItem = DraggableItem & {
  type: string;
  parentId?: number;
  parentPosition?: number;
  onUpdateParentAfterDrag?: (oldParentId: number, newParentId: number) => void;
};

// TODO refactor this
const useMakeDraggable = <ElementType = HTMLElement,>(
  type: string,
  item: DraggableItem,
  previousItem: DraggableItem | null,
  nextItem: DraggableItem | null,
  highestPosition: number,
  onUpdatePosition: (id: number, position: number) => void,
  parentId?: number,
  parentPosition?: number,
  onUpdateParentAfterDrag?: (oldParentId: number, newParentId: number) => void,
): [boolean, React.RefObject<ElementType | null>] => {
  const dragRef = useRef<ElementType>(null);

  const performDrag = (draggedItem: DraggedItem, draggingUp: boolean): void => {
    const itemPosition = extractPosition(item);
    const previousItemPosition = extractPosition(previousItem) ?? 0;
    const nextItemPosition = extractPosition(nextItem) ?? -1;

    let newPosition = draggingUp
      ? (itemPosition + previousItemPosition) / 2
      : (itemPosition + nextItemPosition) / 2;

    if (nextItemPosition === -1) {
      newPosition = Math.ceil(highestPosition) + 1;
    }

    onUpdatePosition(draggedItem.id, newPosition);

    if (
      draggedItem.onUpdateParentAfterDrag &&
      draggedItem.parentId !== parentId &&
      typeof draggedItem.parentId !== 'undefined' &&
      typeof parentId !== 'undefined'
    ) {
      const oldParentId = draggedItem.parentId;
      const newParentId = parentId;
      draggedItem.onUpdateParentAfterDrag(oldParentId, newParentId);
    }
  };

  const [, drop] = useDrop({
    accept: type,
    drop: (draggedItem: DraggedItem): void => {
      if (draggedItem.id === item.id) {
        return;
      }

      const itemPosition = extractPosition(item);
      const draggedItemPosition = extractPosition(draggedItem);

      const draggingUp =
        draggedItem.parentId === parentId ||
        typeof draggedItem.parentPosition === 'undefined' ||
        typeof parentPosition === 'undefined'
          ? draggedItemPosition > itemPosition
          : draggedItem.parentPosition < parentPosition;
      const draggingDown =
        draggedItem.parentId === parentId ||
        typeof draggedItem.parentPosition === 'undefined' ||
        typeof parentPosition === 'undefined'
          ? draggedItemPosition < itemPosition
          : draggedItem.parentPosition > parentPosition;

      if (draggedItem.parentId !== parentId) {
        performDrag(draggedItem, draggingUp);
        return;
      }

      if (
        (draggingUp && draggedItem.id === previousItem?.id) ||
        (draggingDown && draggedItem.id === nextItem?.id)
      ) {
        return;
      }

      performDrag(draggedItem, draggingUp);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: {
      type,
      ...item,
      parentId,
      parentPosition,
      onUpdateParentAfterDrag,
    } satisfies DraggedItem,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(dragRef));

  return [isDragging, dragRef];
};

export default useMakeDraggable;
