import styled from '@emotion/styled';
import { alpha } from '@mui/material';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledNewlySharedInfo = styled('div')(
  ({ theme }) => ({
    margin: '-24px -48px',
    marginBottom: 12,
    padding: '16px 48px',
    fontFamily: 'CircularStd',
    color: theme.palette.text,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [mediaQuery(720)]: {
      display: 'block',
    },
    [mediaQuery(560)]: {
      margin: -24,
      marginBottom: 12,
      padding: '16px 24px',
    },
    section: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    '.MuiSvgIcon-root': {
      marginRight: 16,
      color: theme.palette.primary.main,
    },
    '.MuiButton-root': {
      marginLeft: 16,
      minWidth: 180,
      [mediaQuery(720)]: {
        marginTop: 18,
        marginLeft: 40,
      },
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    backgroundColor: 'transparent',
    borderBottom: `2px solid ${theme.palette.divider}`,
  })),
);

export default StyledNewlySharedInfo;
