import useFormatMessageWithChallengeNaming from '@/lib/utils/useFormatMessageWithChallengeNaming';
import StyledInput from './StyledInput';
import { HTMLInputProps } from '@/lib/types';

type SearchInputProps = Omit<HTMLInputProps, 'onChange' | 'onFocus'> & {
  value: string;
  onChange: (newValue: string) => void;
  onEmptied: () => void;
  onEscPress: (event: KeyboardEvent) => void;
  visible: boolean;
};

const SearchInput = ({
  onChange,
  onEmptied,
  onEscPress,
  ...props
}: SearchInputProps) => {
  const formatMessageWithChallengeNaming =
    useFormatMessageWithChallengeNaming();

  return (
    <StyledInput
      placeholder={formatMessageWithChallengeNaming({
        id: 'challenge_search_bar.placeholder',
      })}
      type="text"
      onChange={e => {
        const newValue = e.target.value;
        onChange(newValue);

        if (newValue === '') {
          onEmptied();
        }
      }}
      onEscPress={(event: KeyboardEvent) => {
        onChange('');
        onEscPress(event);
      }}
      {...props}
    />
  );
};

export default SearchInput;
