import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';
import { PopperProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

type AutocompleteOptionsWrapperProps = PopperProps & {
  open: boolean;
};

const AutocompleteOptionsWrapper = styled(
  stripProps('div', 'anchorEl', 'disablePortal'),
)<AutocompleteOptionsWrapperProps>(({ theme, open }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderTop: 'none',
  boxSizing: 'border-box',
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  // Necessary to override the custom width styles provided by the parent
  // Autocomplete component
  width: '100% !important',
  display: !open ? 'none' : undefined,
  // This adds a more reasonable height limit to the autocomplete options list
  // box
  ul: {
    maxHeight: 200,
    [mediaQuery(450)]: {
      maxHeight: 300,
    },
  },
}));

export default AutocompleteOptionsWrapper;
