// TODO rename for clarity throughout all code
enum AppMode {
  PORTFOLIO = 'portfolio_mode',
  COACH = 'coach_mode',
  EDITOR = 'editor_mode',
  ADMIN = 'admin_mode',
  AUDIT = 'audit_mode',
  PLATFORM_SETTINGS = 'platform_settings_mode',
}

export default AppMode;
