import dummyFunction from '@/lib/utils/dummyFunction';
import { createContext } from 'react';

type RowChallengesVisibilityState = {
  registerChallengeVisibility: (id: number, visible: boolean) => void;
};

const RowChallengesVisibilityStateContext =
  createContext<RowChallengesVisibilityState>({
    registerChallengeVisibility: dummyFunction,
  });

export default RowChallengesVisibilityStateContext;
