import App from '@/lib/App';
import { useTheme } from '@emotion/react';
import ProgressBar from './ProgressBar';
import SliderIcon from './SliderIcon';
import IconVariant from './IconVariant.enum';
import React from 'react';

type StudentProgressBarProps = {
  progress: number;
  coachProgress: number;
  isSliding: boolean;
};

const reversedBorderRadiusCorrectionStyles = (
  progress: number,
): React.CSSProperties => ({
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  borderTopLeftRadius: progress > 99 ? 4 : undefined,
  borderBottomLeftRadius: progress > 99 ? 4 : undefined,
});

const normalBorderRadiusCorrectionStyles = (
  progress: number,
): React.CSSProperties => ({
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  borderTopRightRadius: progress > 99 ? 4 : undefined,
  borderBottomRightRadius: progress > 99 ? 4 : undefined,
});

const StudentProgressBar = ({
  progress,
  coachProgress,
  isSliding,
}: StudentProgressBarProps) => {
  const useReversedSliders =
    App.instanceConfig.useSetting('useReversedSliders');
  const theme = useTheme();

  return (
    <ProgressBar
      progress={progress}
      otherProgress={coachProgress}
      color={theme.palette.sliders.student}
      noTransition={isSliding}
      style={
        useReversedSliders
          ? reversedBorderRadiusCorrectionStyles(progress)
          : normalBorderRadiusCorrectionStyles(progress)
      }
    >
      <SliderIcon variant={IconVariant.STUDENT} isSliding={isSliding} />
    </ProgressBar>
  );
};

export default StudentProgressBar;
