import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';

const TasksIconButton = styled(IconButton)(
  ({ theme }) => ({
    width: 48,
    height: 48,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
    ':hover': {
      backgroundColor: theme.palette.action.selected,
    },
    [mediaQuery(640)]: {
      marginRight: -12,
      width: 40,
      height: 40,
    },
  }),
  addBorderInHighContrastMode,
);

export default TasksIconButton;
