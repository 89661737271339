import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const usePreselectedSchoolHandlingState = (): [
  boolean,
  (hasHandledPreselectedSchool: boolean) => void,
] => {
  const [hasHandledPreselectedSchool, setHasHandledPreselectedSchool] =
    useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const updateHasHandledPreselectedSchool = (
    hasHandledPreselectedSchool: boolean,
  ): void => {
    setHasHandledPreselectedSchool(hasHandledPreselectedSchool);

    if (!hasHandledPreselectedSchool) {
      return;
    }

    const urlQueryParams = new URLSearchParams(location.search);

    if (!urlQueryParams.has('school')) {
      return;
    }

    urlQueryParams.delete('school');

    navigate(`.${urlQueryParams.toString()}`, {
      replace: true,
    });
  };

  return [hasHandledPreselectedSchool, updateHasHandledPreselectedSchool];
};

export default usePreselectedSchoolHandlingState;
