import { Button, ButtonProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';

const ButtonAsA = (props: ButtonProps<'a'>) => (
  <Button component="a" {...props} />
);

const StyledButton = styled(ButtonAsA)({
  fontSize: 13,
});

const ViewAllReleasesButton = () => (
  <StyledButton
    variant="outlined"
    color="secondary"
    href="https://medium.com/egodact/updates/home"
    target="_blank"
    fullWidth
  >
    <FormattedMessage id="header.options_menu.releases_overview.view_all_releases_button" />
  </StyledButton>
);

export default ViewAllReleasesButton;
