import AppMode from '@/lib/constants/AppMode.enum';

const modeUrls = {
  [AppMode.PORTFOLIO]: '/',
  [AppMode.COACH]: '/coach',
  [AppMode.EDITOR]: '/editor',
  [AppMode.ADMIN]: '/admin',
  [AppMode.AUDIT]: '/audit',
  [AppMode.PLATFORM_SETTINGS]: '/platform-settings',
} as const;

export default modeUrls;
