import Dialog from '@/components/Dialog';
import { TextField, TextFieldProps } from '@mui/material';
import device from 'current-device';
import { useContext } from 'react';
import PromptTextFieldInjectedPropsContext from './PromptTextFieldInjectedPropsContext';

export type PromptTextFieldProps = Omit<
  TextFieldProps,
  'variant' | 'maxRows' | 'fullWidth'
>;

const PromptTextField = ({
  minRows,
  autoFocus,
  ...props
}: PromptTextFieldProps) => {
  const injectedProps = useContext(PromptTextFieldInjectedPropsContext);

  return (
    <Dialog.Content style={{ marginTop: -12 }}>
      <TextField
        variant="filled"
        minRows={minRows ?? 4}
        maxRows={6}
        autoFocus={
          typeof autoFocus !== 'undefined' ? autoFocus : device.desktop()
        }
        fullWidth
        {...injectedProps}
        {...props}
      />
    </Dialog.Content>
  );
};

export default PromptTextField;
