import StyledDeleteButton from './StyledDeleteButton';
import StyledDeleteIcon from './StyledDeleteIcon';
import { ActionButtonProps } from '../ActionButton';

type DeleteButtonProps = Omit<
  ActionButtonProps,
  'icon' | 'labelId' | 'disabled'
> & {
  disabled?: boolean;
};

// TODO maybe this & child components can me merged into 1 file
const DeleteButton = ({ disabled, ...props }: DeleteButtonProps) => (
  <StyledDeleteButton
    icon={<StyledDeleteIcon disabled={disabled ?? false} />}
    labelId="portfolio.challenge.drawer.delete.button"
    disabled={disabled}
    {...props}
  />
);

export default DeleteButton;
