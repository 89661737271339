/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Dialog from '@/components/Dialog';
import GroupsManagerButton from './GroupsManagerButton';
import PromptGroupSelectWithClearButton from './PromptGroupSelectWithClearButton';
import { FormattedMessage } from 'react-intl';
import PortfolioItemWithGroupsPromptVariant from '@/pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/PortfolioItemWithGroupsPrompt/PortfolioItemWithGroupsPromptVariant.enum';
import Space from '@/components/Space';

type GroupsManagerProps = {
  variant: PortfolioItemWithGroupsPromptVariant;
  selectedGroups: number[];
  onSave: (selectedGroups: number[]) => Promise<void>;
};

const GroupsManager = ({
  variant,
  selectedGroups: defaultSelectedGroups,
  onSave,
}: GroupsManagerProps) => {
  const [selectedGroups, setSelectedGroups] = useState(defaultSelectedGroups);

  const [groupsManagerOpen, setGroupsManagerOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setSelectedGroups(defaultSelectedGroups);
  }, [JSON.stringify(defaultSelectedGroups)]);

  const saveGroupChanges = async (): Promise<void> => {
    setGroupsManagerOpen(false);
    setSaving(true);

    await onSave(selectedGroups);

    setSaving(false);
  };

  return (
    <>
      <GroupsManagerButton
        onClick={() => setGroupsManagerOpen(true)}
        disabled={saving}
      />
      <Dialog
        open={groupsManagerOpen}
        onClose={saveGroupChanges}
        onEnterClose={saveGroupChanges}
      >
        <Dialog.Title>
          <FormattedMessage
            id={`editor.model_editor.${variant}_editor.groups_manager_dialog.title`}
          />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage
              id={`editor.model_editor.${variant}_editor.groups_manager_dialog.text`}
            />
          </Dialog.ContentText>
          <Space height={16} />
          <PromptGroupSelectWithClearButton
            variant={variant}
            value={selectedGroups}
            onChange={(selectedGroups: number[]) =>
              setSelectedGroups(selectedGroups)
            }
            onClear={() => setSelectedGroups([])}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.DoneButton onClick={saveGroupChanges} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default GroupsManager;
