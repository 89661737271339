import React, { useContext, useState } from 'react';
import FormWrapper from './FormWrapper';
import InnerForm from './InnerForm';
import LeaveChallenge from './LeaveChallenge';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import ChallengeContext from '../../../../../Challenge/ChallengeView/ChallengeContext';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import useApi from '@/lib/api/useApi';
import api from '@/lib/api';
import { UserSummary } from '@/lib/types';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import HttpMethod from '@/lib/constants/HttpMethod.enum';
import NewlySharedContext from '@/components/ChallengePortfolio/NormalView/NewlySharedChallenges/NewlySharedContext';

type ErrorId = 'is_self' | 'already_added' | 'doesnt_exist';

const InviteUserForm = () => {
  const { handleError } = useApi();

  const [isNewlyShared, , setNewlySharedChallenges] =
    useContext(NewlySharedContext);
  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const [challenge, setChallenge] = useContext(ChallengeContext);
  const authenticatedUser = useAuthenticatedUser()!;

  const [email, setEmail] = useState('');
  const [sharing, setSharing] = useState(false);
  const [errorId, setErrorId] = useState<ErrorId | null>(null);

  const allChallengeUserEmails = [
    ...challenge.users.map(({ email }) => email),
    ...challenge.invitees.map(({ email }) => email),
  ];

  const inviteUser = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();

    if (email.length === 0) {
      return;
    }

    setSharing(true);
    setErrorId(null);

    if (email === authenticatedUser.email) {
      setErrorId('is_self');
      setSharing(false);
      return;
    }

    const allChallengeUserEmails = [
      ...challenge.users.map(({ email }) => email),
      ...challenge.invitees.map(({ email }) => email),
    ];

    if (allChallengeUserEmails.includes(email)) {
      setErrorId('already_added');
      setSharing(false);
      return;
    }

    try {
      const invitedUser = await api.post<UserSummary>(
        `challenge-portfolio/challenges/${challenge.id}/users/invite`,
        {
          email,
        },
      );

      setChallenge(challenge => {
        challenge.usersCount++;
        challenge.invitees.push(invitedUser);
      });

      if (isNewlyShared) {
        setNewlySharedChallenges(newlySharedChallenges => {
          newlySharedChallenges[challenge.id].invitees.push(invitedUser);
        });
      } else {
        setChallengePortfolio(challengePortfolio => {
          challengePortfolio.challenges[challenge.id].invitees.push(
            invitedUser,
          );
        });
      }

      setEmail('');
      setSharing(false);
    } catch (error) {
      // TypeScript-compatible check if `error` results from a 404 HTTP
      // response
      if (
        typeof error !== 'object' ||
        error === null ||
        !('responseContent' in error) ||
        typeof error.responseContent !== 'object' ||
        error.responseContent === null ||
        !('statusCode' in error.responseContent) ||
        typeof error.responseContent.statusCode !== 'number' ||
        error.responseContent.statusCode !== 404
      ) {
        handleError(error, HttpMethod.POST);

        return;
      }

      setErrorId('doesnt_exist');
      setSharing(false);
    }
  };

  return (
    <FormWrapper>
      <InnerForm
        label={
          <FormattedMessage id="portfolio.challenge.sharing.add_user.input_label" />
        }
        value={email}
        onChange={e => setEmail(e.target.value.trim())}
        error={
          errorId && (
            <FormattedMessageWithChallengeNaming
              id={`portfolio.challenge.sharing.add_user.errors.${errorId}`}
            />
          )
        }
        onInvite={inviteUser}
        disabled={sharing}
      />
      {allChallengeUserEmails.length > 1 && <LeaveChallenge />}
      {!!errorId && <Space height={14} />}
    </FormWrapper>
  );
};

export default InviteUserForm;
