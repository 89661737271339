import { useContext } from 'react';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import useGlobalStaticApiResponse from '@/lib/api/useGlobalStaticApiResponse';
import { ChallengeModels } from '@/lib/types';

const useModels = (): [true, null] | [false, ChallengeModels] => {
  const portfolioMode = useContext(PortfolioModeContext);
  const apiEndpoint =
    portfolioMode === PortfolioMode.COACH
      ? 'challenge-models/coach'
      : 'challenge-models';

  const [loading, models] =
    useGlobalStaticApiResponse<ChallengeModels>(apiEndpoint);

  if (loading) {
    return [true, null];
  }

  return [loading, models];
};

export default useModels;
