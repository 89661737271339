import { Route, Routes } from 'react-router-dom';
import PortfolioPage from '@/pages/PortfolioPage';
import OpenChallengePage from '@/pages/OpenChallengePage';
import OpenTaskPage from '@/pages/OpenTaskPage';
import CoachPage from '@/pages/CoachPage';
import EditorPage from '@/pages/EditorPage';
import AdminPage from '@/pages/AdminPage';
import PlatformSettingsPage from '@/pages/PlatformSettingsPage';
import NotFoundPage from '@/pages/NotFoundPage';
import mode from '@/lib/mode';
import AppMode from '@/lib/constants/AppMode.enum';
import RequireAuthentication from '@/components/App/RequireAuthentication';
import AuditPage from './pages/AuditPage';

const AppContentRenderer = () => (
  <Routes>
    <Route
      path={mode(AppMode.PORTFOLIO).url}
      element={
        <RequireAuthentication>
          <PortfolioPage />
        </RequireAuthentication>
      }
    />
    <Route path="/challenge/:uuid" element={<OpenChallengePage />} />
    <Route path="/task/:uuid" element={<OpenTaskPage />} />
    <Route
      path={mode(AppMode.COACH).url}
      element={
        <RequireAuthentication
          restrictedToRoles={mode(AppMode.COACH).roleRestrictions!}
        >
          <CoachPage />
        </RequireAuthentication>
      }
    />
    <Route
      path={mode(AppMode.EDITOR).url}
      element={
        <RequireAuthentication
          restrictedToRoles={mode(AppMode.EDITOR).roleRestrictions!}
        >
          <EditorPage />
        </RequireAuthentication>
      }
    />
    <Route
      path={mode(AppMode.ADMIN).url}
      element={
        <RequireAuthentication
          restrictedToRoles={mode(AppMode.ADMIN).roleRestrictions!}
        >
          <AdminPage />
        </RequireAuthentication>
      }
    />
    <Route
      path={mode(AppMode.AUDIT).url}
      element={
        <RequireAuthentication
          restrictedToRoles={mode(AppMode.AUDIT).roleRestrictions!}
        >
          <AuditPage />
        </RequireAuthentication>
      }
    />
    <Route
      path={mode(AppMode.PLATFORM_SETTINGS).url}
      element={
        <RequireAuthentication
          restrictedToRoles={mode(AppMode.PLATFORM_SETTINGS).roleRestrictions!}
        >
          <PlatformSettingsPage />
        </RequireAuthentication>
      }
    />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default AppContentRenderer;
