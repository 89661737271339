import styled from '@emotion/styled';
import { Paper, PaperProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import isPropValid from '@emotion/is-prop-valid';

type ChallengeViewCardProps = PaperProps & {
  taskBoardOpen: boolean;
};

const ChallengeViewCard = styled(Paper, {
  shouldForwardProp: isPropValid,
})<ChallengeViewCardProps>(
  ({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 150,
    width: 'calc(100% - 150px)',
    height: '100vh',
    display: 'flex',
    zIndex: 4,
    borderRadius: 0,
    transition: theme.transitions.create('all'),
    overflow: 'hidden',
    [mediaQuery(960)]: {
      left: 80,
      width: 'calc(100% - 80px)',
    },
    [mediaQuery(768)]: {
      top: 64,
      left: 0,
      width: '100%',
      height: 'calc(100% - 64px)',
    },
  }),
  ({ theme, taskBoardOpen }) => {
    if (taskBoardOpen) {
      return {
        backgroundImage: 'none',
        backgroundColor: theme.palette.background.default,
        display: 'block',
        overflowY: 'auto',
        [mediaQuery(960)]: {
          width: 'calc(100% - 80px)',
        },
        [mediaQuery(768)]: {
          width: '100%',
        },
      };
    }
  },
  addHighContrastStyles(({ theme }) => ({
    borderLeft: `2px solid ${theme.palette.divider}`,
    [mediaQuery(768)]: {
      borderLeft: 'none',
      borderTop: `2px solid ${theme.palette.divider}`,
    },
  })),
);

export default ChallengeViewCard;
