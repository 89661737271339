import AvatarList from './AvatarList';
import sortByName from '@/lib/utils/sorting/sortByName';
import { ChallengeSummary } from '@/lib/types';

type ChallengeUserAvatarProps = {
  challenge: ChallengeSummary;
};

const ChallengeUserAvatars = ({ challenge }: ChallengeUserAvatarProps) => {
  const users = [...challenge.users, ...challenge.invitees]
    .sort(sortByName)
    .slice(0, 4);

  const authenticatedUserIsOnlyUserInChallenge = users.length === 1;
  if (authenticatedUserIsOnlyUserInChallenge) {
    return null;
  }

  return <AvatarList users={users} />;
};

export default ChallengeUserAvatars;
