import { useState } from 'react';
import { AuditEventsSearchInput } from '../types';
import AuditEventsSearchForm from './AuditEventsSearchForm';
import AuditEvents from './AuditEvents';
import PageContentGrid from '../components/PageContentGrid';
import AuditEventVolumesGraph from '../components/AuditEventVolumesGraph';
import SearchFormWrapper from '../components/SearchForm/SearchFormWrapper';

const AuditEventsWithSearchForm = () => {
  const [auditEventsSearchInput, setAuditEventsSearchInput] =
    useState<AuditEventsSearchInput>({});

  return (
    <PageContentGrid>
      <SearchFormWrapper>
        <AuditEventsSearchForm
          lastSearchInput={auditEventsSearchInput}
          onSearch={auditEventsSearchInput =>
            setAuditEventsSearchInput(auditEventsSearchInput)
          }
        />
        <AuditEventVolumesGraph />
      </SearchFormWrapper>
      <AuditEvents searchInput={auditEventsSearchInput} />
    </PageContentGrid>
  );
};

export default AuditEventsWithSearchForm;
