import { useState } from 'react';
import StyledDateField from './StyledDateField';
import moment, { Moment } from 'moment';
import useIsPortfolioReadOnly from '@/components/ChallengePortfolio/NormalView/useIsPortfolioReadOnly';
import DatePicker from './DatePicker';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type InnerDateFieldProps = {
  initialValue?: number | null;
  onSave: (timestamp: number | null) => Promise<void>;
  shouldDisableDate?: (date: Moment) => boolean;
  clearable?: boolean;
};

const InnerDateField = ({
  initialValue,
  onSave,
  shouldDisableDate,
  clearable,
}: InnerDateFieldProps) => {
  const portfolioIsReadOnly = useIsPortfolioReadOnly();
  const [date, setDate, , reenableDateSync] = useComplexSwrState(
    moment(initialValue),
    {
      stringifyChangeCheck: true,
    },
  );
  const [saving, setSaving] = useState(false);

  const handleSave = async (dateAsMoment: Moment): Promise<void> => {
    const timestamp = dateAsMoment.isValid() ? dateAsMoment.valueOf() : null;

    setSaving(true);

    await onSave(timestamp);

    setSaving(false);

    reenableDateSync();
  };

  return (
    <StyledDateField>
      <DatePicker
        value={date}
        onChange={dateAsMoment => setDate(dateAsMoment)}
        onSave={handleSave}
        shouldDisableDate={shouldDisableDate}
        clearable={clearable}
        disabled={portfolioIsReadOnly || saving}
      />
    </StyledDateField>
  );
};

export default InnerDateField;
