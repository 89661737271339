import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

type LoginErrorUnfoldingWrapperProps = {
  showError: boolean;
};

const LoginErrorUnfoldingWrapper = styled(
  'section',
)<LoginErrorUnfoldingWrapperProps>(({ theme, showError }) => ({
  padding: 36,
  transform: showError ? 'translateX(-50%)' : 'none',
  width: '200%',
  display: 'flex',
  boxSizing: 'border-box',
  transition: theme.transitions.create('all'),
  [mediaQuery(768)]: {
    padding: 24,
  },
  [mediaQuery(450)]: {
    padding: '24px 16px',
  },
}));

export default LoginErrorUnfoldingWrapper;
