import StyledPerspectiveCard from './StyledPerspectiveCard';
import PerspectiveHeader from './PerspectiveHeader';
import PerspectiveIcon from '../PerspectiveIcon';
import PerspectiveColorFill from './PerspectiveColorFill';
import PerspectiveCardContent from './PerspectiveCardContent';
import PerspectiveIconPlaceholder from './PerspectiveIconPlaseholder';
import React, { useContext } from 'react';
import OpenButton from './OpenButton';
import AttachmentControls from './AttachmentControls';
import PerspectiveAttachmentContext from '../../ChallengePortfolio/NormalView/perspectives/PerspectiveAttachmentContext';
import PerspectiveTaskAttachment from './PerspectiveTaskAttachment';
import usePerspectiveAttachmentInfo from './usePerspectiveAttachmentInfo';
import PerspectiveExplanation from '../PerspectiveExplanation';
import { PerspectiveWithSublevelsInfo } from '@/lib/types';

type PerspectiveCardProps = {
  perspective: PerspectiveWithSublevelsInfo;
  onOpen?: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  draggable?: boolean;
  ref?: React.Ref<HTMLLIElement>;
};

const PerspectiveCard = ({
  perspective,
  onOpen,
  draggable,
  ref,
}: PerspectiveCardProps) => {
  const { attachmentActive, attachedPerspectives, anySublevelAttached } =
    useContext(PerspectiveAttachmentContext);
  const attachmentInfo = usePerspectiveAttachmentInfo(perspective);

  const showAttachmentOutline =
    attachmentActive &&
    ((attachedPerspectives[perspective.id] ?? false) ||
      anySublevelAttached(
        perspective.sublevelIdsIncludingNested ??
          perspective.sublevels.map(sublevel => sublevel.id),
      ));

  return (
    <StyledPerspectiveCard
      showAttachmentOutline={showAttachmentOutline}
      perspectiveColor={perspective.color}
      draggable={draggable}
      ref={ref}
    >
      <PerspectiveCardContent>
        <PerspectiveHeader>
          <h4>{perspective.name}</h4>
          <PerspectiveTaskAttachment
            attachmentInfo={attachmentInfo}
            perspective={perspective}
          />
          <PerspectiveExplanation perspectiveOrSublevel={perspective} />
        </PerspectiveHeader>
        {perspective.icon !== null ? (
          <PerspectiveIcon src={perspective.icon} />
        ) : (
          <PerspectiveIconPlaceholder />
        )}
      </PerspectiveCardContent>
      {onOpen && (
        <OpenButton fillLevel={attachmentInfo.fillLevel} onClick={onOpen} />
      )}
      <PerspectiveColorFill
        color={perspective.color}
        fillLevel={attachmentInfo.fillLevel}
        fillFade={attachmentInfo.fillFade}
      />
      {attachmentActive && <AttachmentControls perspective={perspective} />}
    </StyledPerspectiveCard>
  );
};

export default PerspectiveCard;
