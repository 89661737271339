import { useState, useEffect } from 'react';
import useUsers from './useUsers';
import useSelectedUsers from './useSelectedUsers';
import LoadingSpinner from '@/components/LoadingSpinner';
import UserToolbar from './UserToolbar';
import UserListWrapper from './UserListWrapper';
import NoSearchResults from '../NoSearchResults';
import User from './User';
import { FormattedMessage } from 'react-intl';
import UserPagination from './UserPagination';
import Space from '@/components/Space';
import UsersContext from './UsersContext';

const USERS_PER_PAGE = 10;

type UserListProps = {
  searchQuery: string | null;
};

const UserList = ({ searchQuery }: UserListProps) => {
  const [loading, usersWithSearchMatching, users, setUsers] =
    useUsers(searchQuery);
  const { selectedUsers, setUserSelected, clearSelectedUsers } =
    useSelectedUsers();

  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const handleSelectUser = (id: number, userBecomesSelected: boolean): void => {
    setUserSelected(id, userBecomesSelected);
  };

  if (loading) {
    return <LoadingSpinner margin={24} centered />;
  }

  const pageCount = Math.ceil(usersWithSearchMatching.length / USERS_PER_PAGE);
  const safePage = Math.min(page, pageCount);

  const shownUsers = usersWithSearchMatching.slice(
    (safePage - 1) * USERS_PER_PAGE,
    safePage * USERS_PER_PAGE,
  );

  return (
    <UsersContext.Provider value={[loading, users, setUsers]}>
      <UserToolbar
        users={users}
        selectedUsers={selectedUsers}
        onSelectedUsersClear={clearSelectedUsers}
      />
      <UserListWrapper>
        {usersWithSearchMatching.length === 0 ? (
          <NoSearchResults>
            <FormattedMessage id="user_manager.user_list.no_search_results" />
          </NoSearchResults>
        ) : (
          shownUsers.map(user => (
            <User
              user={user}
              selected={!!selectedUsers[user.id]}
              onSelectUser={(checked: boolean) =>
                handleSelectUser(user.id, checked)
              }
              key={user.email}
            />
          ))
        )}
      </UserListWrapper>
      {pageCount > 1 && (
        <UserPagination
          count={pageCount}
          page={safePage}
          onChange={page => setPage(page)}
        />
      )}
      <Space height={36} />
    </UsersContext.Provider>
  );
};

export default UserList;
