import { useState, useRef } from 'react';
import { ClickAwayListener } from '@mui/material';
import UserSwitcherPositioningCorrection from './UserSwitcherPositioningCorrection';
import UserSwitcherWrapper from './UserSwitcherWrapper';
import UserSearchInputWrapper from './UserSearchInputWrapper';
import GroupSelector from './GroupSelector';
import UserSearchInput from './UserSearchInput';
import UserResultList from './UserResultList';
import { User } from '@/lib/types';

type UserSwitcherProps = {
  selectedUser: User | null;
  onSelectUser: (user: User) => void;
};

const UserSwitcher = ({ selectedUser, onSelectUser }: UserSwitcherProps) => {
  const [input, setInput] = useState<string | null>(null);
  const [isSearchingUser, setIsSearchingUser] = useState(false);
  const [keyboardSelectedUser, setKeyboardSelectedUser] = useState<
    string | null
  >(null);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const changeUser = (newUser: User): void => {
    setInput(newUser.name);
    setIsSearchingUser(false);

    if (selectedUser?.email !== newUser.email) {
      onSelectUser(newUser);
    }
  };

  const focusInput = () => {
    setInput('');
    setIsSearchingUser(true);
  };

  return (
    <ClickAwayListener
      onClickAway={() => isSearchingUser && setIsSearchingUser(false)}
    >
      <UserSwitcherPositioningCorrection>
        <UserSwitcherWrapper>
          <UserSearchInputWrapper>
            <GroupSelector
              selectedGroupId={selectedGroupId}
              onGroupChange={groupId => setSelectedGroupId(groupId)}
            />
            <UserSearchInput
              selectedUser={selectedUser}
              keyboardSelectedUser={keyboardSelectedUser}
              value={(isSearchingUser ? input : selectedUser?.name) ?? ''}
              hasFocus={isSearchingUser}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInput(e.target.value);
                setIsSearchingUser(true);
              }}
              onFocus={focusInput}
              onBlur={() => setIsSearchingUser(false)}
              ref={inputRef}
            />
          </UserSearchInputWrapper>
          <UserResultList
            selectedUser={selectedUser}
            onSelectUser={changeUser}
            onKeyboardSelectUser={setKeyboardSelectedUser}
            searchInput={input ?? ''}
            selectedGroupId={selectedGroupId}
            collapsed={!isSearchingUser}
            inputRef={inputRef}
          />
        </UserSwitcherWrapper>
      </UserSwitcherPositioningCorrection>
    </ClickAwayListener>
  );
};

export default UserSwitcher;
