import StyledListItemButton from './StyledListItemButton';
import UserAvatar from '@/components/UserAvatar';
import { ListItemButtonProps, ListItemText } from '@mui/material';
import { UserSummary } from '@/lib/types';

type UserListItemProps = Omit<ListItemButtonProps, 'children'> & {
  user: UserSummary;
};

const UserListItem = ({ user, ...props }: UserListItemProps) => (
  <StyledListItemButton {...props}>
    <UserAvatar user={user} marginRight={12} />
    <ListItemText primary={user.name} />
  </StyledListItemButton>
);

export default UserListItem;
