import { useState } from 'react';
import { AuditEvent } from '../../../types';
import { TableCell, Tooltip } from '@mui/material';
import { AddCircleOutline as MoreIcon } from '@mui/icons-material';
import { TableIconButton } from '@/pages/AuditPage/components/Events/AuditTable';
import Dialog from '@/components/Dialog';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

type DetailsTableCellProps = {
  auditEvent: AuditEvent;
  onOpen: () => void;
  onClose: () => void;
};

const StyledDialogContent = styled(Dialog.Content)(({ theme }) => ({
  color: theme.palette.text.secondary,
  p: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

const DetailsTableCell = ({
  auditEvent,
  onOpen,
  onClose,
}: DetailsTableCellProps) => {
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const openDetailsDialog = (): void => {
    setDetailsDialogOpen(true);

    onOpen();
  };

  const closeDetailsDialog = (): void => {
    setDetailsDialogOpen(false);

    onClose();
  };

  return (
    <>
      <TableCell>
        <Tooltip
          title={
            <FormattedMessage id="audit.user_activity.events.table.cells.details.view_details_button_tooltip" />
          }
        >
          <TableIconButton onClick={openDetailsDialog}>
            <MoreIcon />
          </TableIconButton>
        </Tooltip>
      </TableCell>
      <Dialog open={detailsDialogOpen} onClose={closeDetailsDialog}>
        <Dialog.Title>
          <FormattedMessage id="audit.user_activity.events.table.cells.details.dialog.title" />
        </Dialog.Title>
        <StyledDialogContent>
          <p>
            <strong>
              <FormattedMessage id="audit.user_activity.events.table.cells.details.dialog.content.fields.model_id.label" />
              :
            </strong>{' '}
            <code>{auditEvent.modelId}</code>
          </p>
          {auditEvent.attribute && (
            <p>
              <strong>
                <FormattedMessage id="audit.user_activity.events.table.cells.details.dialog.content.fields.attribute.label" />
                :
              </strong>{' '}
              <code>{auditEvent.attribute}</code>
            </p>
          )}
          {auditEvent.oldValue && (
            <p>
              <strong>
                <FormattedMessage id="audit.user_activity.events.table.cells.details.dialog.content.fields.old_value.label" />
                :
              </strong>{' '}
              <code>{auditEvent.oldValue}</code>
            </p>
          )}
          {auditEvent.newValue && (
            <p>
              <strong>
                <FormattedMessage id="audit.user_activity.events.table.cells.details.dialog.content.fields.new_value.label" />
                :
              </strong>{' '}
              <code>{auditEvent.newValue}</code>
            </p>
          )}
          {auditEvent.additionalInfo && (
            <p>
              <strong>
                <FormattedMessage id="audit.user_activity.events.table.cells.details.dialog.content.fields.additional_info.label" />
                :
              </strong>{' '}
              <code>{JSON.stringify(auditEvent.additionalInfo, null, 4)}</code>
            </p>
          )}
          <p>
            <strong>
              <FormattedMessage id="audit.user_activity.events.table.cells.details.dialog.content.fields.origin_ip.label" />
              :
            </strong>{' '}
            <code>{auditEvent.originIp}</code>
          </p>
        </StyledDialogContent>
        <Dialog.Actions>
          <Dialog.Actions.DoneButton onClick={closeDetailsDialog} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DetailsTableCell;
