import Dialog from '@/components/Dialog';
import PerspectiveAttachment from '../../../../perspectives/PerspectiveAttachment';
import StyledDialog from './StyledDialog';
import PerspectiveAttachmentContext from '@/components/ChallengePortfolio/NormalView/perspectives/PerspectiveAttachmentContext';
import useAttachmentState from '../../../../perspectives/useAttachmentState';
import { FormattedMessage } from 'react-intl';
import PerspectivesCopyrightNotice from '@/components/perspectives/PerspectivesCopyrightNotice';
import StyledDialogActions from './StyledDialogActions';

type PerspectiveAttachmentDialogProps = {
  open: boolean;
  onClose: () => void;
  onNext: (attachedPerspectives: number[], attachedSublevels: number[]) => void;
};

// TODO reuse this logic?
function getKeysWithTrueValue(object: Record<number, boolean>): number[] {
  return Object.entries(object)
    .filter(([, value]) => !!value)
    .map(([key]) => Number(key));
}

const PerspectiveAttachmentDialog = ({
  open,
  onClose,
  onNext,
}: PerspectiveAttachmentDialogProps) => {
  const {
    attachedPerspectives,
    attachedSublevels,
    attachPerspective,
    detachPerspective,
    attachSublevel,
    detachSublevel,
    resetAttachmentState,
    anySublevelAttached,
  } = useAttachmentState();

  const handleNext = () => {
    onNext(
      getKeysWithTrueValue(attachedPerspectives),
      getKeysWithTrueValue(attachedSublevels),
    );

    resetAttachmentState();
  };

  const hasAnyAttachments =
    getKeysWithTrueValue(attachedPerspectives).length > 0 ||
    getKeysWithTrueValue(attachedSublevels).length > 0;

  return (
    <StyledDialog open={open} onClose={onClose}>
      <PerspectiveAttachmentContext.Provider
        value={{
          attachmentActive: true,
          attachedPerspectives,
          attachedSublevels,
          attachPerspective,
          detachPerspective,
          attachSublevel,
          detachSublevel,
          anySublevelAttached,
        }}
      >
        <PerspectiveAttachment />
      </PerspectiveAttachmentContext.Provider>
      <StyledDialogActions>
        <PerspectivesCopyrightNotice />
        <Dialog.Actions.CancelButton onClick={onClose} />
        <Dialog.Actions.OkButton onClick={handleNext}>
          <FormattedMessage
            id={`perspectives.attachment.${
              hasAnyAttachments ? 'next' : 'skip'
            }_button`}
          />
        </Dialog.Actions.OkButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default PerspectiveAttachmentDialog;
