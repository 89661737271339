import 'draft-js/dist/Draft.css';
import { useRef, useState, useEffect } from 'react';
import { EditorState } from 'draft-js';
import escapeHtml from '../escapeHtml';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import EditorWrapper from './EditorWrapper';
import EditorToolbar from './EditorToolbar';
import EditorContentWrapper from './EditorContentWrapper';
import InnerEditor from './InnerEditor';
import DisabledContext from './DisabledContext';
import PluginEditor from '@draft-js-plugins/editor/lib/Editor';

type TextEditorProps =
  | {
      initialValue?: string;
      onCancel?: () => void;
      onSave: (value: string | null) => void;
      disableSaveWhenEmpty?: boolean;
      disabled?: boolean;
    }
  | {
      initialValue?: string;
      onCancel?: () => void;
      onSave: (value: string) => void;
      disableSaveWhenEmpty: true;
      disabled?: boolean;
    };

const TextEditor = ({
  initialValue,
  onCancel,
  onSave,
  disableSaveWhenEmpty,
  disabled,
}: TextEditorProps) => {
  const editorRef = useRef<PluginEditor>(null);

  const initialEditorState = !initialValue
    ? EditorState.createEmpty()
    : EditorState.createWithContent(
        stateFromHTML(escapeHtml(initialValue ?? '')),
      );

  const [editorState, setEditorState] = useState(initialEditorState);

  const editorContent = editorState.getCurrentContent();
  const editorIsEmpty = !editorContent.hasText();

  const focusOnEditor = (): void => {
    // Without this timeout, the link detection plugin breaks
    setTimeout(() => {
      if (editorRef.current !== null) {
        editorRef.current.focus();
      }
    }, 100);
  };

  useEffect(focusOnEditor, []);

  const handleSave = (): void => {
    if (disableSaveWhenEmpty && editorIsEmpty) {
      return;
    }

    if (editorIsEmpty) {
      onSave(null as unknown as string);

      return;
    }

    onSave(stateToHTML(editorContent));
  };

  return (
    <DisabledContext.Provider value={disabled ?? false}>
      <EditorWrapper className="text-editor">
        <EditorToolbar
          editorState={editorState}
          setEditorState={setEditorState}
          onCancel={onCancel}
          onSave={handleSave}
          focusOnEditor={focusOnEditor}
          saveDisabled={(disableSaveWhenEmpty && editorIsEmpty) ?? false}
        />
        <EditorContentWrapper>
          <InnerEditor
            ref={editorRef}
            editorState={editorState}
            onChange={editorState => setEditorState(editorState)}
            onEnterSave={handleSave}
            readOnly={disabled}
          />
        </EditorContentWrapper>
      </EditorWrapper>
    </DisabledContext.Provider>
  );
};

export default TextEditor;
