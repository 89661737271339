/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useSelectionCursor, { SelectionCursor } from './useSelectionCursor';
import useKeydownListener from '@/lib/utils/useKeydownListener';
import { User } from '@/lib/types';

const useResultListKeyboardNavigation = (
  inputRef: React.RefObject<HTMLInputElement | null>,
  selectableUsers: User[],
  onSelectUser: (user: User) => void,
  onKeyboardSelectUser: (email: string | null) => void,
): SelectionCursor => {
  const selectionCursor = useSelectionCursor(selectableUsers);
  const keyboardSelectedUser =
    selectionCursor.index === null
      ? null
      : (selectableUsers[selectionCursor.index] ?? null);

  useKeydownListener(inputRef.current, event => {
    if (event.key === 'ArrowUp') {
      selectionCursor.moveUp();
    }

    if (event.key === 'ArrowDown') {
      selectionCursor.moveDown();
    }

    if (event.key === 'Enter' && keyboardSelectedUser) {
      onSelectUser(keyboardSelectedUser);
    }
  });

  const inputHasFocus = inputRef.current === document.activeElement;

  useEffect(() => {
    if (!inputHasFocus) {
      selectionCursor.clear();
    }
  }, [inputHasFocus]);

  useEffect(() => {
    onKeyboardSelectUser(keyboardSelectedUser?.email ?? null);
  }, [selectionCursor.index]);

  return selectionCursor;
};

export default useResultListKeyboardNavigation;
