import DangerColorThemeProvider from '@/components/DangerColorThemeProvider';
import { IconButton, IconButtonProps } from '@mui/material';

const DangerousIconButton = (props: IconButtonProps) => (
  <DangerColorThemeProvider>
    <IconButton color="primary" {...props} />
  </DangerColorThemeProvider>
);

export default DangerousIconButton;
