import { Drawer, DrawerProps } from '@mui/material';
import styled from '@emotion/styled';

type MobileActionsDrawerProps = Omit<
  DrawerProps,
  'anchor' | 'open' | 'onClose'
> & {
  open: boolean;
  onClose: () => void;
};

const StyledDrawer = styled(Drawer)({
  zIndex: 1300,
});

const MobileActionsDrawer = ({
  open,
  onClose,
  ...props
}: MobileActionsDrawerProps) => (
  <StyledDrawer
    anchor="right"
    open={open}
    onClose={() => onClose()}
    {...props}
  />
);

export default MobileActionsDrawer;
