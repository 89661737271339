import shuffle from 'shuffle-array';
import randomLocalStorageJwts from './randomLocalStorageJwts';

const ACCESS_TOKEN_LOCAL_STORAGE_KEY =
  'pYfkT6ZWcUB98Sla76HppFpk4aYdTAp0_CACHE_0';
const REFRESH_TOKEN_LOCAL_STORAGE_KEY =
  'sLiBOYYry1h0ZURvT5WEhiOReVsWKK78_CACHE_1';

type TokenObject = {
  readonly accessToken: string | null;
  readonly refreshToken: string | null;
};

export default class ApiAuthLocalStorage {
  static getTokens(): TokenObject {
    return {
      accessToken: localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY),
      refreshToken: localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY),
    } as const;
  }

  static saveTokens(accessToken: string, refreshToken: string): void {
    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, refreshToken);
  }

  static clearTokens(): void {
    localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
  }

  static refreshAccessToken(refreshedAccessToken: string): void {
    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, refreshedAccessToken);
  }

  // Obscurity is not security, but polluting the localStorage makes the actual
  // access token and refresh token slightly harder to find
  static async polluteLocalStorage(): Promise<void> {
    const shuffledRandomLocalStorageKeys = shuffle(
      Object.keys(
        randomLocalStorageJwts,
      ) as unknown as (keyof typeof randomLocalStorageJwts)[],
    );

    const length = shuffledRandomLocalStorageKeys.length;
    const randomLength = length / 2 + Math.ceil((Math.random() * length) / 2);

    const selectedKeys = shuffledRandomLocalStorageKeys.slice(0, randomLength);
    const nonSelectedKeys = shuffledRandomLocalStorageKeys.slice(
      randomLength - 1,
      length - 1,
    );

    for (const key of selectedKeys) {
      localStorage.setItem(key, randomLocalStorageJwts[key]);
    }

    for (const key of nonSelectedKeys) {
      localStorage.removeItem(key);
    }
  }
}
