import { motion } from 'framer-motion';
import { IconButton, IconButtonProps } from '@mui/material';
import { OmitFramerProps } from '@/lib/types';

type HeaderActionIconButtonProps = OmitFramerProps<IconButtonProps>;

const MotionIconButton = motion.create(IconButton);

const HeaderActionIconButton = (props: HeaderActionIconButtonProps) => (
  <MotionIconButton
    initial={{
      scale: 0,
    }}
    animate={{
      scale: 1,
    }}
    size="large"
    {...props}
  />
);

export default HeaderActionIconButton;
