import Dialog from '@/components/Dialog';
import { Button, ButtonProps } from '@mui/material';
import DangerColorThemeProvider from '@/components/DangerColorThemeProvider';

type DangerousButtonProps = ButtonProps & {
  inDialog?: boolean;
};

const DangerousButton = ({ inDialog, ...props }: DangerousButtonProps) => {
  const ButtonComponent = inDialog ? Dialog.Actions.Button : Button;

  return (
    <DangerColorThemeProvider>
      <ButtonComponent color="primary" {...props} />
    </DangerColorThemeProvider>
  );
};

export default DangerousButton;
