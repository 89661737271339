/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { AuditEvent, SecurityAuditEvent } from './types';
import useComplexState from '@/lib/utils/useComplexState';
import useApi from '@/lib/api/useApi';

const AUDIT_EVENTS_PAGE_SIZE = 25;

const useAuditEventsState = <
  AuditEventType extends AuditEvent | SecurityAuditEvent,
>(
  url: string,
  searchInput: Record<string, unknown>,
): [boolean, AuditEventType[], boolean, () => Promise<void>] => {
  const api = useApi();

  const [loading, setLoading] = useState(true);
  const [auditEvents, setAuditEvents] = useComplexState<AuditEventType[]>([]);
  const [canLoadMore, setCanLoadMore] = useState(true);

  useEffect(() => {
    setLoading(true);
    setAuditEvents([]);
    setCanLoadMore(true);

    api.post<AuditEventType[]>(url, searchInput).then(auditEvents => {
      setAuditEvents(auditEvents);
      setLoading(false);

      if (auditEvents.length < AUDIT_EVENTS_PAGE_SIZE) {
        setCanLoadMore(false);
      }
    });
  }, [searchInput]);

  const loadMoreAuditEvents = async (): Promise<void> => {
    setLoading(true);

    const cursor = auditEvents[auditEvents.length - 1].id;

    const additionalAuditEvents = await api.post<AuditEventType[]>(url, {
      ...searchInput,
      cursor,
    });

    setAuditEvents(auditEvents => {
      (auditEvents as AuditEventType[]).push(...additionalAuditEvents);
    });

    if (additionalAuditEvents.length < AUDIT_EVENTS_PAGE_SIZE) {
      setCanLoadMore(false);
    }

    setLoading(false);
  };

  return [loading, auditEvents, canLoadMore, loadMoreAuditEvents];
};

export default useAuditEventsState;
