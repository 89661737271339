import React, { useContext, useState } from 'react';
import RowDeletionContext from './RowDeletionContext';
import RowOverlayWrapper from './RowOverlayWrapper';
import RowDeletionCountdownOverlay from './RowDeletionCountdownOverlay';
import ChallengePortfolioContext from '../../../../ChallengePortfolioContext';
import useApi from '@/lib/api/useApi';

type RowDeletionProviderProps = {
  rowId: number;
  onDeletionStart: () => void;
  children: React.ReactNode;
};

const RowDeletionProvider = ({
  rowId,
  onDeletionStart,
  children,
}: RowDeletionProviderProps) => {
  const api = useApi();

  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const [rowDeleting, setRowDeleting] = useState(false);

  const deleteRow = async (): Promise<void> => {
    await api.delete(`challenge-portfolio/rows/${rowId}`);

    setChallengePortfolio(challengePortfolio => {
      delete challengePortfolio.rows[rowId];
    });
  };

  return (
    <RowDeletionContext.Provider
      value={{
        deleteRow,
        rowDeleting,
        startRowDeletion: () => {
          onDeletionStart();
          setRowDeleting(true);
        },
        cancelRowDeletion: () => setRowDeleting(false),
      }}
    >
      <RowOverlayWrapper>
        {children}
        {rowDeleting && <RowDeletionCountdownOverlay />}
      </RowOverlayWrapper>
    </RowDeletionContext.Provider>
  );
};

export default RowDeletionProvider;
