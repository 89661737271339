import styled from '@emotion/styled';
import computeLevelTitleColor from './computeLevelTitleColor';

const LevelTitle = styled('h4')(({ theme }) => ({
  marginBottom: 8,
  width: '100%',
  color: computeLevelTitleColor(theme),
}));

export default LevelTitle;
