import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import { ListItemIcon } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ActionMenuButtonProps } from '@/components/CollapsibleActionMenu/ActionMenuButton';

type EditRowNameButtonProps = Omit<ActionMenuButtonProps, 'children' | 'ref'>;

const EditRowNameButton = (props: EditRowNameButtonProps) => (
  <CollapsibleActionMenu.Button {...props}>
    <ListItemIcon>
      <EditIcon />
    </ListItemIcon>
    <FormattedMessage id="portfolio.row.mobile_row_actions.edit_button" />
  </CollapsibleActionMenu.Button>
);

export default EditRowNameButton;
