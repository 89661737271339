import { useState } from 'react';
import useUploadedLogoState from './useUploadedLogoState';
import SettingsBox from '../SettingsBox';
import { Settings as SettingsIcon } from '@mui/icons-material';
import LogoUploadSection from './LogoUploadSection';
import ContentSettings from './ContentSettings';
import SaveButton from './SaveButton';
import { FormattedMessage } from 'react-intl';
import HttpMethod from '@/lib/constants/HttpMethod.enum';
import useApi from '@/lib/api/useApi';

// TODO logo swr sync (relies on selectedschool bg sync)
const GeneralSettings = () => {
  const api = useApi();

  const [logo, logoAsDataUrl, setLogo, logoHasChanged] = useUploadedLogoState();
  const [saving, setSaving] = useState(false);

  const saveChanges = async (): Promise<void> => {
    if (!logoHasChanged) {
      return;
    }

    setSaving(true);

    const formData = new FormData();

    formData.append('logo', logo);

    await api.call('logos', {
      method: HttpMethod.POST,
      body: formData,
    });

    setSaving(false);

    window.location.reload();
  };

  return (
    <SettingsBox
      title={<FormattedMessage id="platform_settings.general_settings.title" />}
      icon={<SettingsIcon />}
    >
      <LogoUploadSection
        logoAsDataUrl={logoAsDataUrl}
        onSetLogoFiles={newLogoFiles => setLogo(newLogoFiles)}
      />
      <ContentSettings />
      <SaveButton onClick={saveChanges} disabled={!logoHasChanged || saving} />
    </SettingsBox>
  );
};

export default GeneralSettings;
