import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import { ListItemIcon } from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ActionMenuButtonProps } from '@/components/CollapsibleActionMenu/ActionMenuButton';

type RowCollapseButtonProps = Omit<
  ActionMenuButtonProps,
  'children' | 'ref'
> & {
  rowCollapsed: boolean;
};

const RowCollapseButton = ({
  rowCollapsed,
  ...props
}: RowCollapseButtonProps) => (
  <CollapsibleActionMenu.Button {...props}>
    <ListItemIcon>
      {rowCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
    </ListItemIcon>
    <FormattedMessage
      id={`portfolio.row.mobile_row_actions.collapse_toggle.${
        rowCollapsed ? 'uncollapse' : 'collapse'
      }`}
    />
  </CollapsibleActionMenu.Button>
);

export default RowCollapseButton;
