import React, { useState } from 'react';
import App from '@/lib/App';
import getProgressFromMousePosition from './getProgressFromMousePosition';
import { MouseMoveEvent } from '../SliderRail';

type SliderState = {
  isSliding: boolean;
  progress: number | null;
  handleMouseMove: (event: MouseMoveEvent) => void;
  handleMouseMoveActivate: (event: MouseMoveEvent) => void;
  handleMouseMoveDeactivate: (event: MouseMoveEvent) => void;
};

const useSliderState = (
  readOnly: boolean,
  sliderRef: React.RefObject<HTMLElement | null>,
): SliderState => {
  const reversed = App.instanceConfig.useSetting('useReversedSliders');

  const [isSliding, setSliding] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);

  const recomputeProgress = (event: MouseMoveEvent): void => {
    if (readOnly) {
      return;
    }

    const newProgress = getProgressFromMousePosition(
      event,
      reversed,
      sliderRef,
    );

    if (newProgress !== null) {
      setProgress(newProgress);
    }
  };

  const handleMouseMoveActivate = (event: MouseMoveEvent): void => {
    const isLeftButton = !('button' in event) || event.button === 0;

    if (isLeftButton && !isSliding) {
      setSliding(true);
    }
  };

  const handleMouseMoveDeactivate = (event: MouseMoveEvent): void => {
    if (isSliding) {
      setSliding(false);
      recomputeProgress(event);
    }
  };

  const handleMouseMove = (event: MouseMoveEvent): void => {
    if (isSliding) {
      recomputeProgress(event);
    }
  };

  return {
    isSliding,
    progress,
    handleMouseMove,
    handleMouseMoveActivate,
    handleMouseMoveDeactivate,
  };
};

export default useSliderState;
