import { useContext, useState } from 'react';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import { FormattedMessage } from 'react-intl';
import PhaseContext from '../../../PhaseContext';
import ChallengeContext from '../../../Challenge/ChallengeView/ChallengeContext';
import useApi from '@/lib/api/useApi';
import CreateButton from '../../../ChallengeBoard/KanbanPhaseColumn/CreateChallenge/CreateItem/CreateButton';
import Prompt from '@/components/Prompt';
import Dialog from '@/components/Dialog';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import usePerspectivesEnabled from '@/lib/utils/usePerspectivesEnabled';
import PerspectiveAttachmentDialog from './PerspectiveAttachmentDialog';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import {
  AttachmentResult,
  TaskSummary,
  TaskWithoutRelations,
} from '@/lib/types';
import NewlySharedContext from '../../../NewlySharedChallenges/NewlySharedContext';

enum CreateTaskStage {
  PERSPECTIVE_ATTACHMENT = 'PERSPECTIVE_ATTACHMENT',
  NAME_PROMPT = 'NAME_PROMPT',
}

// TODO refactor, too large
const CreateTask = () => {
  const api = useApi();

  const [stage, setStage] = useState<CreateTaskStage | null>(null);

  const [isNewlyShared, , setNewlySharedChallenges] =
    useContext(NewlySharedContext);
  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const [challenge, setChallenge] = useContext(ChallengeContext);
  const phase = useContext(PhaseContext);

  const perspectivesEnabled = usePerspectivesEnabled();

  const handleOpen = () => {
    setStage(
      perspectivesEnabled
        ? CreateTaskStage.PERSPECTIVE_ATTACHMENT
        : CreateTaskStage.NAME_PROMPT,
    );
  };

  const handleClose = () => {
    setStage(null);
  };

  const [attachedPerspectives, setAttachedPerspectives] = useState<number[]>(
    [],
  );
  const [attachedPerspectiveSublevels, setAttachedPerspectiveSublevels] =
    useState<number[]>([]);

  const handleNext = (
    attachedPerspectives: number[],
    attachedPerspectiveSublevels: number[],
  ): void => {
    setAttachedPerspectives(attachedPerspectives);
    setAttachedPerspectiveSublevels(attachedPerspectiveSublevels);

    setStage(CreateTaskStage.NAME_PROMPT);
  };

  const createTask = async (name: string): Promise<void> => {
    const task = await api.post<TaskWithoutRelations & AttachmentResult>(
      `challenge-portfolio/tasks?challengeId=${challenge.id}`,
      {
        name,
        phase,
        attachedPerspectives,
        attachedPerspectiveSublevels,
      },
    );

    const taskSummary = {
      ...task,
      assignees: [],
      _count: {
        coachComments: 0,
      },
    };

    setChallenge(challenge => {
      challenge.tasks[task.id] = taskSummary;
    });

    if (isNewlyShared) {
      setNewlySharedChallenges(newlySharedChallenges => {
        (newlySharedChallenges[challenge.id].tasks as TaskSummary[]).push(
          taskSummary,
        );
      });

      return;
    }

    setChallengePortfolio(challengePortfolio => {
      // TODO it should be either an array or object, typing should be reworked
      (challengePortfolio.challenges[challenge.id].tasks as TaskSummary[]).push(
        taskSummary,
      );
    });
  };

  return (
    <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
      <CreateButton
        tooltip={<FormattedMessage id="portfolio.create_task.tooltip" />}
        onClick={handleOpen}
        isCreateTaskButton
      />
      <PerspectiveAttachmentDialog
        open={stage === CreateTaskStage.PERSPECTIVE_ATTACHMENT}
        onClose={handleClose}
        onNext={handleNext}
      />
      <Prompt
        open={stage === CreateTaskStage.NAME_PROMPT}
        onOk={name => {
          handleClose();

          createTask(name);
        }}
        OkButton={Dialog.Actions.CreateButton}
        onCancel={handleClose}
        emptyError="prompt.errors.name_empty"
      >
        <Dialog.Title>
          <FormattedMessageWithChallengeNaming id="portfolio.create_task.title" />
        </Dialog.Title>
        <Prompt.TextField
          label={<FormattedMessage id="portfolio.create_task.input_label" />}
        />
      </Prompt>
    </RestrictedToPortfolioMode>
  );
};

export default CreateTask;
