import { useContext } from 'react';
import PerspectiveAttachmentContext from '../../../ChallengePortfolio/NormalView/perspectives/PerspectiveAttachmentContext';
import DetachButton from './DetachButton';
import AttachButton from './AttachButton';
import { Perspective } from '@/lib/types';

type AttachmentControlsProps = {
  perspective: Perspective;
};

const AttachmentControls = ({ perspective }: AttachmentControlsProps) => {
  const { attachedPerspectives, attachPerspective, detachPerspective } =
    useContext(PerspectiveAttachmentContext);

  const isAttached = attachedPerspectives[perspective.id] ?? false;

  if (isAttached) {
    return <DetachButton onClick={() => detachPerspective(perspective.id)} />;
  }

  return <AttachButton onClick={() => attachPerspective(perspective.id)} />;
};

export default AttachmentControls;
