import styled from '@emotion/styled';
import { Button, ButtonProps } from '@mui/material';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';

type StyledButtonProps = Omit<ButtonProps, 'fullWidth'> & {
  fullWidth: boolean;
};

const StyledButton = styled(Button)<StyledButtonProps>(
  ({ theme, fullWidth }) => ({
    minWidth: fullWidth ? undefined : 240,
    backgroundColor: theme.palette.action.hover,
    fontSize: 16,
    textTransform: 'none',
    ':hover': {
      backgroundColor: theme.palette.action.selected,
    },
  }),
  addBorderInHighContrastMode,
) as unknown as typeof Button;

export default StyledButton;
