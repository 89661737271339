import UploadDropzone from './UploadDropzone';
import PreviewBox from './PreviewBox';
import PreviewOverlay from './PreviewOverlay';
import UploadLabel from './UploadLabel';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import React from 'react';

type UploadBoxProps = {
  preview: string;
  onDrop: Pick<DropzoneOptions, 'onDrop'>['onDrop'];
  children: React.ReactNode;
};

const UploadBox = ({ preview, onDrop, children, ...props }: UploadBoxProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.svg', '.jpg', '.jpeg', '.png'],
    },
    // TODO max filesize handling in UI (error state)
    maxSize: 5_000_000,
    onDrop,
  });

  return (
    <UploadDropzone {...getRootProps(props)}>
      <input {...getInputProps()} />
      <PreviewBox className="upload-preview-box" preview={preview}>
        <PreviewOverlay />
        <UploadLabel>{children}</UploadLabel>
      </PreviewBox>
    </UploadDropzone>
  );
};

export default UploadBox;
