import React, { useState, useRef } from 'react';
import Dialog, { DialogProps } from '@/components/Dialog';
import PromptTextField from './PromptTextField';
import { FormattedMessage } from 'react-intl';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';
import PromptTextFieldInjectedPropsContext from './PromptTextFieldInjectedPropsContext';

export type PromptProps = Omit<DialogProps, 'children'> & {
  defaultValue?: string;
  emptyError?: string | null;
  showError?: (input: string) => React.ReactNode;
  onOk: (input: string) => void;
  onCancel: () => void;
  OkButton?: React.ElementType;
  multiline?: boolean;
  persistInput?: boolean;
  allowEmptyInput?: boolean;
  children?: React.ReactNode;
};

// TODO should be reworked entirely
const Prompt = ({
  defaultValue,
  emptyError,
  showError: showErrorFn,
  onOk,
  onCancel,
  OkButton: OkButtonOrUndefined,
  multiline,
  persistInput,
  allowEmptyInput,
  children,
  ...props
}: PromptProps) => {
  const [input, setInput, , reenableInputSync] = useComplexSwrState(
    defaultValue ?? '',
  );
  const [error, setError] = useState<React.ReactNode | null>(null);

  const handleCancel = (): void => {
    setInput(defaultValue ?? '');
    setError(null);

    onCancel();

    reenableInputSync();
  };

  const showError = (input: string): React.ReactNode => {
    if (emptyError && !input.trim()) {
      return <FormattedMessage id={emptyError} />;
    }

    const externalError = showErrorFn && showErrorFn(input);
    if (externalError) {
      return externalError;
    }

    return null;
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOk = (): void => {
    const error = showError(input);

    setError(error);

    if (error) {
      inputRef.current?.focus();

      return;
    }

    if (!allowEmptyInput && !input.trim()) {
      handleCancel();

      return;
    }

    onOk(input.trim());

    if (!persistInput) {
      setInput('');
    }

    reenableInputSync();
  };

  const OkButton = OkButtonOrUndefined ?? Dialog.Actions.SaveButton;

  return (
    <Dialog
      onClose={handleCancel}
      onEnterClose={handleOk}
      disableEnterClose={multiline}
      {...props}
    >
      <PromptTextFieldInjectedPropsContext.Provider
        value={{
          value: input,
          onChange: e => setInput(e.target.value),
          helperText: error,
          error: !!error,
          inputRef,
          multiline: !!multiline,
        }}
      >
        {children}
      </PromptTextFieldInjectedPropsContext.Provider>
      <Dialog.Actions addTopMargin {...props}>
        <Dialog.Actions.CancelButton onClick={handleCancel} />
        <OkButton onClick={handleOk} />
      </Dialog.Actions>
    </Dialog>
  );
};

Prompt.TextField = PromptTextField;

export default Prompt;
