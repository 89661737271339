import ownYourDevelopmentAndGrowthIcon from './subtopic-icons/own-your-development-and-growth.svg';
import discoverAndOrientateIcon from './subtopic-icons/discover-and-orientate.svg';
import connectIcon from './subtopic-icons/connect.svg';
import communicateIcon from './subtopic-icons/communicate.svg';
import reflectIcon from './subtopic-icons/reflect.svg';
import researchIcon from './subtopic-icons/research.svg';
import shareIcon from './subtopic-icons/share.svg';

const subtopicIcons = {
  'own your development/growth': ownYourDevelopmentAndGrowthIcon,
  'discover & orientate': discoverAndOrientateIcon,
  connect: connectIcon,
  communicate: communicateIcon,
  reflect: reflectIcon,
  research: researchIcon,
  share: shareIcon,
} as Record<string, string>;

const getSubtopicIconFromTitle = (title: string): string | null => {
  const subtopicIcon = subtopicIcons[title.trim().toLowerCase()];

  return subtopicIcon ?? null;
};

export default getSubtopicIconFromTitle;
