/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import useUrlQueryParam from '@/lib/utils/useUrlQueryParam';
import SelectedSchoolLocalStorage from '@/components/App/AppSetup/SelectedSchool/SelectedSchoolLocalStorage';
import { SchoolSummary } from '@/lib/types';

const usePreselectedSchool = (
  schools: SchoolSummary[] | null,
): SchoolSummary | null => {
  const queryParamPreselectedSchool = useUrlQueryParam('school');

  const getPreselectedSchool = (): SchoolSummary | null => {
    if (schools === null) {
      return null;
    }

    const localStorageSelectedSchool =
      SelectedSchoolLocalStorage.getSelectedSchool();

    if (
      queryParamPreselectedSchool === null &&
      localStorageSelectedSchool === null
    ) {
      return null;
    }

    const schoolsKeyedByReference = schools.reduce(
      (schools, school) => {
        schools[school.reference] = school;

        return schools;
      },
      {} as Record<string, SchoolSummary>,
    );
    const schoolReferences = Object.keys(schoolsKeyedByReference);

    if (schoolReferences.includes(queryParamPreselectedSchool!)) {
      return schoolsKeyedByReference[queryParamPreselectedSchool!];
    }

    if (schoolReferences.includes(localStorageSelectedSchool!)) {
      return schoolsKeyedByReference[localStorageSelectedSchool!];
    }

    return null;
  };

  const preselectedSchool = useMemo(getPreselectedSchool, [schools]);

  return preselectedSchool;
};

export default usePreselectedSchool;
