import { useState, useContext } from 'react';
import StyledAddButton from './StyledAddButton';
import { AssignmentInd as AssigneeIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import { List } from '@mui/material';
import UserListItem from './UserListItem';
import { FormattedMessage } from 'react-intl';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import sortByName from '@/lib/utils/sorting/sortByName';
import useAddAssignee from '../useAddAssignee';
import useRemoveAssignee from '../useRemoveAssignee';
import TaskContext from '@/components/ChallengePortfolio/NormalView/Task/TaskView/TaskContext';
import useIsPortfolioReadOnly from '@/components/ChallengePortfolio/NormalView/useIsPortfolioReadOnly';
import IsInTaskAttachmentViewContext from '@/components/perspectives/IsInTaskAttachmentViewContext';
import { UserSummary } from '@/lib/types';

const AddAssignees = () => {
  const portfolioIsReadOnly = useIsPortfolioReadOnly();
  const isInTaskAttachmentView = useContext(IsInTaskAttachmentViewContext);

  const [challenge] = useContext(ChallengeContext);
  const [task] = useContext(TaskContext);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const addAssignee = useAddAssignee();
  const removeAssignee = useRemoveAssignee();

  const challengeUsers = [...challenge.users, ...challenge.invitees];

  const assigneeEmails = task.assignees.map(assignee => assignee.email);

  const toggleAssignee = async (user: UserSummary): Promise<void> => {
    setSaving(true);

    if (assigneeEmails.includes(user.email)) {
      await removeAssignee(user);
    } else {
      await addAssignee(user);
    }

    setSaving(false);
  };

  return (
    <>
      <StyledAddButton
        onClick={() => setDialogOpen(true)}
        startIcon={<AssigneeIcon />}
        disabled={portfolioIsReadOnly || isInTaskAttachmentView}
      >
        <FormattedMessage id="portfolio.task.assignment.add_assignees.button" />
      </StyledAddButton>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onEnterClose={() => setDialogOpen(false)}
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.task.assignment.add_assignees.title" />
        </Dialog.Title>
        <List>
          {challengeUsers.sort(sortByName).map(user => (
            <UserListItem
              user={user}
              selected={assigneeEmails.includes(user.email)}
              onClick={() => toggleAssignee(user)}
              disabled={saving}
              key={user.email}
            />
          ))}
        </List>
        <Dialog.Actions>
          <Dialog.Actions.DoneButton onClick={() => setDialogOpen(false)} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default AddAssignees;
