import React, { useContext, useState } from 'react';
import ChallengeView from './ChallengeView';
import ChallengeCard from './ChallengeCard';
import useChallengeDrag from './useChallengeDrag';
import NewlySharedContext from '../NewlySharedChallenges/NewlySharedContext';
import { ChallengeSummary } from '@/lib/types';

type ChallengeProps = {
  challenge: ChallengeSummary;
  collapsed?: boolean;
};

const Challenge = ({ challenge, collapsed }: ChallengeProps) => {
  const [isNewlyShared] = useContext(NewlySharedContext);

  const [isDragging, attachDragHandlingRef] = useChallengeDrag(challenge);
  const [open, setOpen] = useState(false);

  return (
    <>
      <ChallengeCard
        challenge={challenge}
        collapsed={collapsed ?? false}
        withPhaseText={isNewlyShared}
        dragging={isDragging}
        onClick={() => setOpen(true)}
        ref={attachDragHandlingRef as React.Ref<HTMLButtonElement>}
      />
      {open && (
        <ChallengeView challenge={challenge} onClose={() => setOpen(false)} />
      )}
    </>
  );
};

export default Challenge;
