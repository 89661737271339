import StudentProgressBar from './StudentProgressBar';
import CoachProgressBar from './CoachProgressBar';

type ProgressBarsProps = {
  studentProgress: number;
  coachProgress: number;
  isSliding: boolean;
};

const ProgressBars = ({
  studentProgress,
  coachProgress,
  isSliding,
}: ProgressBarsProps) => (
  <>
    <StudentProgressBar
      progress={studentProgress}
      coachProgress={coachProgress}
      isSliding={isSliding}
    />
    <CoachProgressBar
      progress={coachProgress}
      studentProgress={studentProgress}
      isSliding={isSliding}
    />
  </>
);

export default ProgressBars;
