import useChallengeSearchQueryMatching from './useChallengeSearchQueryMatching';
import StyledChallengeCard, {
  StyledChallengeCardProps,
} from './StyledChallengeCard';

type ChallengeCardWithSearchMatchingProps = Omit<
  StyledChallengeCardProps,
  'highlighted' | 'faded'
> & {
  challengeName: string;
};

const ChallengeCardWithSearchMatching = ({
  challengeName,
  ...props
}: ChallengeCardWithSearchMatchingProps) => {
  const [faded, highlighted] = useChallengeSearchQueryMatching(challengeName);

  return (
    <StyledChallengeCard highlighted={highlighted} faded={faded} {...props} />
  );
};

export default ChallengeCardWithSearchMatching;
