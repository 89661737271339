import { useContext, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import api, { TokenResponse } from '@/lib/api';
import HttpMethod from '@/lib/constants/HttpMethod.enum';
import AuthenticationStateContext from '@/components/App/AppSetup/AuthenticationState/AuthenticationStateContext';

const useLoginWithGoogle = (
  onShowError: (error: unknown) => void,
): [() => void, boolean] => {
  const [, refreshAuthenticationState] = useContext(AuthenticationStateContext);
  const [loading, setLoading] = useState(false);

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async codeResponse => {
      try {
        setLoading(true);

        const response =
          await api.callWithoutAuthorizationHandling<TokenResponse>(
            'auth/login/google',
            {
              method: HttpMethod.POST,
              body: JSON.stringify({
                code: codeResponse.code,
                origin: window.location.origin,
              }),
            },
          );

        api.authenticate(response.accessToken, response.refreshToken);

        refreshAuthenticationState();
      } catch (error) {
        onShowError(error);
      } finally {
        setLoading(false);
      }
    },
    flow: 'auth-code',
  });

  return [loginWithGoogle, loading];
};

export default useLoginWithGoogle;
