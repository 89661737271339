import { useState } from 'react';
import OptionWrapper from './OptionWrapper';
import OptionNameEditor from './OptionNameEditor';
import OptionName from './OptionName';
import EditButton from './EditButton';
import DeleteOption from './DeleteOption';
import { Option } from './types';

type OptionEditorProps = {
  optionKey: string;
  option: Option;
};

const OptionEditor = ({ optionKey, option }: OptionEditorProps) => {
  const [nameEditing, setNameEditing] = useState(false);

  return (
    <OptionWrapper>
      {nameEditing ? (
        <OptionNameEditor
          optionKey={optionKey}
          option={option}
          onClose={() => setNameEditing(false)}
        />
      ) : (
        <>
          <OptionName>{option.name}</OptionName>
          <EditButton onClick={() => setNameEditing(true)} />
        </>
      )}
      <DeleteOption optionKey={optionKey} />
    </OptionWrapper>
  );
};

export default OptionEditor;
