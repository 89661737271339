import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';
import styled from '@emotion/styled';
import { Button } from '@mui/material';

const StyledTasksButton = styled(Button)(
  ({ theme }) => ({
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: theme.palette.action.hover,
    height: 40,
    ':hover': {
      backgroundColor: theme.palette.action.selected,
    },
  }),
  addBorderInHighContrastMode,
);

export default StyledTasksButton;
