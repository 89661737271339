import { SearchFormFieldWrapper } from '..';
import { DatePicker, DatePickerSlotProps } from '@mui/x-date-pickers';
import { FormattedMessage } from 'react-intl';
import { isEarlier, isLater } from '@/lib/utils/dateUtils';
import { Moment } from 'moment';
import Space from '@/components/Space';

type SearchFormDateRangeProps = {
  startDate: Moment | null;
  onChangeStartDate: (date: Moment | null) => void;
  endDate: Moment | null;
  onChangeEndDate: (date: Moment | null) => void;
};

const datePickerSlotProps = {
  textField: {
    variant: 'filled',
    size: 'small',
  },
} satisfies DatePickerSlotProps<Moment, false>;

const SearchFormDateRange = ({
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
}: SearchFormDateRangeProps) => (
  <SearchFormFieldWrapper>
    <DatePicker
      label={
        <FormattedMessage id="audit.shared.search_form.date_range.start_date.field.label" />
      }
      value={startDate}
      onChange={onChangeStartDate}
      shouldDisableDate={
        endDate === null ? undefined : date => isLater(date, endDate)
      }
      slotProps={datePickerSlotProps}
    />
    <Space width={12} />
    <DatePicker
      label={
        <FormattedMessage id="audit.shared.search_form.date_range.end_date.field.label" />
      }
      value={endDate}
      onChange={onChangeEndDate}
      shouldDisableDate={
        startDate === null ? undefined : date => isEarlier(date, startDate)
      }
      slotProps={datePickerSlotProps}
    />
  </SearchFormFieldWrapper>
);

export default SearchFormDateRange;
