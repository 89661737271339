import IdentityConnectionProvider from '@/lib/constants/IdentityConnectionProvider.enum';
import { School } from '@/lib/types';
import { createContext } from 'react';

const SchoolContext = createContext<[boolean, School]>([
  true,
  {
    id: 0,
    reference: '',
    displayName: '',
    logoFilename: null,
    instanceConfig: {
      allowedEmails: [],
      authProvider: IdentityConnectionProvider.GOOGLE,
      enablePerspectiveModule: false,
      useReversedSliders: true,
      enableUserSwitcherArrows: false,
      useProfilePictures: true,
      enableAggressiveRubricCollapsing: false,
      customChallengeNaming: {
        enabled: false,
      },
    },
  },
]);

export default SchoolContext;
