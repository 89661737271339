/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { AuditEventsSearchInput } from '../../types';
import useApi from '@/lib/api/useApi';
import { FormattedMessage } from 'react-intl';
import CountTextWrapper from '../../components/Events/CountTextWrapper';
import CountTextPlaceholder from '../../components/Events/CountTextPlaceholder';

type AuditEventCountProps = {
  searchInput: AuditEventsSearchInput;
  shownAuditEventCount: number;
};

type AuditEventCounts = {
  totalCount: number;
  filteredCount: number;
};

const AuditEventCount = ({
  searchInput,
  shownAuditEventCount,
}: AuditEventCountProps) => {
  const api = useApi();

  const [counts, setCounts] = useState<AuditEventCounts | null>(null);

  useEffect(() => {
    setCounts(null);

    api
      .post<AuditEventCounts>('audit-events/count', searchInput)
      .then(auditEventCounts => {
        setCounts(auditEventCounts);
      });
  }, [searchInput]);

  if (counts === null) {
    // This placeholder prevents page jumping when the count request completes
    return <CountTextPlaceholder />;
  }

  if (counts.totalCount === counts.filteredCount) {
    return (
      <CountTextWrapper>
        <FormattedMessage
          id="audit.user_activity.events.count.event_count_unfiltered"
          values={{
            totalCount: counts.totalCount,
            shownCount: shownAuditEventCount,
          }}
        />
      </CountTextWrapper>
    );
  }

  return (
    <CountTextWrapper>
      <FormattedMessage
        id="audit.user_activity.events.count.event_count_filtered"
        values={{
          totalCount: counts.totalCount,
          filteredCount: counts.filteredCount,
          shownCount: shownAuditEventCount,
        }}
      />
    </CountTextWrapper>
  );
};

export default AuditEventCount;
