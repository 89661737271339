import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogProps as MuiDialogProps,
} from '@mui/material';
import DialogActions from './DialogActions';
import DialogWithEnterClose from './DialogWithEnterClose';
import ResponsiveDialogPaper from './ResponsiveDialogPaper';
import dummyFunction from '@/lib/utils/dummyFunction';

export type DialogProps = MuiDialogProps & {
  open: boolean;
  onEnterClose?: (event: KeyboardEvent) => void;
  disableEnterClose?: boolean;
  dangerous?: boolean;
};

const Dialog = ({ onEnterClose, disableEnterClose, ...props }: DialogProps) => (
  <DialogWithEnterClose
    PaperComponent={ResponsiveDialogPaper}
    onEnterClose={onEnterClose ?? dummyFunction}
    disableEnterClose={disableEnterClose ?? false}
    fullWidth
    {...props}
  />
);

Dialog.Title = DialogTitle;
Dialog.Content = DialogContent;
Dialog.ContentText = DialogContentText;
Dialog.Actions = DialogActions;

export default Dialog;
