/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import DialogContentWrapper from '../../DialogContentWrapper';
import Dialog from '@/components/Dialog';
import Prompt from '@/components/Prompt';
import { FormattedMessage } from 'react-intl';
import SendButton from './SendButton';
import IncludeEmailCheckbox from './IncludeEmailCheckbox';
import useApi from '@/lib/api/useApi';
import Space from '@/components/Space';

type FeedbackFormProps = {
  setDialogActions: (dialogActions: React.ReactNode) => void;
  onGoBack: () => void;
  onDialogClose: () => void;
};

const FeedbackForm = ({
  setDialogActions,
  onGoBack,
  onDialogClose,
}: FeedbackFormProps) => {
  const api = useApi();

  const [feedback, setFeedback] = useState('');
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [includeEmail, setIncludeEmail] = useState(false);

  const [saving, setSaving] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const sendFeedback = async (): Promise<void> => {
    if (feedback.trim().length === 0) {
      inputRef.current?.focus();
      setShowEmptyError(true);
      return;
    }

    setShowEmptyError(false);
    setSaving(true);

    await api.post('feedback', {
      content: feedback,
      isAnonymous: !includeEmail,
    });

    setSaving(false);

    onGoBack();
    onDialogClose();
  };

  useEffect(() => {
    setDialogActions(<SendButton onClick={sendFeedback} disabled={saving} />);

    return () => setDialogActions(null);
  }, [feedback, includeEmail, saving]);

  return (
    <DialogContentWrapper>
      <Dialog.Title>
        <FormattedMessage id="support.dialog.feedback_form.title" />
      </Dialog.Title>
      <Dialog.Content>
        <Dialog.ContentText>
          <FormattedMessage id="support.dialog.feedback_form.text" />
        </Dialog.ContentText>
      </Dialog.Content>
      <Prompt.TextField
        label={
          <FormattedMessage id="support.dialog.feedback_form.input_label" />
        }
        value={feedback}
        onChange={e => setFeedback(e.target.value)}
        error={showEmptyError}
        helperText={
          showEmptyError && (
            <FormattedMessage id="support.dialog.feedback_form.empty_error" />
          )
        }
        autoFocus={false}
        minRows={6}
        inputRef={inputRef}
        multiline
      />
      <Space height={-32} />
      <IncludeEmailCheckbox
        checked={includeEmail}
        onChange={e => setIncludeEmail(e.target.checked)}
      />
    </DialogContentWrapper>
  );
};

export default FeedbackForm;
