import styled from '@emotion/styled';
import { Alert, AlertProps } from '@mui/material';
import { motion } from 'framer-motion';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import React from 'react';
import { OmitFramerProps } from '@/lib/types';

type ErrorAlertProps = OmitFramerProps<AlertProps> & {
  onDismiss: (event: React.SyntheticEvent<Element, Event>) => void;
};

const MotionAlert = motion.create(Alert);

const StyledAlert = styled(MotionAlert)(
  ({ theme }) => ({
    width: '100%',
    border: theme.isDark ? undefined : `1px solid ${theme.palette.error.light}`,
    boxSizing: 'border-box',
    '.MuiAlert-message': {
      overflow: 'hidden',
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    border: `2px solid ${theme.palette.divider}`,
  })),
) as unknown as typeof MotionAlert;

const ErrorAlert = ({ onDismiss, ...props }: ErrorAlertProps) => (
  <StyledAlert
    initial={{
      top: 12,
      marginBottom: 0,
      opacity: 0,
    }}
    animate={{
      top: 0,
      marginBottom: 12,
      opacity: 1,
    }}
    exit={{
      height: 0,
      marginBottom: 0,
      opacity: 0,
    }}
    severity="error"
    onClose={onDismiss}
    {...props}
  />
);

export default ErrorAlert;
