import styled from '@emotion/styled';
import Dialog from '@/components/Dialog';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledDialog = styled(Dialog)(
  ({ theme }) => ({
    '.MuiDialog-paper': {
      width: 1240,
      minWidth: 1240,
      maxWidth: 1240,
      backgroundColor: theme.palette.background.shaded,
      display: 'block',
      [mediaQuery(1450)]: {
        width: 'calc(90% - 64px)',
        minWidth: 'calc(90% - 64px)',
        maxWidth: 'calc(90% - 64px)',
      },
      [mediaQuery(1100)]: {
        width: 'calc(100% - 64px)',
        minWidth: 'calc(100% - 64px)',
        maxWidth: 'calc(100% - 64px)',
      },
      [mediaQuery(420)]: {
        width: 'calc(100% - 24px)',
        minWidth: 'calc(100% - 24px)',
        maxWidth: 'calc(100% - 24px)',
      },
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    '.MuiDialog-paper': {
      border: `2px solid ${theme.palette.divider}`,
    },
  })),
);

export default StyledDialog;
