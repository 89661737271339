import {
  Button,
  ButtonProps,
  IconButtonProps,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import StyledLinkIconButton from './StyledLinkIconButton';
import { Link as LinkIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';

type LinkButtonProps = Omit<
  ButtonProps | IconButtonProps,
  'color' | 'size' | 'startIcon' | 'children'
>;

const StyledLinkButton = styled(Button)({
  minWidth: 180,
});

const LinkButton = (props: LinkButtonProps) => {
  const shouldUseIconButton = useMediaQuery('(max-width: 1250px)');

  if (shouldUseIconButton) {
    return (
      <Tooltip
        title={
          <FormattedMessage id="portfolio.challenge.link_sharing.button" />
        }
      >
        <StyledLinkIconButton color="primary" size="large" {...props}>
          <LinkIcon />
        </StyledLinkIconButton>
      </Tooltip>
    );
  }

  return (
    <StyledLinkButton
      startIcon={<LinkIcon color="primary" />}
      color="secondary"
      {...props}
    >
      <FormattedMessage id="portfolio.challenge.link_sharing.button" />
    </StyledLinkButton>
  );
};

export default LinkButton;
