import { useState } from 'react';
import { TableCell, Tooltip } from '@mui/material';
import { AuditEvent, SecurityAuditEvent } from '../../types';
import { MissingTableCellValue, TableIconButton } from '../Events/AuditTable';
import { ExpandCircleDownOutlined as UserMoreIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import { AccountCircleOutlined as UserIcon } from '@mui/icons-material';
import useApi from '@/lib/api/useApi';
import { User } from '@/lib/types';
import UserAvatar from '@/components/UserAvatar';
import LoadingSpinner from '@/components/LoadingSpinner';
import { PersonOff as UserDeletedIcon } from '@mui/icons-material';
import UserRole from '@/lib/constants/UserRole.enum';
import { FormattedMessage } from 'react-intl';
import {
  UserTableCellContent,
  UserDialogTitle,
  UserRoleIndicator,
  UserDialogContent,
  UserDeletedMessage,
} from './styled-components';

type UserInfoTableCellProps = {
  auditEvent: AuditEvent | SecurityAuditEvent;
};

type UserResponse = {
  user: User | null;
};

const UserInfoTableCell = ({ auditEvent }: UserInfoTableCellProps) => {
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [userExists, setUserExists] = useState<boolean | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const [userInfoDialogOpen, setUserInfoDialogOpen] = useState(false);

  const openUserInfoDialog = async (): Promise<void> => {
    setUserInfoDialogOpen(true);

    if (userExists !== null) {
      return;
    }

    setLoading(true);

    const { user } = await api.get<UserResponse>(`users/${auditEvent.userId}`);

    setUserExists(user !== null);
    setUser(user);

    setLoading(false);
  };

  const userLabel =
    auditEvent.userName ?? auditEvent.userEmail ?? auditEvent.userId ?? null;

  return (
    <>
      <TableCell>
        <UserTableCellContent>
          {userLabel === null ? (
            <MissingTableCellValue>
              <FormattedMessage id="audit.shared.events.table.cells.user.missing_value" />
            </MissingTableCellValue>
          ) : (
            <>
              {userLabel}
              <Tooltip
                title={
                  <FormattedMessage id="audit.shared.events.table.cells.user.user_info_button_tooltip" />
                }
              >
                <TableIconButton onClick={openUserInfoDialog}>
                  <UserMoreIcon />
                </TableIconButton>
              </Tooltip>
            </>
          )}
        </UserTableCellContent>
      </TableCell>
      {userLabel !== null && (
        <Dialog
          open={userInfoDialogOpen}
          onClose={() => setUserInfoDialogOpen(false)}
        >
          <UserDialogTitle>
            {loading && <LoadingSpinner />}
            {!loading && user !== null && <UserAvatar user={user} size={32} />}
            {!loading && user === null && <UserIcon />}
            {userLabel}
            {!loading && user !== null && user.role !== UserRole.STUDENT && (
              <UserRoleIndicator>
                <FormattedMessage
                  id={`user_roles.${user.role.toLowerCase()}`}
                />
              </UserRoleIndicator>
            )}
          </UserDialogTitle>
          <UserDialogContent>
            {(user?.name || auditEvent.userName) && (
              <p>
                <strong>
                  <FormattedMessage id="audit.shared.events.table.cells.user.dialog.content.fields.name.label" />
                  :
                </strong>{' '}
                {user?.name ?? auditEvent.userName}
              </p>
            )}
            {(user?.email || auditEvent.userEmail) && (
              <p>
                <strong>
                  <FormattedMessage id="audit.shared.events.table.cells.user.dialog.content.fields.email.label" />
                  :
                </strong>{' '}
                {user?.email ?? auditEvent.userEmail}
              </p>
            )}
            {auditEvent.userId && (
              <p>
                <strong>
                  <FormattedMessage id="audit.shared.events.table.cells.user.dialog.content.fields.id.label" />
                  :
                </strong>{' '}
                {auditEvent.userId}
              </p>
            )}
            {!loading && !userExists && (
              <UserDeletedMessage>
                <UserDeletedIcon />
                <FormattedMessage id="audit.shared.events.table.cells.user.dialog.content.user_deleted_message" />
              </UserDeletedMessage>
            )}
          </UserDialogContent>
          <Dialog.Actions>
            <Dialog.Actions.DoneButton
              onClick={() => setUserInfoDialogOpen(false)}
            />
          </Dialog.Actions>
        </Dialog>
      )}
    </>
  );
};

export default UserInfoTableCell;
