import { AuditStatsResponse } from '../../types';
import { LineChart } from '@mui/x-charts';
import moment from 'moment';
import GraphWrapper from './GraphWrapper';
import useGlobalStaticApiResponse from '@/lib/api/useGlobalStaticApiResponse';
import { FormattedMessage, useIntl } from 'react-intl';
import GraphLoadingPlaceholder from './GraphLoadingPlaceholder';
import { useTheme } from '@emotion/react';
import { blue, teal } from '@mui/material/colors';

// TODO make graphs keyboard accessible (ie show legend on focus)
const AuditEventVolumesGraph = () => {
  const [loading, auditStats] =
    useGlobalStaticApiResponse<AuditStatsResponse>('audit-stats');

  const theme = useTheme();
  const isHighContrast = theme.palette.isHighContrast;

  const intl = useIntl();

  if (loading) {
    return <GraphLoadingPlaceholder />;
  }

  const dates = Object.keys(auditStats.stats.auditEvents).map(
    date => new Date(date),
  );

  return (
    <GraphWrapper>
      <h4>
        <FormattedMessage id="audit.event_volume_graphs.title" />
      </h4>
      <LineChart
        xAxis={[
          {
            data: dates,
            scaleType: 'time',
            valueFormatter: date => moment(date).format('L'),
          },
        ]}
        yAxis={[
          {
            tickMinStep: 1,
          },
        ]}
        series={[
          {
            id: 'audit-events',
            label: intl.formatMessage({
              id: 'audit.event_volume_graphs.labels.audit_events',
            }),
            curve: 'linear',
            data: Object.values(auditStats.stats.auditEvents),
            showMark: false,
            area: !isHighContrast,
            color: isHighContrast
              ? theme.palette.divider
              : teal[theme.palette.mode === 'dark' ? 200 : 300],
          },
          {
            id: 'security-events',
            label: intl.formatMessage({
              id: 'audit.event_volume_graphs.labels.security_events',
            }),
            curve: 'linear',
            data: Object.values(auditStats.stats.securityAuditEvents),
            showMark: false,
            color: isHighContrast
              ? theme.palette.divider
              : blue[theme.palette.mode === 'dark' ? 200 : 500],
          },
        ]}
        slots={{
          legend: () => null,
        }}
        margin={{
          top: 20,
          bottom: 30,
          left: 42,
          right: 10,
        }}
        height={200}
      />
    </GraphWrapper>
  );
};

export default AuditEventVolumesGraph;
