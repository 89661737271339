import {
  SecurityAuditEvent,
  SecurityAuditEventsSearchInput,
} from '../../types';
import SecurityAuditEventTable from './SecurityAuditEventTable';
import SecurityAuditEventCount from './SecurityAuditEventCount';
import EventsWrapper from '../../components/Events/EventsWrapper';
import NoMatchesMessage from '../../components/Events/NoMatchesMessage';
import LoadingSpinnerWithText from '@/components/LoadingSpinnerWithText';
import { FormattedMessage } from 'react-intl';
import LoadMoreButton from '../../components/Events/LoadMoreButton';
import useAuditEventsState from '../../useAuditEventsState';

type SecurityAuditEventsProps = {
  searchInput: SecurityAuditEventsSearchInput;
};

const SecurityAuditEvents = ({ searchInput }: SecurityAuditEventsProps) => {
  const [
    loading,
    securityAuditEvents,
    canLoadMore,
    loadMoreSecurityAuditEvents,
  ] = useAuditEventsState<SecurityAuditEvent>(
    'security-audit-events',
    searchInput,
  );

  return (
    <EventsWrapper>
      {loading && securityAuditEvents.length === 0 ? (
        <LoadingSpinnerWithText noPadding>
          <FormattedMessage id="audit.security_events.events.loading_message" />
        </LoadingSpinnerWithText>
      ) : (
        <>
          {securityAuditEvents.length === 0 ? (
            <NoMatchesMessage>
              <FormattedMessage id="audit.security_events.events.no_matches" />
            </NoMatchesMessage>
          ) : (
            <>
              <SecurityAuditEventCount
                searchInput={searchInput}
                shownSecurityAuditEventCount={securityAuditEvents.length}
              />
              <SecurityAuditEventTable
                securityAuditEvents={securityAuditEvents}
              />
              {canLoadMore && (
                <LoadMoreButton
                  loading={loading}
                  onClick={loadMoreSecurityAuditEvents}
                />
              )}
            </>
          )}
        </>
      )}
    </EventsWrapper>
  );
};

export default SecurityAuditEvents;
