import mode from '@/lib/mode';
import AppMode from '@/lib/constants/AppMode.enum';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import { useLocation } from 'react-router-dom';

const modesForUrls = {
  [mode(AppMode.PORTFOLIO).url]: AppMode.PORTFOLIO,
  [mode(AppMode.COACH).url]: AppMode.COACH,
  [mode(AppMode.EDITOR).url]: AppMode.EDITOR,
  [mode(AppMode.ADMIN).url]: AppMode.ADMIN,
  [mode(AppMode.AUDIT).url]: AppMode.AUDIT,
  [mode(AppMode.PLATFORM_SETTINGS).url]: AppMode.PLATFORM_SETTINGS,
};

const useSelectedMode = (): AppMode | null => {
  const location = useLocation();
  const currentUrl = location.pathname;

  const attemptedSelectedMode = modesForUrls[currentUrl];

  const authenticatedUser = useAuthenticatedUser();

  if (!authenticatedUser || !attemptedSelectedMode) {
    return null;
  }

  const restrictedToRoles = mode(attemptedSelectedMode).roleRestrictions;

  if (
    restrictedToRoles !== null &&
    !restrictedToRoles.includes(authenticatedUser.role)
  ) {
    return null;
  }

  return attemptedSelectedMode;
};

export default useSelectedMode;
