import { useContext } from 'react';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioUserContext from '@/lib/contexts/PortfolioUserContext';
import { weekNumber } from 'weeknumber';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import useGlobalStaticApiResponse from '@/lib/api/useGlobalStaticApiResponse';
import { RubricsPortfolioSnapshotsOverview } from '@/lib/types';

const addCurrentWeekToPortfolioSnapshotsOverview = (
  portfolioSnapshotsOverview: RubricsPortfolioSnapshotsOverview,
): void => {
  const currentYear = new Date().getFullYear();

  if (typeof portfolioSnapshotsOverview.weeks[currentYear] === 'undefined') {
    portfolioSnapshotsOverview.weeks[currentYear] = [];
  }

  const currentWeek = weekNumber(new Date()) + 1;

  if (!portfolioSnapshotsOverview.weeks[currentYear].includes(currentWeek)) {
    portfolioSnapshotsOverview.weeks[currentYear].push(currentWeek);
  }
};

const usePortfolioSnapshotsOverview = ():
  | [true, null]
  | [false, RubricsPortfolioSnapshotsOverview] => {
  const portfolioMode = useContext(PortfolioModeContext);
  const portfolioUser = useContext(PortfolioUserContext);

  const apiUrl =
    portfolioMode === PortfolioMode.COACH
      ? `portfolio/snapshots/coach?userEmail=${portfolioUser!.email}`
      : 'portfolio/snapshots';

  const [portfolioSnapshotsOverviewLoading, portfolioSnapshotsOverview] =
    useGlobalStaticApiResponse<RubricsPortfolioSnapshotsOverview>(apiUrl);

  if (!portfolioSnapshotsOverviewLoading) {
    addCurrentWeekToPortfolioSnapshotsOverview(portfolioSnapshotsOverview);
  }

  if (portfolioSnapshotsOverviewLoading) {
    return [true, null];
  }

  return [false, portfolioSnapshotsOverview];
};

export default usePortfolioSnapshotsOverview;
