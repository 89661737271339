import UserRole from '@/lib/constants/UserRole.enum';
import AppMode from '@/lib/constants/AppMode.enum';

const ModesForRole = {
  [UserRole.STUDENT]: [AppMode.PORTFOLIO],
  [UserRole.COACH]: [AppMode.PORTFOLIO, AppMode.COACH],
  [UserRole.EDITOR]: [AppMode.PORTFOLIO, AppMode.COACH, AppMode.EDITOR],
  [UserRole.ADMIN]: [
    AppMode.PORTFOLIO,
    AppMode.COACH,
    AppMode.EDITOR,
    AppMode.ADMIN,
    AppMode.AUDIT,
  ],
  [UserRole.SUPER_ADMIN]: [
    AppMode.PORTFOLIO,
    AppMode.COACH,
    AppMode.EDITOR,
    AppMode.ADMIN,
    AppMode.AUDIT,
    AppMode.PLATFORM_SETTINGS,
  ],
} as const;

export default ModesForRole;
