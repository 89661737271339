import { useRef, useEffect } from 'react';

// https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
const usePrevious = <T>(value: T): T | null => {
  const ref = useRef<T>(null);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current ?? null;
};

export default usePrevious;
