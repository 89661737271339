import { useContext } from 'react';
import IsUsingPlanningViewContext from '@/lib/contexts/IsUsingPlanningViewContext';
import VisiblePhaseContext from './VisiblePhaseContext';
import PlanningView from './PlanningView';
import NormalView from './NormalView';
import ModelsContext from '@/lib/contexts/ModelsContext';
import ChallengePortfolioContext from './ChallengePortfolioContext';
import useChallengePortfolio from './useChallengePortfolio';
import PortfolioLoadingView from '@/components/PortfolioLoadingView';
import PlatformType from '@/lib/constants/PlatformType.enum';
import useModels from './useModels';

const ChallengePortfolio = () => {
  const [isUsingPlanningView] = useContext(IsUsingPlanningViewContext);

  const [modelsLoading, models] = useModels();
  const [challengePortfolioLoading, challengePortfolio, setChallengePortfolio] =
    useChallengePortfolio();

  if (modelsLoading || challengePortfolioLoading) {
    return <PortfolioLoadingView platform={PlatformType.CHALLENGEMONITOR} />;
  }

  return (
    <ModelsContext.Provider value={models}>
      <ChallengePortfolioContext.Provider
        value={[challengePortfolio, setChallengePortfolio]}
      >
        <VisiblePhaseContext.AutomatedProvider>
          {isUsingPlanningView && <PlanningView />}
          <NormalView hidden={isUsingPlanningView} />
        </VisiblePhaseContext.AutomatedProvider>
      </ChallengePortfolioContext.Provider>
    </ModelsContext.Provider>
  );
};

export default ChallengePortfolio;
