import { TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import { AuditEvent } from '../../../types';
import UserInfoTableCell from '../../../components/UserInfoTableCell';
import moment from 'moment';
import DetailsTableCell from './DetailsTableCell';
import { FormattedMessage } from 'react-intl';
import AuditTable, {
  HeadTableCell,
  SecondaryTableCell,
  MissingTableCellValue,
} from '@/pages/AuditPage/components/Events/AuditTable';
import { useState } from 'react';

type AuditEventTableProps = {
  auditEvents: AuditEvent[];
};

const AuditEventTable = ({ auditEvents }: AuditEventTableProps) => {
  const [openAuditEvent, setOpenAuditEvent] = useState<number | null>(null);

  return (
    <AuditTable>
      <TableHead>
        <TableRow>
          <HeadTableCell width={50}>
            <FormattedMessage id="audit.user_activity.events.table.columns.id" />
          </HeadTableCell>
          <HeadTableCell width={100}>
            <FormattedMessage id="audit.user_activity.events.table.columns.event_type" />
          </HeadTableCell>
          <HeadTableCell width={100}>
            <FormattedMessage id="audit.user_activity.events.table.columns.model_type" />
          </HeadTableCell>
          <HeadTableCell width={150}>
            <FormattedMessage id="audit.user_activity.events.table.columns.model_name" />
          </HeadTableCell>
          <HeadTableCell>
            <FormattedMessage id="audit.user_activity.events.table.columns.event_subtype" />
          </HeadTableCell>
          <HeadTableCell width={150}>
            <FormattedMessage id="audit.user_activity.events.table.columns.user" />
          </HeadTableCell>
          <HeadTableCell width={120}>
            <FormattedMessage id="audit.user_activity.events.table.columns.created_at" />
          </HeadTableCell>
          <HeadTableCell width={24}></HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {auditEvents.map(auditEvent => (
          <TableRow
            selected={auditEvent.id === openAuditEvent}
            key={auditEvent.id}
          >
            <TableCell component="th">#{auditEvent.id}</TableCell>
            <TableCell>{auditEvent.eventType}</TableCell>
            <TableCell>
              <span title={auditEvent.modelType}>{auditEvent.modelType}</span>
            </TableCell>
            <TableCell>
              {auditEvent.modelName ? (
                auditEvent.modelName
              ) : (
                <MissingTableCellValue>
                  <FormattedMessage id="audit.user_activity.events.table.cells.model_name.missing_value" />
                </MissingTableCellValue>
              )}
            </TableCell>
            <TableCell>
              {auditEvent.eventSubtype ? (
                <code>{auditEvent.eventSubtype}</code>
              ) : (
                <MissingTableCellValue>
                  <FormattedMessage id="audit.user_activity.events.table.cells.event_subtype.missing_value" />
                </MissingTableCellValue>
              )}
            </TableCell>
            <UserInfoTableCell auditEvent={auditEvent} />
            <SecondaryTableCell>
              {moment(auditEvent.createdAt).format('L LT')}
            </SecondaryTableCell>
            <DetailsTableCell
              auditEvent={auditEvent}
              onOpen={() => setOpenAuditEvent(auditEvent.id)}
              onClose={() => setOpenAuditEvent(null)}
            />
          </TableRow>
        ))}
      </TableBody>
    </AuditTable>
  );
};

export default AuditEventTable;
