import React from 'react';
import { MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type NoneMenuItemProps = {
  value: '';
  ref?: React.Ref<HTMLLIElement>;
};

const NoneMenuItem = ({ value, ref }: NoneMenuItemProps) => (
  <MenuItem value={value} ref={ref}>
    <em>
      <FormattedMessage id="portfolio.challenge.select_field.options.none" />
    </em>
  </MenuItem>
);

export default NoneMenuItem;
