import ActionsWrapper from './ActionsWrapper';
import React from 'react';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

type HeaderActionsProps = {
  children: React.ReactNode;
};

const HeaderActions = ({ children }: HeaderActionsProps) => {
  const authenticatedUser = useAuthenticatedUser();

  if (authenticatedUser === null) {
    return null;
  }

  return <ActionsWrapper>{children}</ActionsWrapper>;
};

export default HeaderActions;
