import { IconButtonProps, Tooltip } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

type ClearButtonProps = Omit<IconButtonProps, 'children'>;

const StyledClearButton = styled(IconButton)({
  marginTop: 35,
  marginBottom: 'auto',
  marginLeft: 6,
});

const ClearButton = (props: ClearButtonProps) => (
  <Tooltip
    title={
      <FormattedMessage id="editor.model_editor.groups_manager.clear_button" />
    }
  >
    <StyledClearButton {...props}>
      <ClearIcon />
    </StyledClearButton>
  </Tooltip>
);

export default ClearButton;
