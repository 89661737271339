import usePhaseSwitching from './usePhaseSwitching';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import HeaderContentWrapper from '../HeaderContentWrapper';
import PhaseSelectIconButton from './PhaseSelectIconButton';
import {
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon,
} from '@mui/icons-material';
import PhaseName from '../PhaseName';
import getPhaseName from '@/lib/utils/getPhaseName';

type MobilePhaseHeaderProps = {
  inTaskBoard: boolean;
};

const MobilePhaseHeader = ({ inTaskBoard }: MobilePhaseHeaderProps) => {
  const [visiblePhase, switchToPreviousPhase, switchToNextPhase] =
    usePhaseSwitching(inTaskBoard);

  const previousPhaseButtonHidden = visiblePhase === KanbanPhase.TODO;
  const nextPhaseButtonHidden = visiblePhase === KanbanPhase.DONE;

  return (
    <HeaderContentWrapper>
      <PhaseSelectIconButton
        onClick={switchToPreviousPhase}
        hidden={previousPhaseButtonHidden}
      >
        <ArrowLeftIcon />
      </PhaseSelectIconButton>
      <PhaseName>{getPhaseName(visiblePhase)}</PhaseName>
      <PhaseSelectIconButton
        onClick={switchToNextPhase}
        hidden={nextPhaseButtonHidden}
      >
        <ArrowRightIcon />
      </PhaseSelectIconButton>
    </HeaderContentWrapper>
  );
};

export default MobilePhaseHeader;
