import { useContext, useMemo } from 'react';
import PerspectivePortfolioContext from '../PerspectivePortfolioContext';
import mapElementsById from '@/lib/utils/mapElementsById';
import { computeFillLevel, computeFillFade } from '../fillHelpers';
import {
  AttachedTaskSummary,
  AttachmentInfo,
  Perspective,
  UnprocessedAttachmentInfo,
} from '@/lib/types';

const emptyAttachedTaskMap = new Map<number, AttachedTaskSummary>();

const emptyAttachmentInfo = {
  attachedTasks: {
    all: emptyAttachedTaskMap,
    directlyAttached: emptyAttachedTaskMap,
    indirectlyAttached: emptyAttachedTaskMap,
  },
  attachedTasksCount: 0,
  fillLevel: 0,
  fillFade: 0,
} satisfies AttachmentInfo;

export const buildAttachmentInfo = (
  unprocessedAttachmentInfo: UnprocessedAttachmentInfo | null,
  sublevelNestingIndex?: number,
): AttachmentInfo => {
  if (unprocessedAttachmentInfo === null) {
    return emptyAttachmentInfo;
  }

  const directlyAttachedTasks = mapElementsById(
    unprocessedAttachmentInfo.directlyAttachedTasks,
  );
  const indirectlyAttachedTasks = mapElementsById(
    unprocessedAttachmentInfo.indirectlyAttachedTasks,
  );

  // This removes duplicates, ie tasks attached both to a perspective and its
  // sublevels
  const allAttachedTasks = new Map([
    ...directlyAttachedTasks,
    ...indirectlyAttachedTasks,
  ]);

  return {
    attachedTasks: {
      all: allAttachedTasks,
      directlyAttached: directlyAttachedTasks,
      indirectlyAttached: indirectlyAttachedTasks,
    },
    attachedTasksCount: allAttachedTasks.size,
    fillLevel: computeFillLevel(allAttachedTasks.size, sublevelNestingIndex),
    fillFade: computeFillFade(unprocessedAttachmentInfo),
  };
};

const usePerspectiveAttachmentInfo = (
  perspective: Perspective,
): AttachmentInfo => {
  const [perspectivePortfolio] = useContext(PerspectivePortfolioContext);

  const unprocessedAttachmentInfo =
    perspectivePortfolio[perspective.id] ?? null;

  const attachmentInfo = useMemo(
    () => buildAttachmentInfo(unprocessedAttachmentInfo),
    [unprocessedAttachmentInfo],
  );

  return attachmentInfo;
};

export default usePerspectiveAttachmentInfo;
