/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { SecurityAuditEventsSearchInput } from '../../types';
import useApi from '@/lib/api/useApi';
import { FormattedMessage } from 'react-intl';
import CountTextWrapper from '../../components/Events/CountTextWrapper';
import CountTextPlaceholder from '../../components/Events/CountTextPlaceholder';

type SecurityAuditEventCountProps = {
  searchInput: SecurityAuditEventsSearchInput;
  shownSecurityAuditEventCount: number;
};

type SecurityAuditEventCounts = {
  totalCount: number;
  filteredCount: number;
};

const SecurityAuditEventCount = ({
  searchInput,
  shownSecurityAuditEventCount,
}: SecurityAuditEventCountProps) => {
  const api = useApi();

  const [counts, setCounts] = useState<SecurityAuditEventCounts | null>(null);

  useEffect(() => {
    setCounts(null);

    api
      .post<SecurityAuditEventCounts>(
        'security-audit-events/count',
        searchInput,
      )
      .then(securityAuditEventCounts => {
        setCounts(securityAuditEventCounts);
      });
  }, [searchInput]);

  if (counts === null) {
    // This placeholder prevents page jumping when the count request completes
    return <CountTextPlaceholder />;
  }

  if (counts.totalCount === counts.filteredCount) {
    return (
      <CountTextWrapper>
        <FormattedMessage
          id="audit.security_events.events.count.event_count_unfiltered"
          values={{
            totalCount: counts.totalCount,
            shownCount: shownSecurityAuditEventCount,
          }}
        />
      </CountTextWrapper>
    );
  }

  return (
    <CountTextWrapper>
      <FormattedMessage
        id="audit.security_events.events.count.event_count_filtered"
        values={{
          totalCount: counts.totalCount,
          filteredCount: counts.filteredCount,
          shownCount: shownSecurityAuditEventCount,
        }}
      />
    </CountTextWrapper>
  );
};

export default SecurityAuditEventCount;
