import { useContext } from 'react';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import useGlobalStaticApiResponse from '@/lib/api/useGlobalStaticApiResponse';
import { PortfolioTemplates } from '@/lib/types';

const usePortfolioTemplates = ():
  | [true, null]
  | [false, PortfolioTemplates] => {
  const portfolioMode = useContext(PortfolioModeContext);
  const apiEndpoint =
    portfolioMode === PortfolioMode.COACH
      ? 'portfolio-templates/coach'
      : 'portfolio-templates';

  const [loading, portfolioTemplates] =
    useGlobalStaticApiResponse<PortfolioTemplates>(apiEndpoint);

  if (loading) {
    return [true, null];
  }

  return [false, portfolioTemplates];
};

export default usePortfolioTemplates;
