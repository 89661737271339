import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

type StyledOutdatedBrowserWarningProps = {
  wideContent: boolean;
};

const StyledOutdatedBrowserWarning = styled(
  'section',
)<StyledOutdatedBrowserWarningProps>(
  ({ theme }) => ({
    position: 'relative',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 148,
    paddingRight: 148,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create('all'),
    [mediaQuery(1024)]: {
      paddingLeft: 72,
      paddingRight: 72,
    },
    [mediaQuery(850)]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  }),
  ({ wideContent }) => {
    if (wideContent) {
      return {
        paddingLeft: '24px !important',
        paddingRight: '24px !important',
      };
    }
  },
);

export default StyledOutdatedBrowserWarning;
