import { useContext } from 'react';
import PhaseContext from '../PhaseContext';
import { ConnectDragSource, DragSourceMonitor, useDrag } from 'react-dnd';
import RowContext from '../Rows/Row/RowContext';
import useApi from '@/lib/api/useApi';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import NewlySharedContext from '../NewlySharedChallenges/NewlySharedContext';
import useIsPortfolioReadOnly from '../useIsPortfolioReadOnly';
import { ChallengeSummary, Row } from '@/lib/types';
import DragType from '../DragType.enum';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import { ChallengeDropResult } from '../ChallengeBoard/KanbanPhaseColumn/useChallengePhaseDrop';

export type ChallengeDragItem = Readonly<{
  type: DragType.CHALLENGE;
  phase: KanbanPhase;
  row: Row;
}>;

// TODO: instead of handling local state update + API request at drop, could
// also update local state as the user drags. Would work better and feel more
// responsive
const useChallengeDrag = (
  challenge: ChallengeSummary,
): [false, null] | [boolean, ConnectDragSource] => {
  const api = useApi();

  const portfolioIsReadOnly = useIsPortfolioReadOnly();
  const [isNewlyShared, , , removeChallengeFromNewlyShared] =
    useContext(NewlySharedContext);
  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const row = useContext(RowContext);
  const phase = useContext(PhaseContext);

  const [{ isDragging }, dragRef] = useDrag({
    type: DragType.CHALLENGE,
    item: {
      type: DragType.CHALLENGE,
      phase,
      row,
    } satisfies ChallengeDragItem,
    end: (
      _,
      monitor: DragSourceMonitor<ChallengeDragItem, ChallengeDropResult>,
    ) => {
      const dropResult = monitor.getDropResult();

      if (dropResult === null || !dropResult.shouldDrop) {
        return;
      }

      const { newPhase, newRow } = dropResult;

      setChallengePortfolio(challengePortfolio => {
        challengePortfolio.challenges[challenge.id] = {
          ...challengePortfolio.challenges[challenge.id],
          ...challenge,
          phase: newPhase,
        };

        if (row !== null && row.id !== newRow.id) {
          challengePortfolio.rows[row.id].challenges.splice(
            challengePortfolio.rows[row.id].challenges.findIndex(
              id => id === challenge.id,
            ),
            1,
          );
        }

        // TODO backend bug causing challenge duplication if challenge is
        // added from newlyshared to row it was originally copied from
        // (ie someone else copies challenge, one of challenge users then drags
        // it from newly shared to the row that the original was placed in
        // in that case you get duplicates cause it was already added to that
        // row)
        if (
          challengePortfolio.rows[newRow.id].challenges.includes(challenge.id)
        ) {
          return;
        }

        if (row?.id !== newRow.id) {
          challengePortfolio.rows[newRow.id].challenges.push(challenge.id);
        }
      });

      if (isNewlyShared) {
        removeChallengeFromNewlyShared(challenge.id);
      }

      const challengeUpdates: Record<string, unknown> = {
        phase: newPhase,
      };

      if (row?.id !== newRow.id) {
        challengeUpdates.newRowId = newRow.id;
      }

      api.post(
        `challenge-portfolio/challenges/${challenge.id}/drag`,
        challengeUpdates,
      );
    },
    collect: (
      monitor: DragSourceMonitor<ChallengeDragItem, ChallengeDropResult>,
    ) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (portfolioIsReadOnly) {
    return [false, null];
  }

  return [isDragging, dragRef];
};

export default useChallengeDrag;
