import AppMode from '@/lib/constants/AppMode.enum';
import UserRole from '@/lib/constants/UserRole.enum';

const modeRoleRestrictions = {
  [AppMode.PORTFOLIO]: null,
  [AppMode.COACH]: [
    UserRole.COACH,
    UserRole.EDITOR,
    UserRole.ADMIN,
    UserRole.SUPER_ADMIN,
  ],
  [AppMode.EDITOR]: [UserRole.EDITOR, UserRole.ADMIN, UserRole.SUPER_ADMIN],
  [AppMode.ADMIN]: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
  [AppMode.AUDIT]: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
  [AppMode.PLATFORM_SETTINGS]: [UserRole.SUPER_ADMIN],
};

export default modeRoleRestrictions;
