import { useRef } from 'react';
import useElementIsInViewport from '@/lib/utils/useElementIsInViewport';
import UserToolbarPlaceholder from './UserToolbarPlaceholder';
import UserToolbarWrapper from './UserToolbarWrapper';
import { IconButton } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import SelectedUsersMessage from './SelectedUsersMessage';
import MultipleUsersGroupSelect from './MultipleUsersGroupSelect';
import MultipleUsersRoleSelect from './MultipleUsersRoleSelect';
import DeleteUsers from './DeleteUsers';
import { safeKeys } from '@/lib/utils/safeObjectFunctions';
import { UserWithId } from '@/lib/types';
import UserRole from '@/lib/constants/UserRole.enum';
import Space from '@/components/Space';

type UserToolbarProps = {
  users: UserWithId[];
  selectedUsers: {
    [userId: number]: boolean;
  };
  onSelectedUsersClear: () => void;
};

const UserToolbar = ({
  users,
  selectedUsers,
  onSelectedUsersClear,
}: UserToolbarProps) => {
  const nonFixedToolbarStartRef = useRef<HTMLDivElement>(null);
  const nonFixedToolbarInViewport = useElementIsInViewport(
    nonFixedToolbarStartRef,
  );
  const isToolbarFixed = !nonFixedToolbarInViewport;

  const usersObject: Record<number, UserWithId> = users.reduce(
    (obj, user) => ({
      ...obj,
      [user.id]: user,
    }),
    {},
  );

  const selectedUsersIds = safeKeys<number>(selectedUsers)
    .filter(id => !!usersObject[id])
    .map(Number);

  if (selectedUsersIds.length === 0) {
    return null;
  }

  const selectedUsersMatchRoles = (roles: UserRole[]): boolean => {
    return selectedUsersIds.reduce(
      (matchSoFar, id) => matchSoFar && roles.includes(usersObject[id].role),
      true,
    );
  };

  const selectedUsersAreStudents = selectedUsersMatchRoles([UserRole.STUDENT]);
  const selectedUsersAreCoaches = selectedUsersMatchRoles([
    UserRole.COACH,
    UserRole.EDITOR,
    UserRole.ADMIN,
  ]);

  return (
    <>
      <div ref={nonFixedToolbarStartRef} />
      <UserToolbarPlaceholder visible={isToolbarFixed} />
      <UserToolbarWrapper fixed={isToolbarFixed}>
        <IconButton size="large" onClick={onSelectedUsersClear}>
          <ClearIcon />
        </IconButton>
        <SelectedUsersMessage selectedUsersCount={selectedUsersIds.length} />
        <Space width="auto" />
        {selectedUsersAreStudents && (
          <MultipleUsersGroupSelect selectedUsersIds={selectedUsersIds} />
        )}
        {selectedUsersAreCoaches && (
          <MultipleUsersGroupSelect
            selectedUsersIds={selectedUsersIds}
            asManagedGroups
          />
        )}
        <MultipleUsersRoleSelect selectedUsersIds={selectedUsersIds} />
        <DeleteUsers
          selectedUsersIds={selectedUsersIds}
          onSelectedUsersClear={onSelectedUsersClear}
        />
      </UserToolbarWrapper>
    </>
  );
};

export default UserToolbar;
