import { useContext } from 'react';
import CollapsedRowsContext from '../CollapsedRowsContext';
import StyledButton from './StyledButton';
import CollapseIcon from './CollapseIcon';
import { FormattedMessage } from 'react-intl';

type CollapseAllRowsButtonProps = {
  rowIds: number[];
};

const CollapseAllRowsButton = ({ rowIds }: CollapseAllRowsButtonProps) => {
  const { isRowCollapsed, collapseAllRows, uncollapseAllRows } =
    useContext(CollapsedRowsContext);

  if (rowIds.length <= 1) {
    return null;
  }

  const shouldUncollapseAllRows = rowIds.reduce(
    (allRowsCollapsed, rowId) => allRowsCollapsed && isRowCollapsed(rowId),
    true,
  );

  const toggleAllRowsCollapsed = () => {
    if (shouldUncollapseAllRows) {
      uncollapseAllRows();
      return;
    }

    collapseAllRows();
  };

  return (
    <StyledButton onClick={toggleAllRowsCollapsed}>
      <CollapseIcon rotated={shouldUncollapseAllRows} />
      <FormattedMessage
        id={
          shouldUncollapseAllRows
            ? 'portfolio.challenge_portfolio.collapse_all_rows_button.uncollapse'
            : 'portfolio.challenge_portfolio.collapse_all_rows_button'
        }
      />
    </StyledButton>
  );
};

export default CollapseAllRowsButton;
