import { useIntl } from 'react-intl';

const useDefaultChallengeNaming = () => {
  const intl = useIntl();

  return {
    defaultChallengeNameSingular: intl.formatMessage({
      id: 'default_challenge_name.singular',
    })[0],
    defaultChallengeNamePlural: intl.formatMessage({
      id: 'default_challenge_name.plural',
    })[0],
  } as const;
};

export default useDefaultChallengeNaming;
