import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { calculateFillPercentage } from '../fillHelpers';
import stripProps from '@/lib/utils/stripProps';
import styled from '@emotion/styled';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

export type StyledOpenButtonProps = ButtonBaseProps & {
  fillLevel: number;
};

const StyledOpenButton = styled(
  stripProps(ButtonBase, 'fillLevel'),
)<StyledOpenButtonProps>(
  ({ theme, fillLevel }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    paddingLeft: 6,
    paddingRight: 6,
    height: `${100 - calculateFillPercentage(fillLevel)}%`,
    backgroundColor: theme.isMobile ? undefined : theme.palette.action.hover,
    transition: theme.transitions.create('all'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    ':hover': {
      backgroundColor: theme.palette.action.selected,
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    borderLeft: `1px solid ${theme.palette.divider}`,
  })),
);

export default StyledOpenButton;
