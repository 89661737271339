import React, { createContext, useState, useContext } from 'react';
import getCollapsedPortfolioItemsCache from './getCollapsedPortfolioItemsCache';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import { ReactContextWithAutomatedProvider } from '@/lib/types';

type PortfolioItemCollapsingState = {
  isPortfolioItemCollapsed: (
    itemId: number,
    uncollapsedByDefault: boolean,
  ) => boolean;
  togglePortfolioItemCollapsed: (
    itemId: number,
    uncollapsedByDefault: boolean,
  ) => void;
  canCollapseAnyPortfolioItem: boolean;
  collapseAllPortfolioItems: () => void;
};

const CollapsedPortfolioItemsContext =
  createContext<PortfolioItemCollapsingState>({
    isPortfolioItemCollapsed: () => false,
    togglePortfolioItemCollapsed: () => {},
    canCollapseAnyPortfolioItem: false,
    collapseAllPortfolioItems: () => {},
  }) as ReactContextWithAutomatedProvider<PortfolioItemCollapsingState>;

type AutomatedCollapsedPortfolioItemsContextProviderProps = {
  children: React.ReactNode;
};

const AutomatedCollapsedPortfolioItemsContextProvider = ({
  children,
}: AutomatedCollapsedPortfolioItemsContextProviderProps) => {
  const portfolioMode = useContext(PortfolioModeContext);
  const localStorageKeyPrefix = `portfolio-item-collapsed:${portfolioMode}:`;
  const hasCollapsedAllLocalStorageKey = `portfolio-item-collapsing:${portfolioMode}:has-collapsed-all`;

  const [collapsedPortfolioItems, setCollapsedPortfolioItems] = useState(
    getCollapsedPortfolioItemsCache(localStorageKeyPrefix),
  );

  const [hasCollapsedAll, setHasCollapsedAll] = useState(
    localStorage.getItem(hasCollapsedAllLocalStorageKey) === 'true',
  );

  const isPortfolioItemCollapsed = (
    itemId: number,
    uncollapsedByDefault: boolean,
  ): boolean => {
    const isCollapsed = collapsedPortfolioItems[itemId];

    if (typeof isCollapsed === 'undefined') {
      return hasCollapsedAll ? true : !uncollapsedByDefault;
    }

    return isCollapsed;
  };

  const updatePortfolioItemCollapsedInCache = (
    itemId: number,
    collapsed: boolean,
  ): void => {
    localStorage.setItem(
      `${localStorageKeyPrefix}${itemId}`,
      collapsed.toString(),
    );
  };

  const togglePortfolioItemCollapsed = (
    itemId: number,
    uncollapsedByDefault: boolean,
  ): void => {
    const alreadyCollapsed = isPortfolioItemCollapsed(
      itemId,
      uncollapsedByDefault,
    );

    updatePortfolioItemCollapsedInCache(itemId, !alreadyCollapsed);
    setCollapsedPortfolioItems({
      ...collapsedPortfolioItems,
      [itemId]: !alreadyCollapsed,
    });
  };

  const canCollapseAnyPortfolioItem =
    !hasCollapsedAll || Object.values(collapsedPortfolioItems).includes(false);

  const collapseAllPortfolioItems = (): void => {
    for (const key in localStorage) {
      if (key.startsWith(localStorageKeyPrefix)) {
        localStorage.removeItem(key);
      }
    }

    setHasCollapsedAll(true);
    localStorage.setItem(hasCollapsedAllLocalStorageKey, true.toString());
    setCollapsedPortfolioItems({});
  };

  return (
    <CollapsedPortfolioItemsContext.Provider
      value={{
        isPortfolioItemCollapsed,
        togglePortfolioItemCollapsed,
        canCollapseAnyPortfolioItem,
        collapseAllPortfolioItems,
      }}
    >
      {children}
    </CollapsedPortfolioItemsContext.Provider>
  );
};

CollapsedPortfolioItemsContext.AutomatedProvider =
  AutomatedCollapsedPortfolioItemsContextProvider;

export default CollapsedPortfolioItemsContext;
