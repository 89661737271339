import { useContext } from 'react';
import Space from '@/components/Space';
import OptionEditor from './OptionEditor';
import AddOptionForm from './AddOptionForm';
import MultipleOptionsSelectableCheckbox from './MultipleOptionsSelectableCheckbox';
import AdditionalConfigStateContext from '../../AdditionalConfigStateContext';

const SelectEditor = () => {
  const [additionalConfig] = useContext(AdditionalConfigStateContext);
  const options = Object.entries(additionalConfig.options ?? {});

  return (
    <section>
      <Space height={12} />
      {options.map(([key, option]) => (
        <OptionEditor optionKey={key} option={option} key={key} />
      ))}
      <AddOptionForm />
      <MultipleOptionsSelectableCheckbox />
    </section>
  );
};

export default SelectEditor;
