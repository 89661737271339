import { useContext, useState } from 'react';
import FieldContext from '../../../FieldContext';
import AddLogEntryButton from './AddLogEntryButton';
import NewLogEntryForm from './NewLogEntryForm';
import useApi from '@/lib/api/useApi';
import FieldRendererContext from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRendererContext';
import { LogEntry } from '@/lib/types';

type AddLogEntryProps = {
  logEntryName: string;
  isFirstLogEntry: boolean;
};

const AddLogEntry = ({ logEntryName, isFirstLogEntry }: AddLogEntryProps) => {
  const api = useApi();

  const { setPortfolioItem, portfolioItemApiEndpoint } =
    useContext(FieldRendererContext);
  const field = useContext(FieldContext);

  const [newLogEntryFormOpen, setNewLogEntryFormOpen] = useState(false);

  const saveNewLogEntry = async (logEntryContent: string): Promise<void> => {
    const logEntry = await api.post<LogEntry>(
      `${portfolioItemApiEndpoint}/log-entries?fieldId=${field.id}`,
      {
        content: logEntryContent,
      },
    );

    setNewLogEntryFormOpen(false);

    setPortfolioItem(portfolioItem => {
      portfolioItem.logEntries[logEntry.id] = logEntry;
    });
  };

  if (!newLogEntryFormOpen) {
    return (
      <AddLogEntryButton
        logEntryName={logEntryName}
        onClick={() => setNewLogEntryFormOpen(true)}
        isFirstLogEntry={isFirstLogEntry}
      />
    );
  }

  return (
    <NewLogEntryForm
      logEntryName={logEntryName}
      onSave={saveNewLogEntry}
      onCancel={() => setNewLogEntryFormOpen(false)}
    />
  );
};

export default AddLogEntry;
