import styled from '@emotion/styled';
import FormattedHtmlMessage from '../FormattedHtmlMessage';

type PerspectivesCopyrightNoticeProps = {
  className?: string;
};

const StyledNotice = styled('p')(({ theme }) => ({
  marginTop: 0,
  marginBottom: 0,
  fontSize: 14,
  color: theme.palette.text.secondary,
}));

const PerspectivesCopyrightNotice = ({
  className,
}: PerspectivesCopyrightNoticeProps) => {
  const year = new Date().getFullYear();

  return (
    <StyledNotice className={className}>
      <FormattedHtmlMessage
        id="perspectives.copyright_notice"
        values={{
          year,
        }}
      />
    </StyledNotice>
  );
};

export default PerspectivesCopyrightNotice;
