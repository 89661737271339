type ObjectFlatteningFunction =
  | typeof Object.keys
  | typeof Object.values
  | typeof Object.entries;

// TODO (also remove manual typecheck?)
const makeSafe =
  (objectFlatteningFunction: ObjectFlatteningFunction) => (object: object) => {
    if (!object || typeof object !== 'object') {
      return [];
    }

    return objectFlatteningFunction(object);
  };

export const safeKeys = makeSafe(Object.keys) as <
  K extends string | number | symbol,
>(
  object: Record<K, unknown>,
) => K[];
export const safeEntries = makeSafe(Object.entries) as <
  K extends string | number | symbol,
  V,
>(
  object: Record<K, V>,
) => [K, V][];
