import React, { createContext } from 'react';
import useLatestVisibleWeekRangeShiftDirection from './useLatestVisibleWeekRangeShiftDirection';
import ShiftDirection from './ShiftDirection.enum';
import { ReactContextWithAutomatedProvider } from '@/lib/types';

const LatestVisibleWeekRangeShiftDirectionContext =
  createContext<ShiftDirection | null>(
    null,
  ) as ReactContextWithAutomatedProvider<ShiftDirection | null>;

type AutomatedLatestVisibleWeekRangeShiftDirectionContextProviderProps = {
  children: React.ReactNode;
};

const AutomatedLatestVisibleWeekRangeShiftDirectionContextProvider = ({
  children,
}: AutomatedLatestVisibleWeekRangeShiftDirectionContextProviderProps) => {
  const latestVisibleWeekRangeShiftDirection =
    useLatestVisibleWeekRangeShiftDirection();

  return (
    <LatestVisibleWeekRangeShiftDirectionContext.Provider
      value={latestVisibleWeekRangeShiftDirection}
    >
      {children}
    </LatestVisibleWeekRangeShiftDirectionContext.Provider>
  );
};

LatestVisibleWeekRangeShiftDirectionContext.AutomatedProvider =
  AutomatedLatestVisibleWeekRangeShiftDirectionContextProvider;

export default LatestVisibleWeekRangeShiftDirectionContext;
