import { useContext } from 'react';
import VisiblePortfolioTemplatesGroupContext from '../VisiblePortfolioTemplatesGroupContext';
import UseAggressiveRubricCollapsingContext from './UseAggressiveRubricCollapsingContext';
import EditorTopic from './EditorTopic';
import AddTopic from './AddTopic';
import itemMatchesVisibleGroup from '@/lib/rubricsPortfolioUtils/itemMatchesVisibleGroup';
import getHighestPositionFromSortedItems from '@/pages/EditorPage/getHighestPositionFromSortedItems';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import EditorPortfolioTemplatesContext from '../EditorPortfolioTemplatesContext';

// refactor (+loading states + save success/error feedback later)
// & better local state (of db updates) handling? (also in combination with
// success/error? -> eg insert in local state on save but on error revert such
// insertion) --> maybe use redux or something?
const PortfolioTemplatesEditor = () => {
  const [portfolioTemplates] = useContext(EditorPortfolioTemplatesContext);
  const allTopics = Object.values(portfolioTemplates.topics).sort(
    sortByPosition,
  );

  const visibleGroup = useContext(VisiblePortfolioTemplatesGroupContext);
  const visibleTopics = allTopics.filter(topic =>
    itemMatchesVisibleGroup(topic, visibleGroup, true),
  );

  const highestTopicPosition = getHighestPositionFromSortedItems(allTopics);

  return (
    <UseAggressiveRubricCollapsingContext.AutomatedProvider>
      {visibleTopics.map((topic, i) => (
        <EditorTopic
          topic={topic}
          previousTopic={visibleTopics[i - 1] ?? {}}
          nextTopic={visibleTopics[i + 1] ?? {}}
          highestPosition={highestTopicPosition}
          key={topic.id}
        />
      ))}
      <AddTopic highestTopicPosition={highestTopicPosition} />
    </UseAggressiveRubricCollapsingContext.AutomatedProvider>
  );
};

export default PortfolioTemplatesEditor;
