import Dialog, { DialogProps } from '@/components/Dialog';
import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type PerspectiveColoredDialogProps = DialogProps & {
  perspectiveColor: string;
};

const PerspectiveColoredDialog = styled(
  stripProps(Dialog, 'perspectiveColor'),
)<PerspectiveColoredDialogProps>(
  ({ perspectiveColor }) => ({
    '.MuiDialog-paper': {
      borderTop: `8px solid ${perspectiveColor}`,
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    '.MuiDialog-paper': {
      borderTopColor: theme.palette.divider,
    },
  })),
);

export default PerspectiveColoredDialog;
