import { useContext } from 'react';
import PhaseContentGroupContext from '../../../PhaseContentGroupContext';
import useSelectableFieldTypesForPhaseContentGroups from './useSelectableFieldTypesForPhaseContentGroups';
import ModelTypeContext from '../../../../ModelTypeContext';
import ModelType from '@/lib/constants/challengeModel/ModelType.enum';
import FieldType from '@/lib/constants/challengeModel/FieldType.enum';

const useSelectableFieldTypes = (): FieldType[] => {
  const currentPhaseContentGroup = useContext(PhaseContentGroupContext);
  const selectableFieldTypesForCurrentPhaseContentGroup =
    useSelectableFieldTypesForPhaseContentGroups()[currentPhaseContentGroup];

  const currentModelType = useContext(ModelTypeContext);
  const unavailableFieldTypesForCurrentModelType =
    currentModelType === ModelType.CHALLENGE
      ? [FieldType.MANUAL_ASSIGNEES]
      : [FieldType.MANUAL_CHALLENGE_SHARING];

  return selectableFieldTypesForCurrentPhaseContentGroup.filter(
    fieldType => !unavailableFieldTypesForCurrentModelType.includes(fieldType),
  );
};

export default useSelectableFieldTypes;
