import Group from '../constants/Group.enum';
import { EditorPart, EditorSubtopic, EditorTopic } from '../types';
import UserRole from '../constants/UserRole.enum';

const itemMatchesVisibleGroup = (
  item: EditorTopic | EditorSubtopic | EditorPart,
  visibleGroup: Group,
  isTopic: boolean = false,
): boolean => {
  switch (visibleGroup) {
    case Group.STUDENTS: {
      return item.minimumRole === UserRole.STUDENT;
    }
    case Group.COACHES: {
      return item.minimumRole === UserRole.COACH;
    }
    case Group.DRAFTS: {
      if (!isTopic) {
        // Show all children for draft topics
        return true;
      }

      return item.minimumRole === UserRole.EDITOR;
    }
    default: {
      return true;
    }
  }
};

export default itemMatchesVisibleGroup;
