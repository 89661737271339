import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';
import { Button, ButtonProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import { Link } from 'react-router-dom';

type StyledButtonProps = ButtonProps<typeof Link> & {
  noResponsivityHiding: boolean;
};

const ButtonAsLink = (props: ButtonProps<typeof Link>) => (
  <Button component={Link} {...props} />
);

const StyledButton = styled(
  stripProps(ButtonAsLink, 'noResponsivityHiding'),
)<StyledButtonProps>(
  {
    marginRight: 12,
  },
  ({ noResponsivityHiding }) => {
    if (!noResponsivityHiding) {
      return {
        [mediaQuery(1200)]: {
          display: 'none',
        },
      };
    }

    return {
      [mediaQuery(700)]: {
        marginLeft: 'auto',
        marginRight: 0,
        width: 140,
        display: 'flex',
      },
    };
  },
);

export default StyledButton;
