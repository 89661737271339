import { useContext } from 'react';
import InnerDateField from '../DateField/InnerDateField';
import { isLater } from '@/lib/utils/dateUtils';
import convertToTimestamp from './utils/convertToTimestamp';
import useSavePortfolioItemDate from './utils/useSavePortfolioItemDate';
import FieldRendererContext from '../../../FieldRendererContext';
import moment from 'moment';

// TODO (for PortfolioItemDates) use nullcheck instead of !! on
// portfolioItem.endDate etc
const StartDate = () => {
  const { portfolioItem, portfolioItemApiEndpoint } =
    useContext(FieldRendererContext);

  const savePortfolioItemDate = useSavePortfolioItemDate(
    `${portfolioItemApiEndpoint}/dates/start-date`,
  );

  return (
    <InnerDateField
      initialValue={convertToTimestamp(portfolioItem.startDate)}
      onSave={savePortfolioItemDate}
      shouldDisableDate={
        portfolioItem.endDate === null
          ? undefined
          : date => isLater(date, moment(portfolioItem.endDate))
      }
    />
  );
};

export default StartDate;
