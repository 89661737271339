import styled from '@emotion/styled';
import ActionButton from '../ActionButton';
import { alpha } from '@mui/material';

type StyledDeleteButtonProps = {
  disabled?: boolean;
};

const StyledDeleteButton = styled(ActionButton)<StyledDeleteButtonProps>(({
  theme,
  disabled,
}) => {
  if (!disabled) {
    return {
      color: theme.palette.danger,
      ':hover, :focus': {
        backgroundColor: alpha(theme.palette.danger, 0.08),
      },
    };
  }
});

export default StyledDeleteButton;
