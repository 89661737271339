import {
  Challenge,
  ChallengeSummary,
  SetComplexImmerStateAction,
  Task,
  TaskSummary,
} from '@/lib/types';
import { createContext } from 'react';

type FieldRendererState = {
  portfolioItem: Challenge | Task;
  setPortfolioItem: SetComplexImmerStateAction<Challenge | Task>;
  setPortfolioItemSummary: SetComplexImmerStateAction<
    ChallengeSummary | TaskSummary
  >;
  portfolioItemApiEndpoint: string;
};

const FieldRendererContext = createContext<FieldRendererState>({
  portfolioItem: null as unknown as Challenge | Task,
  setPortfolioItem: () => {},
  setPortfolioItemSummary: () => {},
  portfolioItemApiEndpoint: '',
});

export default FieldRendererContext;
