import StyledUserAvatar from './StyledUserAvatar';
import StyledUserSearchInput from './StyledUserSearchInput';
import SearchIcon from './SearchIcon';
import { useIntl } from 'react-intl';
import { User } from '@/lib/types';
import { HTMLInputProps } from '@/lib/types';

type UserSearchInputProps = Omit<HTMLInputProps, 'onFocus'> & {
  selectedUser: User | null;
  keyboardSelectedUser: string | null;
  hasFocus: boolean;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
};

const UserSearchInput = ({
  selectedUser,
  keyboardSelectedUser,
  hasFocus,
  onFocus,
  ...props
}: UserSearchInputProps) => {
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!hasFocus) {
      onFocus(event);
    }
  };

  const intl = useIntl();

  return (
    <>
      {selectedUser && !hasFocus && (
        <StyledUserAvatar user={selectedUser} size={24} />
      )}
      <StyledUserSearchInput
        placeholder={intl.formatMessage({
          id: 'user_switcher.search.placeholder',
        })}
        onFocus={handleFocus}
        aria-controls={hasFocus ? 'user-result-list' : undefined}
        aria-autocomplete="list"
        aria-activedescendant={
          keyboardSelectedUser
            ? `user-result-${keyboardSelectedUser}`
            : undefined
        }
        {...props}
      />
      <SearchIcon />
    </>
  );
};

export default UserSearchInput;
