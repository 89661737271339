import { useRef, useEffect } from 'react';
import EditField, { EditFieldProps } from './EditField';
import { useIntl, FormattedMessage } from 'react-intl';

type DescriptionFieldProps = Omit<
  EditFieldProps,
  | 'placeholder'
  | 'error'
  | 'helperText'
  | 'multiline'
  | 'minRows'
  | 'maxRows'
  | 'inputRef'
> & {
  showEmptyError: boolean;
};

const DescriptionField = ({
  showEmptyError,
  ...props
}: DescriptionFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showEmptyError && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [showEmptyError]);

  const intl = useIntl();
  return (
    <EditField
      placeholder={intl.formatMessage({
        id: 'portfolio_template_editor.level.edit.description_input_placeholder',
      })}
      error={showEmptyError}
      helperText={
        showEmptyError ? (
          <FormattedMessage id="portfolio_template_editor.level.edit.error" />
        ) : null
      }
      multiline
      minRows={4}
      maxRows={6}
      inputRef={inputRef}
      {...props}
    />
  );
};

export default DescriptionField;
