import DangerousIconButton from '@/components/DangerousIconButton';
import { IconButton, IconButtonProps } from '@mui/material';

export type PossiblyDangerousIconButtonProps = IconButtonProps & {
  dangerous?: boolean;
};

const PossiblyDangerousIconButton = ({
  dangerous,
  ...props
}: PossiblyDangerousIconButtonProps) => {
  const IconButtonComponent = dangerous ? DangerousIconButton : IconButton;

  return <IconButtonComponent {...props} />;
};

export default PossiblyDangerousIconButton;
