import styled from '@emotion/styled';
import { getConnectorStyles } from './styleConstants';
import {
  CARD_HEIGHT,
  CARD_MARGIN_BOTTOM,
} from '@/components/perspectives/PerspectiveCard/styleConstants';

type StraightCardConnectorProps = {
  target: number;
  highlighted: boolean;
};

const StraightCardConnector = styled('div')<StraightCardConnectorProps>(({
  theme,
  target,
  highlighted,
}) => {
  const connectorStyles = getConnectorStyles(theme);

  return {
    position: 'absolute',
    top:
      target * (CARD_HEIGHT + CARD_MARGIN_BOTTOM) -
      connectorStyles.thickness / 2,
    left: 0,
    width: '100%',
    borderBottom: `${connectorStyles.thickness}px solid ${connectorStyles.color}`,
    borderColor: highlighted ? connectorStyles.highlightColor : undefined,
    zIndex: highlighted ? 2 : 1,
  };
});

export default StraightCardConnector;
