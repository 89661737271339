import StyledAddButton, {
  StyledAddButtonProps,
} from '../../TextField/ViewText/AddButton/StyledAddButton';
import { FormattedMessage } from 'react-intl';
import useIsPortfolioReadOnly from '@/components/ChallengePortfolio/NormalView/useIsPortfolioReadOnly';
import styled from '@emotion/styled';

type AddLogEntryButtonProps = {
  logEntryName: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isFirstLogEntry: boolean;
};

type StyledAddLogEntryButtonProps = StyledAddButtonProps & {
  increaseTopSpacing: boolean;
};

const StyledAddLogEntryButton = styled(
  StyledAddButton,
)<StyledAddLogEntryButtonProps>(({ increaseTopSpacing }) => ({
  marginTop: increaseTopSpacing ? 18 : undefined,
}));

const AddLogEntryButton = ({
  logEntryName,
  onClick,
  isFirstLogEntry,
}: AddLogEntryButtonProps) => {
  const portfolioIsReadOnly = useIsPortfolioReadOnly();

  return (
    <StyledAddLogEntryButton
      onClick={!portfolioIsReadOnly ? onClick : undefined}
      disabled={portfolioIsReadOnly}
      increaseTopSpacing={!isFirstLogEntry}
    >
      <FormattedMessage
        id="portfolio.challenge.log_entries.add.button"
        values={{ logEntryName }}
      />
    </StyledAddLogEntryButton>
  );
};

export default AddLogEntryButton;
