import { EditorSection } from '@/lib/types';
import { createContext } from 'react';

type PhaseContextValue = {
  phase: EditorSection;
  previousPhase: EditorSection | null;
  nextPhase: EditorSection | null;
};

const PhaseContext = createContext<PhaseContextValue>({
  phase: null as unknown as EditorSection,
  previousPhase: null,
  nextPhase: null,
});

export default PhaseContext;
