/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { weekNumber } from 'weeknumber';
import InnerGapminder from './InnerGapminder';
import useGapminderState from './useGapminderState';
import {
  RubricsPortfolioSnapshot,
  RubricsPortfolioSnapshotsObject,
  RubricsPortfolioSnapshotsOverview,
} from '@/lib/types';

type GapminderProps = {
  portfolioSnapshotsOverview: RubricsPortfolioSnapshotsOverview;
  portfolioSnapshots: RubricsPortfolioSnapshotsObject;
  loadPortfolioSnapshotsForYear: (year: number) => Promise<void>;
  selectedPortfolioSnapshot: RubricsPortfolioSnapshot | null;
  setSelectedPortfolioSnapshot: (
    portfolioSnapshot: RubricsPortfolioSnapshot | null,
  ) => void;
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

// TODO instead of requiring a react.setstate specifically, use simplified
// function types?
const Gapminder = ({
  portfolioSnapshotsOverview,
  portfolioSnapshots,
  loadPortfolioSnapshotsForYear,
  selectedPortfolioSnapshot,
  setSelectedPortfolioSnapshot,
  onClose,
}: GapminderProps) => {
  const currentYear = new Date().getFullYear();
  const currentWeek = weekNumber(new Date()) + 1;

  const [selectedYear, setSelectedYear, selectedWeek, setSelectedWeek] =
    useGapminderState(portfolioSnapshotsOverview.weeks);

  const [loading, setLoading] = useState(true);

  const loadedPortfolioSnapshotYears = Object.keys(portfolioSnapshots);

  const updateSelectedPortfolioSnapshot = () => {
    if (selectedYear === currentYear && selectedWeek === currentWeek) {
      setSelectedPortfolioSnapshot(null);

      return;
    }

    const newSelectedPortfolioSnapshot =
      portfolioSnapshots[selectedYear]?.[selectedWeek] ?? null;

    if (newSelectedPortfolioSnapshot === null) {
      return;
    }

    setSelectedPortfolioSnapshot(newSelectedPortfolioSnapshot);
  };

  const loadPortfolioSnapshotsForSelectedYear = async () => {
    setLoading(true);

    await loadPortfolioSnapshotsForYear(selectedYear);

    setLoading(false);
  };

  // This is necessary as invoking updateSelectedPortfolioSnapshot() direcly
  // from loadPortfolioSnapshotsForSelectedYear() does nothing since it will
  // try to access the snapshot after it's been loaded but before the portfolio
  // snapshots state has been updated.
  useEffect(() => {
    updateSelectedPortfolioSnapshot();
  }, [loading]);

  useEffect(() => {
    if (loadedPortfolioSnapshotYears.includes(selectedYear.toString())) {
      setLoading(false);

      return;
    }

    loadPortfolioSnapshotsForSelectedYear();
  }, [selectedYear, JSON.stringify(loadedPortfolioSnapshotYears)]);

  useEffect(() => {
    updateSelectedPortfolioSnapshot();
  }, [selectedYear, selectedWeek]);

  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setSelectedYear(currentYear);
    setSelectedWeek(currentWeek);

    setSelectedPortfolioSnapshot(null);

    onClose(event);
  };

  return (
    <InnerGapminder
      years={portfolioSnapshotsOverview.years}
      selectedYear={selectedYear}
      setSelectedYear={setSelectedYear}
      weeks={portfolioSnapshotsOverview.weeks[selectedYear]}
      selectedWeek={selectedWeek}
      setSelectedWeek={setSelectedWeek}
      selectedPortfolioSnapshot={selectedPortfolioSnapshot}
      onClose={handleClose}
      loading={loading}
    />
  );
};
export default Gapminder;
