import React from 'react';
import useLocalStorageState from 'use-local-storage-state';

const useDetermineDarkModeAutomatically = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [determineDarkModeAutomatically, setDetermineDarkModeAutomatically] =
    useLocalStorageState('determine-dark-mode-automatically', {
      defaultValue: true,
    });

  return [determineDarkModeAutomatically, setDetermineDarkModeAutomatically];
};

export default useDetermineDarkModeAutomatically;
