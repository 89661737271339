import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

type FormWrapperProps = {
  accountForNewlySharedMessage?: boolean;
};

const FormWrapper = styled('form')<FormWrapperProps>(
  ({ theme, accountForNewlySharedMessage }) => ({
    margin: '-24px -48px',
    marginTop: accountForNewlySharedMessage ? -12 : undefined,
    marginBottom: 12,
    padding: '24px 48px',
    backgroundColor: theme.palette.action.hover,
    display: 'flex',
    alignItems: 'center',
    [mediaQuery(560)]: {
      margin: -24,
      marginTop: accountForNewlySharedMessage ? -12 : undefined,
      marginBottom: 12,
      padding: 24,
    },
  }),
);

export default FormWrapper;
