import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import stripProps from '@/lib/utils/stripProps';
import PossiblyDangerousIconButton, {
  PossiblyDangerousIconButtonProps,
} from '@/components/PossiblyDangerousIconButton';
import mediaQuery from '@/lib/styling/mediaQuery';
import device from 'current-device';
import { OmitFramerProps } from '@/lib/types';

export type StyledActionButtonProps =
  OmitFramerProps<PossiblyDangerousIconButtonProps> & {
    small?: boolean;
    right?: boolean;
    correctRightMargin?: boolean;
  };

export const StyledActionButton = styled(
  motion.create(
    stripProps(
      PossiblyDangerousIconButton,
      'small',
      'right',
      'correctRightMargin',
    ),
  ),
)<StyledActionButtonProps>(
  ({ small }) => ({
    marginTop: -12,
    marginBottom: -12,
    display: 'inline-flex',
    ':focus': {
      transform: 'none !important',
      opacity: '1 !important',
    },
    [mediaQuery(560)]: {
      marginLeft: -6,
      marginRight: -6,
      ':last-of-type': {
        marginRight: 0,
      },
    },
    [mediaQuery(640)]: {
      transform: 'none',
      opacity: '1',
    },
    '.MuiSvgIcon-root': {
      width: small ? 16 : undefined,
      height: small ? 16 : undefined,
    },
  }),
  ({ small }) => {
    if (small) {
      return {
        padding: 6,
        width: 38,
        height: 38,
        [mediaQuery(560)]: {
          margin: -6,
        },
      };
    }
  },
  ({ right }) => {
    if (right) {
      return {
        marginLeft: 'auto',
      };
    }
  },
  ({ correctRightMargin }) => {
    if (correctRightMargin) {
      return {
        marginRight: -6,
      };
    }
  },
);

const AnimatedActionButton = (props: StyledActionButtonProps) => {
  const shouldAlwaysShowButton = !device.desktop();

  return (
    <StyledActionButton
      variants={{
        initial: {
          y: shouldAlwaysShowButton ? 0 : 5,
          opacity: shouldAlwaysShowButton ? 1 : 0,
        },
        hover: {
          y: 0,
          opacity: 1,
        },
      }}
      {...props}
    />
  );
};

export default AnimatedActionButton;
