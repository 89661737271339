import { useContext, useState } from 'react';
import api, { TokenResponse } from '@/lib/api';
import HttpMethod from '@/lib/constants/HttpMethod.enum';
import AuthenticationStateContext from '@/components/App/AppSetup/AuthenticationState/AuthenticationStateContext';
import { useMsal } from '@azure/msal-react';
import { BrowserAuthError } from '@azure/msal-browser';

const useLoginWithMicrosoft = (
  onShowError: (error: unknown) => void,
): [() => Promise<void>, boolean] => {
  const [, refreshAuthenticationState] = useContext(AuthenticationStateContext);
  const { instance } = useMsal();

  const [loading, setLoading] = useState(false);

  const loginWithMicrosoft = async (): Promise<void> => {
    try {
      const signInResult = await instance.loginPopup({
        scopes: ['User.Read', 'openid', 'profile', 'email'],
        prompt: 'select_account',
      });

      setLoading(true);

      const response =
        await api.callWithoutAuthorizationHandling<TokenResponse>(
          'auth/login/microsoft',
          {
            method: HttpMethod.POST,
            body: JSON.stringify({
              idToken: signInResult.idToken,
              accessToken: signInResult.accessToken,
            }),
          },
        );

      api.authenticate(response.accessToken, response.refreshToken);

      refreshAuthenticationState();
    } catch (error) {
      if (
        error instanceof BrowserAuthError &&
        error.errorCode === 'user_cancelled'
      ) {
        return;
      }

      onShowError(error);
    } finally {
      setLoading(false);
    }
  };

  return [loginWithMicrosoft, loading];
};

export default useLoginWithMicrosoft;
