/* eslint-disable react-hooks/exhaustive-deps */
import App from '@/lib/App';
import { Api } from '@/lib/api';
import useApi from '@/lib/api/useApi';
import { UserSummary } from '@/lib/types';
import readKeyedImageAsDataUrl from '@/lib/utils/readKeyedImageAsDataUrl';
import { useEffect, useState } from 'react';

const loadProfilePictureDataUrl = async (
  api: Api,
  src: string,
): Promise<string> => {
  const blob = await api.getGlobalStatic<Blob>(`/profile-pictures/${src}`);
  const dataUrl = await readKeyedImageAsDataUrl(src, blob);

  return dataUrl;
};

const useProfilePicture = (user: UserSummary): string | null => {
  const api = useApi();

  const useProfilePictures =
    App.instanceConfig.useSetting('useProfilePictures');

  const [profilePictureDataUrl, setProfilePictureDataUrl] = useState<
    string | null
  >(null);

  const loadInternalProfilePicture = async (): Promise<void> => {
    if (
      useProfilePictures &&
      user.picture !== null &&
      !user.picture.startsWith('https://')
    ) {
      const profilePicture = await loadProfilePictureDataUrl(api, user.picture);

      setProfilePictureDataUrl(profilePicture);
    }
  };

  useEffect(() => {
    loadInternalProfilePicture();
  }, [useProfilePictures, user.picture]);

  if (!useProfilePictures || user.picture === null) {
    return null;
  }

  if (!user.picture.startsWith('https://')) {
    return profilePictureDataUrl;
  }

  return user.picture;
};

export default useProfilePicture;
